















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';

interface IStep {
  id: string;
  name: string;
  href?: string;
}

@Component({
  components: {
    UiButton,
    UiLoading,
  },
})
export default class UiStepper extends Vue {
  @Prop({ default: [] })
  public steps!: IStep[];

  @Prop({ default: 'Submit' })
  public submitButtonLabel!: string;

  @Prop({ default: false })
  public hideSubmitButton!: boolean;

  @Prop({ default: [] })
  public nextButtonLabels!: string[];

  @Prop({ default: false })
  public nextButtonLoading!: boolean;

  @Prop({ default: false })
  public previousButtonLoading!: boolean;

  @Prop({ default: false })
  public disablePreviousButton!: boolean;

  @Prop({ default: false })
  public disableNextButton!: boolean;

  @Prop({ required: true, type: Function })
  public readonly nextButtonCallbackFn!: (arg: unknown) => unknown;

  @Prop({ required: true, type: Function })
  public readonly previousButtonCallbackFn!: (arg: unknown) => unknown;

  @Prop({ required: true, type: Function })
  public readonly submitButtonCallbackFn!: (arg: unknown) => unknown;

  @Prop({ default: 0 })
  public initialStepIndex!: number;

  currentIndex = 0;

  mounted() {
    this.currentIndex = this.initialStepIndex;
  }

  handlePreviousButtonClick() {
    if (this.currentIndex > 0) {
      const result = this.previousButtonCallbackFn(this.currentIndex);
      if (result) {
        this.currentIndex--;
      }
    }
  }

  async handleNextButtonClick() {
    if (this.currentIndex < this.steps.length) {
      const result = await this.nextButtonCallbackFn(this.currentIndex);
      if (result) {
        this.currentIndex++;
      }
    }
  }

  handleSubmitButtonClick() {
    this.submitButtonCallbackFn(this.currentIndex);
  }
}
