export const exchangeRateForCurrency = (currency: any, exchangeRates: any[]) => {
  // get selected exchange rate
  if (exchangeRates) {
    const ro = exchangeRates.find((m: any) => m.coin === currency); // .get(currency);
    if (ro === null || ro === undefined || isNaN(ro.rate)) {
      return 0;
    } else {
      return ro.rate;
    }
  } else {
    return 0;
  }
};
