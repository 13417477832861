






































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import numberUtils from '@/utils/numberUtils';

export interface TabOptions {
  value?: unknown;
  label: string;
  disabled?: boolean;
  icon?: string;
  count?: number;
}

@Component({})
export default class UiTabs extends Vue {
  @Prop()
  public readonly value?: unknown;

  @Prop()
  public readonly label?: string;

  @Prop({ required: true })
  public readonly values!: (string | TabOptions)[] | Record<string, unknown>;

  public selectedIdx: number | null = null;

  public numFormat = numberUtils.format;

  @Watch('value', { immediate: true })
  private onValueChange(newValue: unknown) {
    const index = this.valueToIndexes.get(newValue);
    if (index !== undefined) {
      this.selectedIdx = index;
    } else {
      this.selectedIdx = null;
    }
  }

  @Watch('selectedIdx')
  private onSelectedIdxChange(newValue: number | null) {
    this.$emit('input', newValue !== null ? this.normalValues[newValue].value : undefined);
  }

  public get valueToIndexes(): Map<unknown, number> {
    return this.normalValues.reduce((a, x, i) => {
      a.set(x.value, i);
      return a;
    }, new Map<unknown, number>());
  }

  public get normalValues(): TabOptions[] {
    return Array.isArray(this.values)
      ? this.values.map((x) => (typeof x === 'string' ? { value: x, label: x } : x))
      : Object.entries(this.values).map(([label, value]) => ({ label, value }));
  }

  public resetTab(event: any) {
    event.stopPropagation();
    this.selectedIdx = null;
    this.$emit('reset');
  }
}
