


































































































































































































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Contact,
  ReconciliationStatus,
  Transaction,
  TransactionLite,
  TransactionsResultLite,
  TxnType,
  Wallet,
  WalletAmounts,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiSelect from '@/components/ui/UiSelect.vue';
import numberUtils from '@/utils/numberUtils';

import UiButton from '../../components/ui/UiButton.vue';
import UiListItem from '../../components/ui/UiListItem.vue';
import UiModal from '../../components/ui/UiModal.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';

@Component({
  components: {
    UiSelect,
    UiButton,
    UiListItem,
    UiTextEdit,
    UiModal,
  },
})
export default class Paywall extends BaseVue {
  @Prop({ required: true })
  public readonly transaction!: any;

  @Prop({ required: true })
  public readonly coinLookup!: Map<string, string>;

  @Prop({ required: true })
  public readonly wallets!: Wallet[];

  public isHovering = false;

  public openType = false;

  public openToAddress = false;

  public openAmount = false;

  public filterContactsSearch = '';

  public filterCategoriesSearch = '';

  public numFormat = numberUtils.format;

  public selectedContact = '';

  public selectedCategory = '';

  public confirmAddAddress = false;

  public selectedAddress = '';

  public appliedToAll = false;

  public saved = false;

  public get contacts() {
    return [
      'Contact 1',
      'Contact 2',
      'Contact 3',
      'Contact 4',
      'Contact 5',
      'Contact 6',
      'Contact 7',
      'Contact 8',
      'Contact 9',
    ].filter((x) => x.toLowerCase().includes(this.filterContactsSearch.toLowerCase()));
  }

  public get categories() {
    return [
      'Category 1',
      'Category 2',
      'Category 3',
      'Category 4',
      'Category 5',
      'Category 6',
      'Category 7',
      'Category 8',
      'Category 9',
    ].filter((x) => x.toLowerCase().includes(this.filterCategoriesSearch.toLowerCase()));
  }

  public getLineDisplayAddress(txn: Transaction) {
    if (txn.type === TxnType.Receive && txn.from && txn.from.length === 1) {
      return `From: ${txn.from[0]!.address.substring(0, 20)}...`;
    } else if (txn.type === TxnType.Send && txn.to && txn.to.length === 1) {
      return `To: ${txn.to[0]!.address.substring(0, 20)}...`;
    } else {
      return 'No addresss found';
    }
  }

  public lookupCoin(currencyId: string) {
    return this.coinLookup.get(currencyId) ?? currencyId;
  }

  public get walletNameById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x.name as string;
      return a;
    }, {} as Record<string, string>);
  }
}
