









import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component({})
export default class PaginationFooter extends BaseVue {
  @Prop({ required: true })
  public readonly hasOlder!: boolean;

  @Prop({ required: true })
  public readonly hasNewer!: boolean;

  @Prop({ required: true })
  public readonly isLoading!: boolean;

  public onClickOlder() {
    this.$emit('clickedOlder');
  }

  public onClickNewer() {
    this.$emit('clickedNewer');
  }
}
