














































































import { faTugrikSign } from '@fortawesome/pro-regular-svg-icons';
import { Source } from 'graphql';
import gql from 'graphql-tag';
import { fromPairs } from 'lodash';
import moment from 'moment-timezone';
import { o } from 'ramda';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {
  AmountLite,
  ManualTransaction,
  Sources,
  TransactionLite,
  TransactionTypes,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import UiButton from '@/components/ui/UiButton.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { WalletsQuery } from '@/queries/transactionsPageQuery';

import { BaseVue } from '../../../BaseVue';
import UiModal from '../../ui/UiModal.vue';
@Component({ components: { UiModal, UiTextEdit, UiButton, UiSelect2 } })
export default class EditTransactionModal extends BaseVue {
  @Prop({ required: true })
  public readonly transaction!: TransactionLite | null;

  @Prop({})
  public readonly modalOpen!: boolean;

  public txDate = false;
  public txTime = false;
  public transactionDate = '';
  public transactionTime = '';
  public savingChanges = false;
  public transactionUpdate: ManualTransaction | null = null;
  public selectedTransactionType: { label: string; type: string } = { label: 'Withdrawal', type: 'Withdrawal' };

  public defaultFormData: {
    coin: string;
    amount: string;
    toAddress: string;
    fromAddress: string;
    type: string;
  } = { coin: '', amount: '', toAddress: '', fromAddress: '', type: '' };

  public formData = this.defaultFormData;
  public wallets: Wallet[] = [];

  async mounted() {
    const resp = await this.$apollo.query({
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            addresses
            networkId
            type
          }
        }
      `,
      variables: { orgId: this.$store.state.currentOrg.id },
    });
    this.wallets = resp.data.wallets;
  }

  public handleClose() {
    this.$emit('closeEditTransaction');
  }

  public mapTransactionLiteToManualTransaction(transactionToMap: TransactionLite) {
    if (
      !transactionToMap ||
      !transactionToMap.to ||
      !transactionToMap.from ||
      !transactionToMap.fullAmountSet ||
      !transactionToMap.txnLines
    ) {
      throw new Error('unable to map transaction');
    }
    const from = transactionToMap.from[0];
    const to = transactionToMap.to[0];
    const amountSet = transactionToMap.fullAmountSet[0];
    const manualTxnLine = transactionToMap.txnLines[0];
    if (!from || !to || !amountSet) {
      throw new Error('unable to map transaction');
    }
    // amount set info
    const coin = amountSet.coin;
    const currencyId = amountSet.currencyId;
    const amount = amountSet.value.value;
    const unit = amountSet.unit;

    // amounts
    const fromAmount: AmountLite = { coin, currencyId, value: transactionToMap.from[0]?.amount, unit };
    const toAmount: AmountLite = { coin, currencyId, value: transactionToMap.to[0]?.amount, unit };

    // addresses
    const fromAddress = from.address;
    const toAddress = to.address;

    const walletId = manualTxnLine.walletId;
    const walletName = this.wallets.find((wallet) => wallet.id === walletId)?.name;
    const createdSEC = transactionToMap.created;
    const ticker = transactionToMap.ticker;

    if (!fromAmount || !toAmount || !walletId || !walletName || !createdSEC || !ticker) {
      throw new Error('unable to map transaction');
    }

    const manualTansaction: ManualTransaction = {
      type: TransactionTypes.Manual,
      walletId,
      walletName,
      createdSEC,
      isComplete: false,
      amounts: [{ amount, ticker }],
      from: [{ address: fromAddress, amount: fromAmount.value, ticker }],
      to: [{ address: toAddress, amount: toAmount.value, ticker }],
      source: Sources.Custom,
    };
    return manualTansaction;
  }

  public async saveChanges() {
    try {
      if (!this.transactionUpdate) throw new Error('unable to save changes');
      this.savingChanges = true;
      const validTransactionTime = this.transactionTime || '00:00:00';

      const createdSEC = moment(`${this.transactionDate} ${validTransactionTime}`).unix();

      this.transactionUpdate = {
        ...this.transactionUpdate,
        type: TransactionTypes.Manual,
        createdSEC,
        amounts: [
          {
            amount:
              this.selectedTransactionType?.type === 'Deposit'
                ? this.formData.amount.toString()
                : (-this.formData.amount).toString(),
            ticker: this.formData.coin,
            type: 'crypto',
          },
        ],
        from: [
          { address: this.formData.fromAddress, amount: this.formData.amount.toString(), ticker: this.formData.coin },
        ],
        to: [{ address: this.formData.toAddress, amount: this.formData.amount.toString(), ticker: this.formData.coin }],
        remoteSystemId: { remoteId: this.transaction?.id?.split('.')[1] ?? '', source: this.transactionUpdate.source },
      };

      const mutation = gql`
        mutation updateManualTransaction($orgId: ID!, $txn: TxnUpdateTransaction!, $txnId: ID!, $typeGuess: TxnType!) {
          updateManualTransaction(orgId: $orgId, txn: $txn, txnId: $txnId, typeGuess: $typeGuess) {
            id
          }
        }
      `;

      const variables = {
        orgId: this.$store.state.currentOrg.id,
        txn: { ManualTransaction: this.transactionUpdate },
        txnId: this.transaction?.id,
        typeGuess: this.selectedTransactionType?.type === 'Deposit' ? TxnType.Receive : TxnType.Send,
      };

      await this.$apollo.mutate({ mutation, variables });
      this.handleClose();
      this.formData = this.defaultFormData;
      this.$emit('transactionUpdated');
    } catch (error) {
    } finally {
      this.savingChanges = false;
    }
  }

  public handleSelectTransactionType(transactionType: { type: string; label: string }) {
    this.selectedTransactionType = transactionType;
  }

  @Watch('transaction')
  onTransactionChange() {
    if (this.transaction) {
      this.transactionUpdate = this.mapTransactionLiteToManualTransaction(this.transaction);
      if (
        !(this.transactionUpdate.amounts && this.transactionUpdate.amounts[0]) ||
        !this.transactionUpdate.from ||
        !this.transactionUpdate.to
      ) {
        throw new Error('Unable to update formData');
      }
      const momentObj = moment.unix(this.transactionUpdate.createdSEC);
      this.transactionDate = momentObj.format('YYYY-MM-DD');
      this.transactionTime = momentObj.format('HH:mm:ss');
      const transactionAmount = this.transactionUpdate.amounts[0];
      this.selectedTransactionType =
        parseFloat(this.transactionUpdate.amounts[0].amount) >= 0
          ? { label: 'Deposit', type: 'Deposit' }
          : { label: 'Withdrawal', type: 'Withdrawal' };

      this.formData = {
        amount: String(Math.abs(parseFloat(transactionAmount.amount))),
        coin: transactionAmount.ticker,
        fromAddress: this.transactionUpdate.from[0]?.address ?? '',
        toAddress: this.transactionUpdate.to[0]?.address ?? '',
        type: this.selectedTransactionType.type,
      };
    }
  }
}
