var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('abbr',{staticClass:"tw-relative tooltipContainer tw-flex",on:{"mouseenter":_vm.onMouseIn}},[_vm._t("default"),(_vm.show)?_c('span',{class:[
      'tw-shadow-sm',
      'tooltip',
      _vm.currentStep.isSuspended ? 'suspended' : '',
      _vm.currentStep.isSuspended ? 'tw-bg-black' : 'tw-bg-white',
      _vm.currentStep.isSuspended ? 'tw-text-white' : 'tw-text-neutral-500',
      'tw-whitespace-pre',
      'tw-z-20',
      ( _obj = {}, _obj['pos-' + _vm.position] = true, _obj['tw-fixed'] =  _vm.fixed, _obj['tw-absolute'] =  !_vm.fixed, _obj ) ].concat( _vm.tooltipClass.split(' ') ),style:(_vm.styles)},[(!_vm.currentStep.isSuspended)?_c('div',[_c('nav',{staticClass:"tw-p-2",attrs:{"aria-label":"Progress"}},[_c('ol',{staticClass:"tw-overflow-hidden",attrs:{"role":"list"}},_vm._l((_vm.filteredSteps),function(step,index){return _c('li',{key:step.name,staticClass:"tw-relative tw-pb-2"},[(index < _vm.filteredSteps.length - 1)?_c('div',{staticClass:"tw-absolute tw-left-2 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5",class:[
                {
                  'tw-bg-error-300': _vm.filteredSteps[index + 1].isError && step.id < _vm.currentIndex,
                  'tw-bg-primary-300': _vm.filteredSteps[index + 1].id <= _vm.currentIndex,
                  'tw-bg-gray-300': _vm.filteredSteps[index + 1].id > _vm.currentIndex,
                } ],attrs:{"aria-hidden":"true"}}):_vm._e(),_c('a',{staticClass:"tw-group tw-relative tw-flex tw-items-start",attrs:{"href":"#"}},[_c('span',{staticClass:"tw-flex tw-h-7 tw-items-center"},[_c('span',{staticClass:"tw-relative tw-z-10 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full",class:[
                    {
                      'tw-border-300': step.isError,
                      'tw-bg-white': step.id >= _vm.currentIndex,
                      'tw-bg-primary-300': step.id < _vm.currentIndex,
                      'tw-border-2': step.id >= _vm.currentIndex,
                      'tw-border-gray-300': step.id > _vm.currentIndex,
                      'tw-border-primary-300': step.id === _vm.currentIndex && !step.isError,
                      'tw-border-error-300': step.id === _vm.currentIndex && step.isError,
                    } ]},[(step.id < _vm.currentIndex)?_c('svg',{staticClass:"tw-h-5 tw-w-5 tw-text-white",attrs:{"viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z","clip-rule":"evenodd"}})]):(step.id === _vm.currentIndex && step.isError)?_c('span',{staticClass:"tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-error-300"}):(step.id === _vm.currentIndex)?_c('span',{staticClass:"tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-primary-300"}):(step.id > _vm.currentIndex)?_c('span',{staticClass:"tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-transparent"}):_vm._e()])]),_c('span',{staticClass:"tw-ml-4 tw-flex tw-min-w-0 tw-flex-col"},[_c('span',{staticClass:"tw-text-sm tw-font-medium",class:{
                    'tw-text-primary-300': step.id === _vm.currentIndex && !step.isError,
                    'tw-text-error-300': step.id === _vm.currentIndex && step.isError,
                  }},[_vm._v(_vm._s(step.name))])])])])}),0)])]):_c('div',[_vm._v(" "+_vm._s(_vm.currentStep.name)+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }