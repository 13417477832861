


























































































































































































































































import axios from 'axios';
import { Transaction } from 'ethereumjs-tx';
import gql from 'graphql-tag';
import { isNull, isNumber } from 'lodash';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import {
  AmountLite,
  BulkCategorizeTransactionsDTO,
  Category,
  Connection,
  ConnectionCategory,
  Contact,
  Maybe,
  MultiValueTransactionInput,
  Providers,
  TransactionLineView,
  TransactionLite,
  TransactionView,
  TxnLineOperation,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { exchangeRateForCurrency } from '@/components/transactions/categorization/CostBasisUtils';
import {
  looselyGetCategoryWithCode,
  multivalueTransactionDataFactory,
  splitToMultiValueTransactionItemInput,
} from '@/components/transactions/categorization/StandardCategorization/utilities';
import { validAccountingConnections } from '@/components/transactions/ConnectionUtils';
import { TxnVM } from '@/components/transactions/TransactionTable2.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import { MUT_SNACKBAR } from '@/store';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';
import { convertUnits, getMainUnitForCoin } from '@/utils/coinUtils';
import { stringifyError } from '@/utils/error';
import { assertDefined } from '@/utils/guards';

import { baConfig } from '../../../config';

@Component({
  components: {
    UiButton,
    UiModal,
    UiSelect,
    UiLoading,
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
})
export default class BulkEditTransactionModal extends BaseVue {
  @Prop({ required: true })
  public readonly open!: boolean;

  @Prop({ type: Array, required: true })
  public readonly selectedTransactions!: TransactionView[];

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: true })
  readonly contacts!: Contact[];

  @Prop({ required: true })
  readonly coins!: any[];

  @Prop({ required: true })
  public readonly getDetails!: (id: string) => Promise<any>;

  // From Apollo
  public connections: Connection[] = [];

  public supportedMethods = 'Standard';

  public costBasisType = 'exchangeRate';
  public SUPPORTED_TXN_TYPES = ['Send', 'Receive', 'ContractExecution'];

  public convertBigNumberScalar(bigNumber: { mathjs: string; value: string } | string) {
    const num = typeof bigNumber === 'string' ? bigNumber : bigNumber.value;
    return math.bignumber(num);
  }

  public getProviderIcon(provider: Providers) {
    return getAccountingProviderIcon(provider);
  }

  public get getValidAccountingConnections() {
    return validAccountingConnections(this.connections, this.contacts, this.categories);
  }

  public getDefaultAccountingConnection() {
    const defaultAccountingConnection = this.getValidAccountingConnections.find((connection) => connection.isDefault);
    const defaultAccountingConnectionId =
      defaultAccountingConnection?.id ?? this.getValidAccountingConnections[0]?.id ?? null;
    return defaultAccountingConnectionId;
  }

  public getRemoteID(item: Contact) {
    const split = item.id.split('.');
    split.shift();
    return split.join('.');
  }

  public getConnectionById(connectionId?: string | null) {
    return this.getValidAccountingConnections.filter((connection) => connection.id === connectionId)[0];
  }

  public accountingConnectionId: string | null = null;

  public receiveCategory: Category | null = null;
  public receiveContact: Contact | null = null;

  public sendCategory: Category | null = null;
  public sendContact: Contact | null = null;

  public contractContact: Contact | null = null;

  public feeCategory: Category | null = null;

  public isSaving = false;
  public isLoading = false;

  public closeBulkEditTransaction() {
    this.resetDialogForm();
    this.close();
  }

  // Lifecycle hook to set the default value of account connection id
  private mounted(): void {
    // set the class property to false when the modal is mounted for the first time
    this.accountingConnectionId = this.getDefaultAccountingConnection();
  }

  public get connectionProviderCounts(): Record<Providers, number> {
    return this.connections.reduce(
      (a, x) => ({
        ...a,
        [x.provider]: (a[x.provider] ?? 0) + 1,
      }),
      {} as Record<Providers, number>
    );
  }

  public getCoinByCurrencyId(ci: string, unit?: string) {
    const coin = { ...this.coins.find((coin) => coin.currencyId === ci) };

    switch (unit) {
      case '1':
        coin.unit = 'Satoshi';
        break;
      case '2':
        coin.unit = 'Bitcoin';
        break;
      case '10':
        coin.unit = 'Wei';
        break;
      case '11':
        coin.unit = 'Ether';
        break;
      case '20':
        coin.unit = 'EOS';
        break;
      case '999999':
        coin.unit = 'Unknown';
        break;
      default: {
        break;
      }
    }
    return coin;
  }

  public getConnectionName(conn: Connection) {
    let name: string | null | undefined = conn.name;
    if (conn.provider === 'Manual') {
      name = 'Bitwave';
    }

    if (!name) {
      name = conn.provider;
      if (this.connectionProviderCounts[conn.provider] > 1) {
        return `${name} (${conn.id})`;
      } else {
        return name;
      }
    } else {
      return name;
    }
  }

  public getUnsupportedTransactions() {
    return this.selectedTransactions.filter((txn) => {
      return !this.SUPPORTED_TXN_TYPES.includes(txn.transactionType ?? '');
    });
  }

  public getSupportedTransactions() {
    return this.selectedTransactions.filter((txn) => {
      return (
        this.SUPPORTED_TXN_TYPES.includes(txn.transactionType ?? '') && txn.categorizationStatus === 'Uncategorized'
      );
    });
  }

  public get filteredContacts(): Contact[] {
    if (!this.contacts) return [];
    if (this.accountingConnectionId) {
      return this.contacts.filter((c) => c.accountingConnectionId === this.accountingConnectionId);
    }

    return this.contacts;
  }

  public get filteredCategories(): Category[] {
    if (!this.categories) {
      return [];
    }
    if (this.accountingConnectionId) {
      return this.categories
        .filter((c) => c.accountingConnectionId === this.accountingConnectionId)
        .sort((a, b) => {
          //  we will try to sort by the account code
          if (isNumber(a.code) && isNumber(b.code)) {
            return Number(a.code) - Number(b.code);
          }

          //  default is a string comparison on name
          return a.name.localeCompare(b.name);
        });
    }

    return this.categories;
  }

  public getCategorizedTransactions() {
    return this.selectedTransactions.filter((txn) => txn.categorizationStatus === 'Categorized').length;
  }

  public getUncategorizedTransactions() {
    return this.selectedTransactions.filter((txn) => txn.categorizationStatus === 'Uncategorized');
  }

  public calcTotalCostBasis(transaction: any): number {
    let total = math.bignumber(0);
    let totalPos = math.bignumber(0);
    let totalNeg = math.bignumber(0);
    for (const a of transaction.walletAmounts[0].amounts) {
      const val = this.bn(a.value);
      const rate = exchangeRateForCurrency(
        this.getCoinByCurrencyId(a.currencyId, a.unit).ticker,
        transaction.exchangeRates.exchangeRates
      );
      const totalInt = total.add(this.convertBigNumberScalar(a.value).mul(rate)).abs();
      if (val.gte(0)) {
        totalPos = totalPos.plus(totalInt);
      } else {
        totalNeg = totalNeg.plus(totalInt);
      }
    }

    for (const f of transaction.paidFees) {
      const rate = exchangeRateForCurrency(
        this.getCoinByCurrencyId(f.currencyId, f.unit).ticker,
        transaction.exchangeRates.exchangeRates
      );
      totalNeg = totalNeg.add(this.convertBigNumberScalar(f.value).mul(rate)).abs();
    }
    if (totalPos.gt(totalNeg)) {
      total = totalPos.sub(totalNeg);
    } else {
      total = totalNeg.sub(totalPos);
    }

    return Number(total.toFixed(2));
  }

  public getContactByTransaction(transaction: TransactionView) {
    switch (transaction.transactionType) {
      case 'Send':
        return this.sendContact ?? undefined;
      case 'Receive':
        return this.receiveContact ?? undefined;
      case 'ContractExecution':
        return this.contractContact ?? undefined;
    }
  }

  public getCategoryByTransaction(transaction: TransactionView): Category | undefined {
    switch (transaction.transactionType) {
      case 'Send':
        return this.sendCategory ?? undefined;
      case 'Receive':
        return this.receiveCategory ?? undefined;
      case 'ContractExecution':
        return this.feeCategory ?? undefined;
    }
  }

  public txnAmountTotal(txn: TxnVM): math.BigNumber {
    let amountTotal = math.bignumber(0);

    const amounts = txn.lines.filter((x) => x.operation !== TxnLineOperation.Fee);

    assertDefined(amounts);
    for (const m of amounts) {
      assertDefined(m);
      const { amount, amountCurrencyId, amountCurrencyName } = m;
      const coin = amountCurrencyName;
      const unit = getMainUnitForCoin(coin);
      assertDefined(coin);
      assertDefined(unit);
      const val = this.convertBigNumberScalar(amount);
      const converted = convertUnits(coin, unit, unit, val);
      assertDefined(converted);
      amountTotal = amountTotal.plus(converted);
    }

    return amountTotal;
  }

  public getUncategorizedSendTransactions() {
    return this.selectedTransactions.filter(
      (txn) => txn.categorizationStatus === 'Uncategorized' && txn.transactionType === 'Send'
    ).length;
  }

  public getUncategorizedContractExecutionTransactions() {
    return this.selectedTransactions.filter(
      (txn) => txn.categorizationStatus === 'Uncategorized' && txn.transactionType === 'ContractExecution'
    ).length;
  }

  public getUncategorizedReceiveTransactions() {
    return this.selectedTransactions.filter(
      (txn) => txn.categorizationStatus === 'Uncategorized' && txn.transactionType === 'Receive'
    ).length;
  }

  public getUncategorizedContractTransactions() {
    return this.selectedTransactions.filter(
      (txn) => txn.categorizationStatus === 'Uncategorized' && txn.transactionType === 'ContractExecution'
    ).length;
  }

  public getCategoryCall(transaction: any, details: any) {
    try {
      const exchangeRates = [];
      for (const er of details?.exchangeRates ?? []) {
        let exRate: any = {
          coin: er?.from,
          unit: getMainUnitForCoin(er?.from),
          fiat: er?.to,
          rate: er?.rate,
          // source: er?.source,
        };
        if (transaction.transactionType === 'ContractExecution') {
          exRate = { ...exRate, priceId: er?.priceId };
        }
        exchangeRates.push(exRate);
      }

      let cb: any;
      if (details?.exchangeRates) {
        if (this.costBasisType === 'exchangeRate') {
          cb = {
            exchangeRate: this.convertBigNumberScalar(exchangeRates[0].rate).toNumber(),
            costBasisType: 'ExchangeRate',
            valid: true,
            exchangeRates: exchangeRates,
          };
        }
      }

      const lines = this.calcLinesForBulkCategorization(
        transaction,
        this.categories,
        this.connections.filter((connection) => connection.id === this.accountingConnectionId)[0]
      );

      const splits: any[] = [];
      splits.push({
        contact: this.getContactByTransaction(transaction),
        lines: lines.map((line: any) => {
          let category = null;
          if (line.operation === TxnLineOperation.Fee) {
            category = this.feeCategory;
          } else {
            category = this.getCategoryByTransaction(transaction);
          }
          return {
            ...line,
            category,
          };
        }),
      });

      const baseCurrency = this.$store.state.currentOrg.baseCurrency;

      const splitsFinal = splits.map((split) =>
        splitToMultiValueTransactionItemInput(
          split,
          this.txnAmountTotal(transaction).toNumber(),
          baseCurrency,
          cb,
          transaction.transactionType ?? ''
        )
      );

      const { valid, ...transactionData } = multivalueTransactionDataFactory(splitsFinal, exchangeRates);
      assertDefined(transactionData);
      console.log('transactionData', transactionData);
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        txnId: transaction.id ?? '',
        transactionData: {
          ...transactionData,
          accountingConnectionId: transactionData.multivalue.items[0].contactId.split('.')[0],
          editEtag: transaction.editEtag,
        },
      };

      return this.categorizeTransaction(vars);
    } catch (error) {
      console.error(error, ':: getCategoryCall error txn ::', transaction);
      return Promise.reject(error);
    }
  }

  public async getTransactionDetails() {
    const ids = this.selectedTransactions.map((txn) => txn.id);
    const promises = ids.map((id) => {
      return this.getDetails(id);
    });
    return Promise.all(promises).then((results) => {
      return results.reduce((acc, data, index) => {
        acc[ids[index]] = data; // Use the original ID from the `ids` array for the key
        return acc;
      }, {});
    });
  }

  public mapToBulkCategorizationDTO() {
    const supportedTxns = this.getSupportedTransactions();
    const dto = {
      categorization: {
        multivalue: {
          txnIds: supportedTxns.map((txn) => txn.id),
          feeContactId: this.contractContact?.id || '',
          feeCategoryId: this.feeCategory?.id || '',
          sendContactId: this.sendContact?.id || '',
          sendCategoryId: this.sendCategory?.id || '',
          receiveContactId: this.receiveContact?.id || '',
          receiveCategoryId: this.receiveCategory?.id || '',
        },

        accountingConnectionId: this.accountingConnectionId,
      },
    } as BulkCategorizeTransactionsDTO;
    return dto;
  }

  public async saveBulkTransaction() {
    this.isLoading = true;
    const dto = this.mapToBulkCategorizationDTO();
    try {
      const url = `${baConfig.getFriendlyApiUrl()}/orgs/${this.orgId}/transactions`;
      const resp = await axios.put(url, dto, { withCredentials: true });
      this.$store.commit(MUT_SNACKBAR, {
        color: 'success',
        message: this.$tc('_categorizeTransactionsSuccess'),
      });
    } catch (error) {
      console.error('Error saving bulk transaction', error);
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Failed to Categorize All Transactions',
      });
    } finally {
      this.resetDialogForm();
      this.isLoading = false;
      this.isSaving = false;
      this.close('refresh');
    }
  }

  public async saveBulkEditTransaction() {
    this.isLoading = true;
    const toBeCategorizedList = [];
    const details = await this.getTransactionDetails();
    console.log('details', details);
    for (const transaction of this.getSupportedTransactions()) {
      toBeCategorizedList.push(this.getCategoryCall(transaction, details[transaction.id]));
    }
    await Promise.all(toBeCategorizedList)
      .then(() => {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: this.$tc('_categorizeTransactionsSuccess'),
        });
      })
      .catch(() => {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Failed to Categorize All Transactions',
        });
      })
      .finally(() => {
        this.resetDialogForm();
        this.isLoading = false;
        this.isSaving = false;
        this.close('refresh');
      });
  }

  async categorizeTransaction(vars: {
    orgId: string;
    txnId: string;
    transactionData: {
      multivalue: MultiValueTransactionInput;
      editEtag: string;
    };
  }): Promise<void> {
    try {
      // await this.$apollo.mutate({
      //   // Query
      //   mutation: gql`
      //     mutation ($orgId: ID!, $txnId: ID!, $transactionData: TransactionData!) {
      //       categorizeTransaction(orgId: $orgId, txnId: $txnId, transactionData: $transactionData) {
      //         id
      //       }
      //     }
      //   `,
      //   // Parameters
      //   variables: vars,
      // });
      this.$emit('saved');
    } catch (e) {
      const message = 'Problem saving item: ' + stringifyError(e, { hideErrorName: true });
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message,
      });
    }
  }

  public resetDialogForm() {
    this.sendCategory = null;
    this.sendContact = null;
    this.contractContact = null;
    this.receiveCategory = null;
    this.receiveContact = null;
    this.feeCategory = null;
  }

  public close(extraAction?: string) {
    if (extraAction === 'refresh') {
      this.$emit('close', 'refresh');
    } else {
      this.$emit('close');
    }
  }

  public prepopulateLineFromAmount(line: Maybe<TransactionLineView>) {
    assertDefined(line);
    const { amountCurrencyId, amount, amountCurrencyName } = line;
    const coin = amountCurrencyName;
    const mainUnit = getMainUnitForCoin(coin);
    assertDefined(coin);
    assertDefined(mainUnit);
    const val = this.convertBigNumberScalar(amount);
    const conv = convertUnits(coin, mainUnit, mainUnit, val);
    return {
      amount: conv,
      ticker: coin,
    };
  }

  public calcLinesForBulkCategorization(
    txn: TxnVM,
    categories: Category[],
    connection: Connection | null
  ): { amount: math.BigNumber }[] {
    const prePopLines: any[] = [];
    const linesWithoutFees = txn.lines.filter((x) => x.operation !== TxnLineOperation.Fee);
    const linesWithFees = txn.lines.filter((x) => x.operation === TxnLineOperation.Fee);

    if (txn?.lines?.length) {
      //  we are going to try to pre populate the txn amounts and fees separately
      //  we will try to fill in the fee contact and category
      linesWithoutFees.forEach((a: any) => {
        const l = this.prepopulateLineFromAmount(a);
        prePopLines.push(l);
      });
      linesWithFees.forEach((f: any) => {
        const l = this.prepopulateLineFromAmount(f);
        const maybeCategory = looselyGetCategoryWithCode(categories, connection?.feeAccountCode);
        const fee = {
          category: maybeCategory,
          amount: l.amount as any,
          ticker: l.ticker,
          description: `Fee for txn ${txn.id}.`,
        };
        prePopLines.push(fee);
      });
    }
    //  this is the final catch all
    if (prePopLines.length === 0) {
      const zero = math.bignumber(0);
      prePopLines.push({ amount: zero });
    }
    return prePopLines;
  }
}
