



















import { emit } from 'process';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiButton from '@/components/ui/UiButton.vue';

@Component({
  components: {
    UiButton,
  },
})
export default class UiBanner extends Vue {
  @Prop({ default: '#' })
  public readonly link!: string;

  @Prop({ default: false })
  public readonly alt!: boolean;

  @Prop({ default: 'feedback' })
  public readonly color!: 'info' | 'warning' | 'feedback';

  public show = true;

  public get classes(): string[] {
    switch (this.color) {
      case 'feedback':
        return ['tw-bg-gray-100'];
      case 'warning':
        return ['tw-bg-yellow-200'];
      case 'info':
        return ['tw-bg-green-200'];
    }
  }
}
