import { TransactionsResultLite } from '@/api-svc-types';

export function walletResponse() {
  return {
    data: {
      wallets: [
        {
          id: 'wYbeINrejmSTvvFz5G3P',
          name: 'AC SZN3',
          addresses: ['0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef'],
          networkId: 'eth',
          __typename: 'Wallet',
        },
        {
          id: 'j2R8uH7ZZRxFvECy13V2',
          name: 'NFT Fund Q3 2022 Vault',
          addresses: ['0x1b7844CfaE4C823Ac6389855D47106a70c84F067'],
          networkId: 'eth',
          __typename: 'Wallet',
        },
      ],
    },
  };
}

export function transactionResponse() {
  return {
    txns: [
      {
        id: '0x8ebb029990950b591aa4c655022f0cab7887dc18277acfee6bcb6b450f8ab6f7',
        created: 1677178739,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8ebb029990950b591aa4c655022f0cab7887dc18277acfee6bcb6b450f8ab6f7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.407023829387264' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'MEMES-71', coinUnit: 'MEMES-71', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'MEMES-71',
                        coin: 'MEMES-71',
                        coinUnit: 'MEMES-71',
                      },
                      description: '',
                      coin: 'MEMES-71',
                      unit: 'MEMES-71',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x8ebb029990950b591aa4c655022f0cab7887dc18277acfee6bcb6b450f8ab6f7',
            txnLineId: 1,
            dateTimeSEC: 1677178739,
            dateTime: '2023-02-23T18:58:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.407023829387264',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '407023829387264',
            unit: '407023829387264',
            currencyId: 'COIN.407023829387264',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '407023829387264',
            unit: '407023829387264',
            currencyId: 'COIN.407023829387264',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x2abc7c1dd43b7fd5d83bbd7b10acc7982d25f7400c467480578f77b84fdc4cfe',
        created: 1677107243,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Trade',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.0476' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x4610dB225D7305e31690658D674E7D37eB244f7e',
            amount: { value: { mathjs: 'BigNumber', value: '2.0476' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2abc7c1dd43b7fd5d83bbd7b10acc7982d25f7400c467480578f77b84fdc4cfe',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x202c5805'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.006245755278491096' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.0476' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.406617079046656' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1643.28',
              failedOrIncomplete: false,
              priceId: 'cf794d0e-298d-4941-8611-43f89fd57fc6',
            },
            { coin: 'ABXBMG-416', coinUnit: 'ABXBMG-416', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            trade: {
              acquiredAssets: [
                {
                  asset: {
                    displayValue: '1',
                    value: { mathjs: 'BigNumber', value: '1' },
                    coin: 'ABXBMG-416',
                    unit: 'ABXBMG-416',
                    parentTimestamp: 1677107243,
                    itemValue: {
                      type: 2,
                      value: { mathjs: 'BigNumber', value: '1' },
                      coin: 406617079046656,
                      coinUnit: 406617079046656,
                    },
                    org: {
                      id: 'qv6XKdWPGtOuGkJtx26D',
                      name: 'Big NFT Org',
                      roles: { '8NFjuPz7jGRIiUVXFslrtqsZRIQ2': 4 },
                      timezone: 'US/Central',
                      taxStrategy: { type: 2, grouping: 2 },
                      baseCurrency: 'USD',
                      userRoles: [{ userId: '8NFjuPz7jGRIiUVXFslrtqsZRIQ2', role: 4 }],
                      taxConfig: { capitalizeTradingFees: true },
                      accountingConfig: {
                        multiCurrency: false,
                        separateFeeTransactions: false,
                        networkContactIds: {},
                        allowTxnInference: false,
                      },
                      flags: [
                        { key: 'use-price-svc', value: 'true' },
                        { key: 'multiple-inventories', value: 'true' },
                      ],
                    },
                  },
                  fairMarketValue: { cost: 0, exchangeRate: 0, currency: 'USD', costBasisType: 'ExchangeRate' },
                },
              ],
              disposedAssets: [
                {
                  asset: {
                    displayValue: '2.0476',
                    value: { mathjs: 'BigNumber', value: '2.0476' },
                    coin: 'ETH',
                    unit: 'Ether',
                    parentTimestamp: 1677107243,
                    itemValue: { type: 2, value: { mathjs: 'BigNumber', value: '2.0476' }, coin: 10, coinUnit: 11 },
                    org: {
                      id: 'qv6XKdWPGtOuGkJtx26D',
                      name: 'Big NFT Org',
                      roles: { '8NFjuPz7jGRIiUVXFslrtqsZRIQ2': 4 },
                      timezone: 'US/Central',
                      taxStrategy: { type: 2, grouping: 2 },
                      baseCurrency: 'USD',
                      userRoles: [{ userId: '8NFjuPz7jGRIiUVXFslrtqsZRIQ2', role: 4 }],
                      taxConfig: { capitalizeTradingFees: true },
                      accountingConfig: {
                        multiCurrency: false,
                        separateFeeTransactions: false,
                        networkContactIds: {},
                        allowTxnInference: false,
                      },
                      flags: [
                        { key: 'use-price-svc', value: 'true' },
                        { key: 'multiple-inventories', value: 'true' },
                      ],
                    },
                  },
                  fairMarketValue: {
                    cost: 3364.780128,
                    exchangeRate: 1643.28,
                    currency: 'USD',
                    costBasisType: 'ExchangeRate',
                  },
                },
              ],
              tradeFee: {
                assets: [
                  {
                    asset: {
                      displayValue: '0.00624576',
                      value: { mathjs: 'BigNumber', value: '0.006245755278491096' },
                      coin: 'ETH',
                      unit: 'Ether',
                      parentTimestamp: 1677107243,
                      itemValue: {
                        type: 2,
                        value: { mathjs: 'BigNumber', value: '0.006245755278491096' },
                        coin: 10,
                        coinUnit: 11,
                      },
                      org: {
                        id: 'qv6XKdWPGtOuGkJtx26D',
                        name: 'Big NFT Org',
                        roles: { '8NFjuPz7jGRIiUVXFslrtqsZRIQ2': 4 },
                        timezone: 'US/Central',
                        taxStrategy: { type: 2, grouping: 2 },
                        baseCurrency: 'USD',
                        userRoles: [{ userId: '8NFjuPz7jGRIiUVXFslrtqsZRIQ2', role: 4 }],
                        taxConfig: { capitalizeTradingFees: true },
                        accountingConfig: {
                          multiCurrency: false,
                          separateFeeTransactions: false,
                          networkContactIds: {},
                          allowTxnInference: false,
                        },
                        flags: [
                          { key: 'use-price-svc', value: 'true' },
                          { key: 'multiple-inventories', value: 'true' },
                        ],
                      },
                    },
                    fairMarketValue: {
                      cost: 10.263524734038848,
                      exchangeRate: 1643.28,
                      currency: 'USD',
                      costBasisType: 'ExchangeRate',
                    },
                  },
                ],
                feeContactId: 'Manual.Manual1',
              },
              systemCreated: false,
              exchangeRates: [
                { coin: 'ETH', coinUnit: 'Ether', fiat: 'USD', rate: '1643.28', failedOrIncomplete: false },
                { coin: 'ABXBMG-416', coinUnit: 'ABXBMG-416', fiat: 'USD', rate: '0', failedOrIncomplete: false },
              ],
            },
          },
        ],
        txnLines: [
          {
            id: '0x2abc7c1dd43b7fd5d83bbd7b10acc7982d25f7400c467480578f77b84fdc4cfe',
            txnLineId: 1,
            dateTimeSEC: 1677107243,
            dateTime: '2023-02-22T23:07:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.406617079046656',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2abc7c1dd43b7fd5d83bbd7b10acc7982d25f7400c467480578f77b84fdc4cfe',
            txnLineId: 2,
            dateTimeSEC: 1677107243,
            dateTime: '2023-02-22T23:07:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.0476',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x4610dB225D7305e31690658D674E7D37eB244f7e',
            feeAmount: '0.006245755278491096',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-6245755278491096' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2047600000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '406617079046656',
            unit: '406617079046656',
            currencyId: 'COIN.406617079046656',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2047600000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '406617079046656',
            unit: '406617079046656',
            currencyId: 'COIN.406617079046656',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '6245755278491096' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x2db777542e4c1087e5c8a16016080f53c5a21603fb8363f00cf045e95a071773',
        created: 1677074363,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2db777542e4c1087e5c8a16016080f53c5a21603fb8363f00cf045e95a071773',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.406557851551232' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'MEMES-70', coinUnit: 'MEMES-70', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'MEMES-70',
                        coin: 'MEMES-70',
                        coinUnit: 'MEMES-70',
                      },
                      description: '',
                      coin: 'MEMES-70',
                      unit: 'MEMES-70',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x2db777542e4c1087e5c8a16016080f53c5a21603fb8363f00cf045e95a071773',
            txnLineId: 1,
            dateTimeSEC: 1677074363,
            dateTime: '2023-02-22T13:59:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.406557851551232',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '406557851551232',
            unit: '406557851551232',
            currencyId: 'COIN.406557851551232',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '406557851551232',
            unit: '406557851551232',
            currencyId: 'COIN.406557851551232',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x612173aaf7a1c2c6485006315b87ff68a6ec883f0baa4bb6671e735d6823d432',
        created: 1677011183,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x612173aaf7a1c2c6485006315b87ff68a6ec883f0baa4bb6671e735d6823d432',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900180992768' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'FF001-1012032433',
              coinUnit: 'FF001-1012032433',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -1,
                        ticker: 'FF001-1012032433',
                        coin: 'FF001-1012032433',
                        coinUnit: 'FF001-1012032433',
                      },
                      description: '',
                      coin: 'FF001-1012032433',
                      unit: 'FF001-1012032433',
                      coinAmount: '-1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x612173aaf7a1c2c6485006315b87ff68a6ec883f0baa4bb6671e735d6823d432',
            txnLineId: 1,
            dateTimeSEC: 1677011183,
            dateTime: '2023-02-21T20:26:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900180992768',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xC725c98A214a3b79C0454Ef2151c73b248ce329c',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x8a274d9de470d9a31a10b51fcca7d8928e0d39650d7fd9059220eb9e25a6f903',
        created: 1676747783,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8a274d9de470d9a31a10b51fcca7d8928e0d39650d7fd9059220eb9e25a6f903',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.405903518430720' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'MEMES-69', coinUnit: 'MEMES-69', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'MEMES-69',
                        coin: 'MEMES-69',
                        coinUnit: 'MEMES-69',
                      },
                      description: '',
                      coin: 'MEMES-69',
                      unit: 'MEMES-69',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x8a274d9de470d9a31a10b51fcca7d8928e0d39650d7fd9059220eb9e25a6f903',
            txnLineId: 1,
            dateTimeSEC: 1676747783,
            dateTime: '2023-02-18T19:16:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.405903518430720',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '405903518430720',
            unit: '405903518430720',
            currencyId: 'COIN.405903518430720',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '405903518430720',
            unit: '405903518430720',
            currencyId: 'COIN.405903518430720',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xee444417af75c16be44178b82a079fb84dd8f1080ae0868f58fb1d808662a012',
        created: 1676635175,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xee444417af75c16be44178b82a079fb84dd8f1080ae0868f58fb1d808662a012',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.404717032766720' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'MEMES-68', coinUnit: 'MEMES-68', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 2,
                        ticker: 'MEMES-68',
                        coin: 'MEMES-68',
                        coinUnit: 'MEMES-68',
                      },
                      description: '',
                      coin: 'MEMES-68',
                      unit: 'MEMES-68',
                      coinAmount: '2',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0xee444417af75c16be44178b82a079fb84dd8f1080ae0868f58fb1d808662a012',
            txnLineId: 1,
            dateTimeSEC: 1676635175,
            dateTime: '2023-02-17T11:59:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.404717032766720',
            amount: '2',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '404717032766720',
            unit: '404717032766720',
            currencyId: 'COIN.404717032766720',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '404717032766720',
            unit: '404717032766720',
            currencyId: 'COIN.404717032766720',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xce3baade5a9edcd6082a83bad5378f26aede456592af81a0163d1b789b62bf20',
        created: 1676466527,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xce3baade5a9edcd6082a83bad5378f26aede456592af81a0163d1b789b62bf20',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.404072106487808' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'MEMES-67', coinUnit: 'MEMES-67', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'MEMES-67',
                        coin: 'MEMES-67',
                        coinUnit: 'MEMES-67',
                      },
                      description: '',
                      coin: 'MEMES-67',
                      unit: 'MEMES-67',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0xce3baade5a9edcd6082a83bad5378f26aede456592af81a0163d1b789b62bf20',
            txnLineId: 1,
            dateTimeSEC: 1676466527,
            dateTime: '2023-02-15T13:08:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.404072106487808',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '404072106487808',
            unit: '404072106487808',
            currencyId: 'COIN.404072106487808',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '404072106487808',
            unit: '404072106487808',
            currencyId: 'COIN.404072106487808',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
        created: 1676310959,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403362470660096' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403362470707968' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403362470726656' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403362471554304' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403362470976512' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: '✺RUGCVL-13812', coinUnit: '✺RUGCVL-13812', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '✺RUGCVL-13807', coinUnit: '✺RUGCVL-13807', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '✺RUGCVL-13811', coinUnit: '✺RUGCVL-13811', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '✺RUGCVL-13806', coinUnit: '✺RUGCVL-13806', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '✺RUGCVL-13805', coinUnit: '✺RUGCVL-13805', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: '✺RUGCVL-13812',
                        coin: '✺RUGCVL-13812',
                        coinUnit: '✺RUGCVL-13812',
                      },
                      description: '',
                      coin: '✺RUGCVL-13812',
                      unit: '✺RUGCVL-13812',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: '✺RUGCVL-13807',
                        coin: '✺RUGCVL-13807',
                        coinUnit: '✺RUGCVL-13807',
                      },
                      description: '',
                      coin: '✺RUGCVL-13807',
                      unit: '✺RUGCVL-13807',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: '✺RUGCVL-13811',
                        coin: '✺RUGCVL-13811',
                        coinUnit: '✺RUGCVL-13811',
                      },
                      description: '',
                      coin: '✺RUGCVL-13811',
                      unit: '✺RUGCVL-13811',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: '✺RUGCVL-13806',
                        coin: '✺RUGCVL-13806',
                        coinUnit: '✺RUGCVL-13806',
                      },
                      description: '',
                      coin: '✺RUGCVL-13806',
                      unit: '✺RUGCVL-13806',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: '✺RUGCVL-13805',
                        coin: '✺RUGCVL-13805',
                        coinUnit: '✺RUGCVL-13805',
                      },
                      description: '',
                      coin: '✺RUGCVL-13805',
                      unit: '✺RUGCVL-13805',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            txnLineId: 1,
            dateTimeSEC: 1676310959,
            dateTime: '2023-02-13T17:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403362470660096',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            txnLineId: 2,
            dateTimeSEC: 1676310959,
            dateTime: '2023-02-13T17:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403362470707968',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            txnLineId: 3,
            dateTimeSEC: 1676310959,
            dateTime: '2023-02-13T17:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403362470726656',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            txnLineId: 4,
            dateTimeSEC: 1676310959,
            dateTime: '2023-02-13T17:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403362471554304',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbeefa2ddc7694952a31a9c69524f234904bdad69f46c5b0984c34b2b42a0e7bf',
            txnLineId: 5,
            dateTimeSEC: 1676310959,
            dateTime: '2023-02-13T17:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403362470976512',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xb379B56bcACdD58Ae0768654763881849bFAAd94',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470660096',
            unit: '403362470660096',
            currencyId: 'COIN.403362470660096',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470707968',
            unit: '403362470707968',
            currencyId: 'COIN.403362470707968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470726656',
            unit: '403362470726656',
            currencyId: 'COIN.403362470726656',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362471554304',
            unit: '403362471554304',
            currencyId: 'COIN.403362471554304',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470976512',
            unit: '403362470976512',
            currencyId: 'COIN.403362470976512',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470660096',
            unit: '403362470660096',
            currencyId: 'COIN.403362470660096',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470707968',
            unit: '403362470707968',
            currencyId: 'COIN.403362470707968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470726656',
            unit: '403362470726656',
            currencyId: 'COIN.403362470726656',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362471554304',
            unit: '403362471554304',
            currencyId: 'COIN.403362471554304',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403362470976512',
            unit: '403362470976512',
            currencyId: 'COIN.403362470976512',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x161766c82d720d3aee5fc9cc13ac94b3720a55d9a9eef1e776bbd382d302855b',
        created: 1676298299,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x161766c82d720d3aee5fc9cc13ac94b3720a55d9a9eef1e776bbd382d302855b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403303532939265' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-66', coinUnit: 'UNKNOWN-66', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'UNKNOWN-66',
                        coin: 'UNKNOWN-66',
                        coinUnit: 'UNKNOWN-66',
                      },
                      description: '',
                      coin: 'UNKNOWN-66',
                      unit: 'UNKNOWN-66',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x161766c82d720d3aee5fc9cc13ac94b3720a55d9a9eef1e776bbd382d302855b',
            txnLineId: 1,
            dateTimeSEC: 1676298299,
            dateTime: '2023-02-13T14:24:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403303532939265',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403303532939265',
            unit: '403303532939265',
            currencyId: 'COIN.403303532939265',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403303532939265',
            unit: '403303532939265',
            currencyId: 'COIN.403303532939265',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x63e99f6ed6fd8eb9967115a3c82ab557171bfbd4baab3da6505fd8bed1774666',
        created: 1676043803,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x63e99f6ed6fd8eb9967115a3c82ab557171bfbd4baab3da6505fd8bed1774666',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.403303540018433' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-65', coinUnit: 'UNKNOWN-65', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Income',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'UNKNOWN-65',
                        coin: 'UNKNOWN-65',
                        coinUnit: 'UNKNOWN-65',
                      },
                      description: '',
                      coin: 'UNKNOWN-65',
                      unit: 'UNKNOWN-65',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x63e99f6ed6fd8eb9967115a3c82ab557171bfbd4baab3da6505fd8bed1774666',
            txnLineId: 1,
            dateTimeSEC: 1676043803,
            dateTime: '2023-02-10T15:43:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.403303540018433',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403303540018433',
            unit: '403303540018433',
            currencyId: 'COIN.403303540018433',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '403303540018433',
            unit: '403303540018433',
            currencyId: 'COIN.403303540018433',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x76ef517181ef07e39144149575b7f960db4d52b584df61793e65c678bdcd0c41',
        created: 1675823447,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '30.8' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xC725c98A214a3b79C0454Ef2151c73b248ce329c',
            amount: { value: { mathjs: 'BigNumber', value: '30.8' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x76ef517181ef07e39144149575b7f960db4d52b584df61793e65c678bdcd0c41',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000676347954219' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-30.8' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1676.73',
              failedOrIncomplete: false,
              priceId: '89b77d24-7979-4cc0-9533-408cde75132d',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x76ef517181ef07e39144149575b7f960db4d52b584df61793e65c678bdcd0c41',
            txnLineId: 1,
            dateTimeSEC: 1675823447,
            dateTime: '2023-02-08T02:30:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '30.8',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xC725c98A214a3b79C0454Ef2151c73b248ce329c',
            feeAmount: '0.000676347954219',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-676347954219000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-30800000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-30800000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '676347954219000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe18c43f4fc014092a21276800a4018a5cd55826e545304ed9ac319988c457713',
        created: 1675822547,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xCD531Ae9EFCCE479654c4926dec5F6209531Ca7b',
            amount: { value: { mathjs: 'BigNumber', value: '40' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '40' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe18c43f4fc014092a21276800a4018a5cd55826e545304ed9ac319988c457713',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '40' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1676.73',
              failedOrIncomplete: false,
              priceId: '89b77d24-7979-4cc0-9533-408cde75132d',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe18c43f4fc014092a21276800a4018a5cd55826e545304ed9ac319988c457713',
            txnLineId: 1,
            dateTimeSEC: 1675822547,
            dateTime: '2023-02-08T02:15:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '40',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xCD531Ae9EFCCE479654c4926dec5F6209531Ca7b',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '40000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '40000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x93f34644a1fd49138bf91b5a8d9144a317c2aaa2e45d24110c3ca6b7b8a838bc',
        created: 1675818299,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xDDFF4364eED0f07e454d3f50137A9E615AbF9005',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x93f34644a1fd49138bf91b5a8d9144a317c2aaa2e45d24110c3ca6b7b8a838bc',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.003405399358182024' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1682.35',
              failedOrIncomplete: false,
              priceId: '6d653a63-f86e-4b36-846a-b62fcd8a611f',
            },
            {
              coin: 'USDC',
              coinUnit: 'USDC',
              fiat: 'USD',
              rate: '1',
              failedOrIncomplete: false,
              priceId: '57b2ccb5-f309-47ee-89b4-c3c50459bccf',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3405399358182024' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3405399358182024' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x0f936d2083b09877d669decf8031d6a8e3d97782d0d937375ad7a8ad6b004a34',
        created: 1675794827,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x0f936d2083b09877d669decf8031d6a8e3d97782d0d937375ad7a8ad6b004a34',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.401281082699776' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-64', coinUnit: 'UNKNOWN-64', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x0f936d2083b09877d669decf8031d6a8e3d97782d0d937375ad7a8ad6b004a34',
            txnLineId: 1,
            dateTimeSEC: 1675794827,
            dateTime: '2023-02-07T18:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.401281082699776',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '401281082699776',
            unit: '401281082699776',
            currencyId: 'COIN.401281082699776',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '401281082699776',
            unit: '401281082699776',
            currencyId: 'COIN.401281082699776',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x65b61668cbd77a65a29248bfd9adb28c1f7890047e02fae2ac2b58b73c38339d',
        created: 1675710407,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x76D078D7e5755B66fF50166863329D27F2566b43',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x65b61668cbd77a65a29248bfd9adb28c1f7890047e02fae2ac2b58b73c38339d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900188574208' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-186', coinUnit: 'QQL-186', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x65b61668cbd77a65a29248bfd9adb28c1f7890047e02fae2ac2b58b73c38339d',
            txnLineId: 1,
            dateTimeSEC: 1675710407,
            dateTime: '2023-02-06T19:06:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900188574208',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x76D078D7e5755B66fF50166863329D27F2566b43',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x445ada7851a972cc498361dc49577e613c0488ec3009e264f4457a9f13fc5168',
        created: 1675650671,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x445ada7851a972cc498361dc49577e613c0488ec3009e264f4457a9f13fc5168',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb88d4fde'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001572242435207796' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900188574208' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1623.71',
              failedOrIncomplete: false,
              priceId: '4234a4c4-8a11-4d65-8d66-8f0e1e51e45a',
            },
            { coin: 'QQL-186', coinUnit: 'QQL-186', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x445ada7851a972cc498361dc49577e613c0488ec3009e264f4457a9f13fc5168',
            txnLineId: 1,
            dateTimeSEC: 1675650671,
            dateTime: '2023-02-06T02:31:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900188574208',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x76D078D7e5755B66fF50166863329D27F2566b43',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1572242435207796' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1572242435207796' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xd8da511d168353a34391cd41e903619ce03966a7afc708d3a2147be7a9a064ed',
        created: 1675649651,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            amount: { value: { mathjs: 'BigNumber', value: '1.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xd8da511d168353a34391cd41e903619ce03966a7afc708d3a2147be7a9a064ed',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0a3b4739'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004836692901392676' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.5' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399826690754816' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900188574208' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1623.71',
              failedOrIncomplete: false,
              priceId: '4234a4c4-8a11-4d65-8d66-8f0e1e51e45a',
            },
            { coin: 'QQL-MP-542', coinUnit: 'QQL-MP-542', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-186', coinUnit: 'QQL-186', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xd8da511d168353a34391cd41e903619ce03966a7afc708d3a2147be7a9a064ed',
            txnLineId: 1,
            dateTimeSEC: 1675649651,
            dateTime: '2023-02-06T02:14:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399826690754816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xd8da511d168353a34391cd41e903619ce03966a7afc708d3a2147be7a9a064ed',
            txnLineId: 2,
            dateTimeSEC: 1675649651,
            dateTime: '2023-02-06T02:14:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900188574208',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xd8da511d168353a34391cd41e903619ce03966a7afc708d3a2147be7a9a064ed',
            txnLineId: 3,
            dateTimeSEC: 1675649651,
            dateTime: '2023-02-06T02:14:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            feeAmount: '0.004836692901392676',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4836692901392676' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690754816',
            unit: '399826690754816',
            currencyId: 'COIN.399826690754816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690754816',
            unit: '399826690754816',
            currencyId: 'COIN.399826690754816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900188574208',
            unit: '400900188574208',
            currencyId: 'COIN.400900188574208',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4836692901392676' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x80936dc9fd4bdc804dd0e6169555d4ff52e4570859db6415d3f70899ed59a45e',
        created: 1675643327,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '8.0889726125417508' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '8.0889726125417508' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x80936dc9fd4bdc804dd0e6169555d4ff52e4570859db6415d3f70899ed59a45e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '8.0889726125417508' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1639.39',
              failedOrIncomplete: false,
              priceId: 'bab617d7-8585-4c8d-8f78-872120a174b2',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x80936dc9fd4bdc804dd0e6169555d4ff52e4570859db6415d3f70899ed59a45e',
            txnLineId: 1,
            dateTimeSEC: 1675643327,
            dateTime: '2023-02-06T00:28:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '8.0889726125417508',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '8088972612541750800' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '8088972612541750800' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x58fb76932141a84501e1efac9aa4223e56d434a8c75fd54ac4d75d2007e21cda',
        created: 1675642775,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xCD531Ae9EFCCE479654c4926dec5F6209531Ca7b',
            amount: { value: { mathjs: 'BigNumber', value: '8.9722463097288788' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '8.9722463097288788' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x58fb76932141a84501e1efac9aa4223e56d434a8c75fd54ac4d75d2007e21cda',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '8.9722463097288788' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1639.39',
              failedOrIncomplete: false,
              priceId: 'bab617d7-8585-4c8d-8f78-872120a174b2',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x58fb76932141a84501e1efac9aa4223e56d434a8c75fd54ac4d75d2007e21cda',
            txnLineId: 1,
            dateTimeSEC: 1675642775,
            dateTime: '2023-02-06T00:19:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '8.9722463097288788',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xCD531Ae9EFCCE479654c4926dec5F6209531Ca7b',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '8972246309728878800' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '8972246309728878800' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x9cc0f2c7be8f2ce0dc37e0028c3b327eb9c276a29a3dc79a9af11530e10615c7',
        created: 1675617551,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9cc0f2c7be8f2ce0dc37e0028c3b327eb9c276a29a3dc79a9af11530e10615c7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900233885697' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-63', coinUnit: 'UNKNOWN-63', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9cc0f2c7be8f2ce0dc37e0028c3b327eb9c276a29a3dc79a9af11530e10615c7',
            txnLineId: 1,
            dateTimeSEC: 1675617551,
            dateTime: '2023-02-05T17:19:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900233885697',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900233885697',
            unit: '400900233885697',
            currencyId: 'COIN.400900233885697',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900233885697',
            unit: '400900233885697',
            currencyId: 'COIN.400900233885697',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
        created: 1675536635,
        accountingConnectionId: 'Manual',
        categorizationSummary: 'Detailed',
        categorizationStatus: 'Categorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900170454016' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900180992768' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900170343168' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900180968960' },
            ],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.007529096722007008' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900170454016' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900180992768' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900170343168' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.400900180968960' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
            { coin: 'QQL-184', coinUnit: 'QQL-184', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            {
              coin: 'FF001-1012032433',
              coinUnit: 'FF001-1012032433',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
            { coin: 'QQL-183', coinUnit: 'QQL-183', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-182', coinUnit: 'QQL-182', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        accountingDetails: [
          {
            multivalue: {
              orgId: 'qv6XKdWPGtOuGkJtx26D',
              items: [
                {
                  contactId: 'Manual.Manual1',
                  transactionType: 'Expense',
                  lines: [
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -1,
                        ticker: 'QQL-184',
                        coin: 'QQL-184',
                        coinUnit: 'QQL-184',
                      },
                      description: '',
                      coin: 'QQL-184',
                      unit: 'QQL-184',
                      coinAmount: '-1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual',
                      sourceValue: { type: 'Coin', amount: 1, ticker: 'QQL-184', coin: 'QQL-184', coinUnit: 'QQL-184' },
                      description: '',
                      coin: 'QQL-184',
                      unit: 'QQL-184',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -1,
                        ticker: 'FF001-1012032433',
                        coin: 'FF001-1012032433',
                        coinUnit: 'FF001-1012032433',
                      },
                      description: '',
                      coin: 'FF001-1012032433',
                      unit: 'FF001-1012032433',
                      coinAmount: '-1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual',
                      sourceValue: {
                        type: 'Coin',
                        amount: 1,
                        ticker: 'FF001-1012032433',
                        coin: 'FF001-1012032433',
                        coinUnit: 'FF001-1012032433',
                      },
                      description: '',
                      coin: 'FF001-1012032433',
                      unit: 'FF001-1012032433',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -1,
                        ticker: 'QQL-183',
                        coin: 'QQL-183',
                        coinUnit: 'QQL-183',
                      },
                      description: '',
                      coin: 'QQL-183',
                      unit: 'QQL-183',
                      coinAmount: '-1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual',
                      sourceValue: { type: 'Coin', amount: 1, ticker: 'QQL-183', coin: 'QQL-183', coinUnit: 'QQL-183' },
                      description: '',
                      coin: 'QQL-183',
                      unit: 'QQL-183',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -1,
                        ticker: 'QQL-182',
                        coin: 'QQL-182',
                        coinUnit: 'QQL-182',
                      },
                      description: '',
                      coin: 'QQL-182',
                      unit: 'QQL-182',
                      coinAmount: '-1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual',
                      sourceValue: { type: 'Coin', amount: 1, ticker: 'QQL-182', coin: 'QQL-182', coinUnit: 'QQL-182' },
                      description: '',
                      coin: 'QQL-182',
                      unit: 'QQL-182',
                      coinAmount: '1',
                      fiat: 'USD',
                      fiatAmount: '0',
                      metadata: [],
                    },
                    {
                      categoryId: 'Manual.Manual2',
                      sourceValue: {
                        type: 'Coin',
                        amount: -0.007529096722007008,
                        ticker: 'ETH',
                        coin: 'ETH',
                        coinUnit: 'Ether',
                      },
                      description: 'Fee for txn 0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de.',
                      coin: 'ETH',
                      unit: 'Ether',
                      coinAmount: '-0.007529096722007008',
                      fiat: 'USD',
                      fiatAmount: '-12.64',
                      metadata: [],
                    },
                  ],
                },
              ],
              exchangeRates: [],
            },
          },
        ],
        txnLines: [
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 1,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900170454016',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 2,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900180992768',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 3,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900170343168',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 4,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900180968960',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 5,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900170454016',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 6,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900180992768',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 7,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900170343168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x867a8478b39037998ae91067d839b0774b855f8c628e68086e6410f1554260de',
            txnLineId: 8,
            dateTimeSEC: 1675536635,
            dateTime: '2023-02-04T18:50:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.400900180968960',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-7529096722007008' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '7529096722007008' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x08b32cb3feeb1bd7512f3894367e4c2e44000407d8a817d85b6eb5983a41a9c0',
        created: 1675536611,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x28b51BA8B990c48CB22cB6EF0ad5415fdBA5210C',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x08b32cb3feeb1bd7512f3894367e4c2e44000407d8a817d85b6eb5983a41a9c0',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.001179472752635888' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1179472752635888' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1179472752635888' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe2eebe66efabefd2937fd990c838a9cef86b489fc45775984397d0503971e513',
        created: 1675536587,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe2eebe66efabefd2937fd990c838a9cef86b489fc45775984397d0503971e513',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00116632243287675' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1166322432876750' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1166322432876750' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xea66c9934a20c8935251007aae7fee8b106a050d4c822d56fed687327b8b554a',
        created: 1675536131,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xea66c9934a20c8935251007aae7fee8b106a050d4c822d56fed687327b8b554a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0a3b4739'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.006825720184806009' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399826690791424' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900170454016' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
            { coin: 'QQL-MP-541', coinUnit: 'QQL-MP-541', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-184', coinUnit: 'QQL-184', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xea66c9934a20c8935251007aae7fee8b106a050d4c822d56fed687327b8b554a',
            txnLineId: 1,
            dateTimeSEC: 1675536131,
            dateTime: '2023-02-04T18:42:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399826690791424',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xea66c9934a20c8935251007aae7fee8b106a050d4c822d56fed687327b8b554a',
            txnLineId: 2,
            dateTimeSEC: 1675536131,
            dateTime: '2023-02-04T18:42:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900170454016',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xea66c9934a20c8935251007aae7fee8b106a050d4c822d56fed687327b8b554a',
            txnLineId: 3,
            dateTimeSEC: 1675536131,
            dateTime: '2023-02-04T18:42:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            feeAmount: '0.006825720184806009',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-6825720184806009' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690791424',
            unit: '399826690791424',
            currencyId: 'COIN.399826690791424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690791424',
            unit: '399826690791424',
            currencyId: 'COIN.399826690791424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170454016',
            unit: '400900170454016',
            currencyId: 'COIN.400900170454016',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '6825720184806009' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x9e3803b83d48c75bd1182427f5b802e5f5214e4ba80d566c8a0f4f39f474f776',
        created: 1675535087,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '9' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '9' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9e3803b83d48c75bd1182427f5b802e5f5214e4ba80d566c8a0f4f39f474f776',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.005395003859124576' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-9' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900180992768' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
            {
              coin: 'FF001-1012032433',
              coinUnit: 'FF001-1012032433',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9e3803b83d48c75bd1182427f5b802e5f5214e4ba80d566c8a0f4f39f474f776',
            txnLineId: 1,
            dateTimeSEC: 1675535087,
            dateTime: '2023-02-04T18:24:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900180992768',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x02009370Ff755704E9acbD96042C1ab832D6067e',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x9e3803b83d48c75bd1182427f5b802e5f5214e4ba80d566c8a0f4f39f474f776',
            txnLineId: 2,
            dateTimeSEC: 1675535087,
            dateTime: '2023-02-04T18:24:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.005395003859124576',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5395003859124576' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-9000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-9000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180992768',
            unit: '400900180992768',
            currencyId: 'COIN.400900180992768',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5395003859124576' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xce551edaf84a89c3c6336e4be7efa5263399041b75165bb2d48e9b218308f9c9',
        created: 1675534715,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xce551edaf84a89c3c6336e4be7efa5263399041b75165bb2d48e9b218308f9c9',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0a3b4739'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.008181990135356558' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399826690766080' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900170343168' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
            { coin: 'QQL-MP-540', coinUnit: 'QQL-MP-540', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-183', coinUnit: 'QQL-183', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xce551edaf84a89c3c6336e4be7efa5263399041b75165bb2d48e9b218308f9c9',
            txnLineId: 1,
            dateTimeSEC: 1675534715,
            dateTime: '2023-02-04T18:18:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399826690766080',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xce551edaf84a89c3c6336e4be7efa5263399041b75165bb2d48e9b218308f9c9',
            txnLineId: 2,
            dateTimeSEC: 1675534715,
            dateTime: '2023-02-04T18:18:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900170343168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xce551edaf84a89c3c6336e4be7efa5263399041b75165bb2d48e9b218308f9c9',
            txnLineId: 3,
            dateTimeSEC: 1675534715,
            dateTime: '2023-02-04T18:18:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            feeAmount: '0.008181990135356558',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-8181990135356558' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690766080',
            unit: '399826690766080',
            currencyId: 'COIN.399826690766080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690766080',
            unit: '399826690766080',
            currencyId: 'COIN.399826690766080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900170343168',
            unit: '400900170343168',
            currencyId: 'COIN.400900170343168',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '8181990135356558' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x0ab1ad30c78e91baddec5593d2577837389654a98a15faf6e6aa69c9146eafa8',
        created: 1675534655,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            amount: { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x0ab1ad30c78e91baddec5593d2577837389654a98a15faf6e6aa69c9146eafa8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0a3b4739'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.007842506191150853' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399826690716672' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.400900180968960' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
            { coin: 'QQL-MP-539', coinUnit: 'QQL-MP-539', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-182', coinUnit: 'QQL-182', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x0ab1ad30c78e91baddec5593d2577837389654a98a15faf6e6aa69c9146eafa8',
            txnLineId: 1,
            dateTimeSEC: 1675534655,
            dateTime: '2023-02-04T18:17:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51d3BC729b3F863075bf3f8d3d967Ccd06DF20AF',
            feeAmount: '0.007842506191150853',
          },
          {
            id: '0x0ab1ad30c78e91baddec5593d2577837389654a98a15faf6e6aa69c9146eafa8',
            txnLineId: 2,
            dateTimeSEC: 1675534655,
            dateTime: '2023-02-04T18:17:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399826690716672',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x0ab1ad30c78e91baddec5593d2577837389654a98a15faf6e6aa69c9146eafa8',
            txnLineId: 3,
            dateTimeSEC: 1675534655,
            dateTime: '2023-02-04T18:17:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.400900180968960',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-7842506191150853' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690716672',
            unit: '399826690716672',
            currencyId: 'COIN.399826690716672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826690716672',
            unit: '399826690716672',
            currencyId: 'COIN.399826690716672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '400900180968960',
            unit: '400900180968960',
            currencyId: 'COIN.400900180968960',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '7842506191150853' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x61d58fb915ab21f9956e59b599f2782987404c5f57c5be2a11424f4a65ffdfb7',
        created: 1675534391,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xc73B17179Bf0C59cD5860Bb25247D1D1092c1088',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x61d58fb915ab21f9956e59b599f2782987404c5f57c5be2a11424f4a65ffdfb7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.0010504814948449' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1679.17',
              failedOrIncomplete: false,
              priceId: 'b74f1a64-0a91-47f3-a2f9-af6063bc3cb3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1050481494844900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1050481494844900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x149ab368e0684073e4bcf917e425c5ce57d1b31007b73a997bb58c0aa6894be1',
        created: 1675462175,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '15' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '15' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x149ab368e0684073e4bcf917e425c5ce57d1b31007b73a997bb58c0aa6894be1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '15' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1668.01',
              failedOrIncomplete: false,
              priceId: '73a32457-f63c-4283-9d00-60426f57ea6c',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x149ab368e0684073e4bcf917e425c5ce57d1b31007b73a997bb58c0aa6894be1',
            txnLineId: 1,
            dateTimeSEC: 1675462175,
            dateTime: '2023-02-03T22:09:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '15',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '15000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '15000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x7bb2bb605ec872eafa8e322cf8f218a55cc25c73243d7edadb8460ddaedc9f52',
        created: 1675422431,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7bb2bb605ec872eafa8e322cf8f218a55cc25c73243d7edadb8460ddaedc9f52',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825882877186' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-62', coinUnit: 'UNKNOWN-62', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7bb2bb605ec872eafa8e322cf8f218a55cc25c73243d7edadb8460ddaedc9f52',
            txnLineId: 1,
            dateTimeSEC: 1675422431,
            dateTime: '2023-02-03T11:07:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825882877186',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825882877186',
            unit: '399825882877186',
            currencyId: 'COIN.399825882877186',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825882877186',
            unit: '399825882877186',
            currencyId: 'COIN.399825882877186',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf327ef49509d0cf8f84b408058311c26df32df3b39b6c44b4750ac0a82e94f4b',
        created: 1675198799,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf327ef49509d0cf8f84b408058311c26df32df3b39b6c44b4750ac0a82e94f4b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825972578048' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-61', coinUnit: 'UNKNOWN-61', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf327ef49509d0cf8f84b408058311c26df32df3b39b6c44b4750ac0a82e94f4b',
            txnLineId: 1,
            dateTimeSEC: 1675198799,
            dateTime: '2023-01-31T20:59:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825972578048',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825972578048',
            unit: '399825972578048',
            currencyId: 'COIN.399825972578048',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825972578048',
            unit: '399825972578048',
            currencyId: 'COIN.399825972578048',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xbbaabfd1e431a236d1a43acb40111c6a864c2a1d79d614f7d7eb1a73618d5a08',
        created: 1675089851,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbbaabfd1e431a236d1a43acb40111c6a864c2a1d79d614f7d7eb1a73618d5a08',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826007599617' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-60', coinUnit: 'UNKNOWN-60', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xbbaabfd1e431a236d1a43acb40111c6a864c2a1d79d614f7d7eb1a73618d5a08',
            txnLineId: 1,
            dateTimeSEC: 1675089851,
            dateTime: '2023-01-30T14:44:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826007599617',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826007599617',
            unit: '399826007599617',
            currencyId: 'COIN.399826007599617',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826007599617',
            unit: '399826007599617',
            currencyId: 'COIN.399826007599617',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x4b2ee390cd73ac714a42ffc00e9b1e40ada3027fdd0a5a9d4a6b78e4072a835b',
        created: 1674820763,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4b2ee390cd73ac714a42ffc00e9b1e40ada3027fdd0a5a9d4a6b78e4072a835b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825845658112' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-59', coinUnit: 'UNKNOWN-59', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4b2ee390cd73ac714a42ffc00e9b1e40ada3027fdd0a5a9d4a6b78e4072a835b',
            txnLineId: 1,
            dateTimeSEC: 1674820763,
            dateTime: '2023-01-27T11:59:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825845658112',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825845658112',
            unit: '399825845658112',
            currencyId: 'COIN.399825845658112',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825845658112',
            unit: '399825845658112',
            currencyId: 'COIN.399825845658112',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x3f426e9641f4cb62f32d7d12f5e69767e35dc468494322f49d36a23c8679f5ca',
        created: 1674674627,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3f426e9641f4cb62f32d7d12f5e69767e35dc468494322f49d36a23c8679f5ca',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831821071873' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-58', coinUnit: 'UNKNOWN-58', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3f426e9641f4cb62f32d7d12f5e69767e35dc468494322f49d36a23c8679f5ca',
            txnLineId: 1,
            dateTimeSEC: 1674674627,
            dateTime: '2023-01-25T19:23:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831821071873',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831821071873',
            unit: '399831821071873',
            currencyId: 'COIN.399831821071873',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831821071873',
            unit: '399831821071873',
            currencyId: 'COIN.399831821071873',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xaea2781a9e7ea2df1bdcdb4770abf7906c0a17bcd1ff1bdb502413e16227aed2',
        created: 1674562511,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xaea2781a9e7ea2df1bdcdb4770abf7906c0a17bcd1ff1bdb502413e16227aed2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825987811072' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-57', coinUnit: 'UNKNOWN-57', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xaea2781a9e7ea2df1bdcdb4770abf7906c0a17bcd1ff1bdb502413e16227aed2',
            txnLineId: 1,
            dateTimeSEC: 1674562511,
            dateTime: '2023-01-24T12:15:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825987811072',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825987811072',
            unit: '399825987811072',
            currencyId: 'COIN.399825987811072',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825987811072',
            unit: '399825987811072',
            currencyId: 'COIN.399825987811072',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x5977f5fc9d4743b6ad0b644ad44b9db6882a1b6880ace9da1dd2ef07761b0c0c',
        created: 1674430391,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x1254A542B1b9Fd60236A40DDA71B07A880b31e5b',
            amount: { value: { mathjs: 'BigNumber', value: '0.6' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
            amount: { value: { mathjs: 'BigNumber', value: '0.6' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5977f5fc9d4743b6ad0b644ad44b9db6882a1b6880ace9da1dd2ef07761b0c0c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x7ff36ab5'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              {
                value: { mathjs: 'BigNumber', value: '14463752.151578843973267644' },
                currencyId: 'COIN.399824115556096',
              },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1622.53',
              failedOrIncomplete: false,
              priceId: 'ca99894f-5473-4c8c-9fce-45b48c954fdd',
            },
            { coin: 'PORSCHE', coinUnit: 'PORSCHE', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5977f5fc9d4743b6ad0b644ad44b9db6882a1b6880ace9da1dd2ef07761b0c0c.0x273A0FAEA2b3d10a786Ea0CF0567a9bCA384Ac4C',
            txnLineId: 1,
            dateTimeSEC: 1674430391,
            dateTime: '2023-01-22T23:33:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399824115556096',
            amount: '14463752.151578843973267644',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xc77926abef1d80a73acc3093f59256820e94d75c',
            to: '0x1b7844cfae4c823ac6389855d47106a70c84f067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '14463752.151578843973267644' },
            coin: '399824115556096',
            unit: '399824115556096',
            currencyId: 'COIN.399824115556096',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '14463752.151578843973267644' },
            coin: '399824115556096',
            unit: '399824115556096',
            currencyId: 'COIN.399824115556096',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa33150b1928c7fa773b9d91877dc849f03ca408aa7128a13caf2cbcdffbd8def',
        created: 1674222155,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa33150b1928c7fa773b9d91877dc849f03ca408aa7128a13caf2cbcdffbd8def',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831826113536' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-56', coinUnit: 'UNKNOWN-56', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa33150b1928c7fa773b9d91877dc849f03ca408aa7128a13caf2cbcdffbd8def',
            txnLineId: 1,
            dateTimeSEC: 1674222155,
            dateTime: '2023-01-20T13:42:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831826113536',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831826113536',
            unit: '399831826113536',
            currencyId: 'COIN.399831826113536',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831826113536',
            unit: '399831826113536',
            currencyId: 'COIN.399831826113536',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xb515fb0b434c6e8652871fb1995b99e7e3fb91d2f74c0214c79d6be72917b7bb',
        created: 1674041171,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb515fb0b434c6e8652871fb1995b99e7e3fb91d2f74c0214c79d6be72917b7bb',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831786460416' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-55', coinUnit: 'UNKNOWN-55', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xb515fb0b434c6e8652871fb1995b99e7e3fb91d2f74c0214c79d6be72917b7bb',
            txnLineId: 1,
            dateTimeSEC: 1674041171,
            dateTime: '2023-01-18T11:26:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831786460416',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831786460416',
            unit: '399831786460416',
            currencyId: 'COIN.399831786460416',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831786460416',
            unit: '399831786460416',
            currencyId: 'COIN.399831786460416',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x3f3d057bc6396dc8acc5020d429f83aeb344866412dbc9f6c23340fae4916e1a',
        created: 1673878331,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3f3d057bc6396dc8acc5020d429f83aeb344866412dbc9f6c23340fae4916e1a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825912974848' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-54', coinUnit: 'UNKNOWN-54', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3f3d057bc6396dc8acc5020d429f83aeb344866412dbc9f6c23340fae4916e1a',
            txnLineId: 1,
            dateTimeSEC: 1673878331,
            dateTime: '2023-01-16T14:12:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825912974848',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825912974848',
            unit: '399825912974848',
            currencyId: 'COIN.399825912974848',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825912974848',
            unit: '399825912974848',
            currencyId: 'COIN.399825912974848',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x3e7df43113341901080e199658dd48f218cae53fad205f92e3bc44ffb210f5c2',
        created: 1673615663,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3e7df43113341901080e199658dd48f218cae53fad205f92e3bc44ffb210f5c2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825876809986' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-53', coinUnit: 'UNKNOWN-53', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3e7df43113341901080e199658dd48f218cae53fad205f92e3bc44ffb210f5c2',
            txnLineId: 1,
            dateTimeSEC: 1673615663,
            dateTime: '2023-01-13T13:14:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825876809986',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876809986',
            unit: '399825876809986',
            currencyId: 'COIN.399825876809986',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876809986',
            unit: '399825876809986',
            currencyId: 'COIN.399825876809986',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x96b845b036df82d09794ef7eff0b5b6102fb72cbc075f2a791ed82ae7eeebf20',
        created: 1673438687,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x96b845b036df82d09794ef7eff0b5b6102fb72cbc075f2a791ed82ae7eeebf20',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825832248832' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-52', coinUnit: 'UNKNOWN-52', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x96b845b036df82d09794ef7eff0b5b6102fb72cbc075f2a791ed82ae7eeebf20',
            txnLineId: 1,
            dateTimeSEC: 1673438687,
            dateTime: '2023-01-11T12:04:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825832248832',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825832248832',
            unit: '399825832248832',
            currencyId: 'COIN.399825832248832',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825832248832',
            unit: '399825832248832',
            currencyId: 'COIN.399825832248832',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xacc8ee9d61a6d9536f4943a927c450fce1925627523937d49e08eafc75b08541',
        created: 1673263343,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xacc8ee9d61a6d9536f4943a927c450fce1925627523937d49e08eafc75b08541',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831803292416' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-51', coinUnit: 'UNKNOWN-51', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xacc8ee9d61a6d9536f4943a927c450fce1925627523937d49e08eafc75b08541',
            txnLineId: 1,
            dateTimeSEC: 1673263343,
            dateTime: '2023-01-09T11:22:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831803292416',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803292416',
            unit: '399831803292416',
            currencyId: 'COIN.399831803292416',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803292416',
            unit: '399831803292416',
            currencyId: 'COIN.399831803292416',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xd330d6d7c727f8a47362f35d9f555b14c354dd40fff64add5e53cc1829517d32',
        created: 1673010503,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xd330d6d7c727f8a47362f35d9f555b14c354dd40fff64add5e53cc1829517d32',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825934128128' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-50', coinUnit: 'UNKNOWN-50', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xd330d6d7c727f8a47362f35d9f555b14c354dd40fff64add5e53cc1829517d32',
            txnLineId: 1,
            dateTimeSEC: 1673010503,
            dateTime: '2023-01-06T13:08:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825934128128',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825934128128',
            unit: '399825934128128',
            currencyId: 'COIN.399825934128128',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825934128128',
            unit: '399825934128128',
            currencyId: 'COIN.399825934128128',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x08106029a1d5624b386bb96513694b198aa6b437b157aaa30e4d22eb262afd63',
        created: 1672841807,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x08106029a1d5624b386bb96513694b198aa6b437b157aaa30e4d22eb262afd63',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825876776192' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-49', coinUnit: 'UNKNOWN-49', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x08106029a1d5624b386bb96513694b198aa6b437b157aaa30e4d22eb262afd63',
            txnLineId: 1,
            dateTimeSEC: 1672841807,
            dateTime: '2023-01-04T14:16:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825876776192',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876776192',
            unit: '399825876776192',
            currencyId: 'COIN.399825876776192',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876776192',
            unit: '399825876776192',
            currencyId: 'COIN.399825876776192',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x952f5be9dcaaab05ab112b3406fc6309b1fdfcc99472787b83a8a415dcd932e1',
        created: 1672760591,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x03ee832367E29a5CD001f65093283eabB5382B62',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x232A68a51D6e07357ae025D2a459c16077327102',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x952f5be9dcaaab05ab112b3406fc6309b1fdfcc99472787b83a8a415dcd932e1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xf242432a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831814695682' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: '7102-ERC1155-15', coinUnit: '7102-ERC1155-15', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x952f5be9dcaaab05ab112b3406fc6309b1fdfcc99472787b83a8a415dcd932e1',
            txnLineId: 1,
            dateTimeSEC: 1672760591,
            dateTime: '2023-01-03T15:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831814695682',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x03ee832367E29a5CD001f65093283eabB5382B62',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814695682',
            unit: '399831814695682',
            currencyId: 'COIN.399831814695682',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814695682',
            unit: '399831814695682',
            currencyId: 'COIN.399831814695682',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xffc674d0ffa0ad2595ab2eb3c038eb716623af1f2927f196aef8aa1dedb6e17c',
        created: 1672567367,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xffc674d0ffa0ad2595ab2eb3c038eb716623af1f2927f196aef8aa1dedb6e17c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825882874115' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-48', coinUnit: 'UNKNOWN-48', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xffc674d0ffa0ad2595ab2eb3c038eb716623af1f2927f196aef8aa1dedb6e17c',
            txnLineId: 1,
            dateTimeSEC: 1672567367,
            dateTime: '2023-01-01T10:02:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825882874115',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825882874115',
            unit: '399825882874115',
            currencyId: 'COIN.399825882874115',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825882874115',
            unit: '399825882874115',
            currencyId: 'COIN.399825882874115',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x2d3421f7880b3957d09afc336948637757273c6f01a773cbaaea72574fb43d2a',
        created: 1671880931,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2d3421f7880b3957d09afc336948637757273c6f01a773cbaaea72574fb43d2a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825876163328' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-6176', coinUnit: 'SUPR-6176', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x2d3421f7880b3957d09afc336948637757273c6f01a773cbaaea72574fb43d2a',
            txnLineId: 1,
            dateTimeSEC: 1671880931,
            dateTime: '2022-12-24T11:22:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825876163328',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825876163328',
            unit: '399825876163328',
            currencyId: 'COIN.399825876163328',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825876163328',
            unit: '399825876163328',
            currencyId: 'COIN.399825876163328',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x7c70b716599269427e1c09652f4eb42a267687a940b996e11e9569f77eb53b8b',
        created: 1671193607,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7c70b716599269427e1c09652f4eb42a267687a940b996e11e9569f77eb53b8b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825914359809' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-47', coinUnit: 'UNKNOWN-47', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7c70b716599269427e1c09652f4eb42a267687a940b996e11e9569f77eb53b8b',
            txnLineId: 1,
            dateTimeSEC: 1671193607,
            dateTime: '2022-12-16T12:26:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825914359809',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825914359809',
            unit: '399825914359809',
            currencyId: 'COIN.399825914359809',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825914359809',
            unit: '399825914359809',
            currencyId: 'COIN.399825914359809',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xc80cd876fad25efc1d0f3d59dd760a181d8a63b96aecf23c89f86cdbeb7778e4',
        created: 1671152159,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x7bA217eC4643A03f3477e9f2976dDAb7bA5b9161',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xc73B17179Bf0C59cD5860Bb25247D1D1092c1088',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc80cd876fad25efc1d0f3d59dd760a181d8a63b96aecf23c89f86cdbeb7778e4',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399827292599040' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'QQL-MP-627', coinUnit: 'QQL-MP-627', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xc80cd876fad25efc1d0f3d59dd760a181d8a63b96aecf23c89f86cdbeb7778e4',
            txnLineId: 1,
            dateTimeSEC: 1671152159,
            dateTime: '2022-12-16T00:55:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399827292599040',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x7bA217eC4643A03f3477e9f2976dDAb7bA5b9161',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399827292599040',
            unit: '399827292599040',
            currencyId: 'COIN.399827292599040',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399827292599040',
            unit: '399827292599040',
            currencyId: 'COIN.399827292599040',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x787827a4eca4a732643fa9a261f72203273b1402a946e981a87d4270dea870d4',
        created: 1671021947,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x787827a4eca4a732643fa9a261f72203273b1402a946e981a87d4270dea870d4',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825910181124' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-46', coinUnit: 'UNKNOWN-46', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x787827a4eca4a732643fa9a261f72203273b1402a946e981a87d4270dea870d4',
            txnLineId: 1,
            dateTimeSEC: 1671021947,
            dateTime: '2022-12-14T12:45:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825910181124',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825910181124',
            unit: '399825910181124',
            currencyId: 'COIN.399825910181124',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825910181124',
            unit: '399825910181124',
            currencyId: 'COIN.399825910181124',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xbe994b2937612fe9fed1562025dec32042974e343948b385407ab2cd9c1df455',
        created: 1670976479,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x4c03eF085F65221297eE7423e0d721CFf28Ed677',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbe994b2937612fe9fed1562025dec32042974e343948b385407ab2cd9c1df455',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001212787694519861' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832026728704' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1325.07',
              failedOrIncomplete: false,
              priceId: '77fc5ee4-5aee-4aca-b486-1c0970ab75bb',
            },
            { coin: 'KNIT-2', coinUnit: 'KNIT-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xbe994b2937612fe9fed1562025dec32042974e343948b385407ab2cd9c1df455',
            txnLineId: 1,
            dateTimeSEC: 1670976479,
            dateTime: '2022-12-14T00:07:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832026728704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1212787694519861' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832026728704',
            unit: '399832026728704',
            currencyId: 'COIN.399832026728704',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832026728704',
            unit: '399832026728704',
            currencyId: 'COIN.399832026728704',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1212787694519861' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
        created: 1670951363,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825936479232' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825936513536' },
            ],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.005489295405878752' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825936479232' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825936513536' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
            { coin: 'MOMENT-31000989', coinUnit: 'MOMENT-31000989', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000746', coinUnit: 'MOMENT-31000746', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
            txnLineId: 1,
            dateTimeSEC: 1670951363,
            dateTime: '2022-12-13T17:09:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825936479232',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
            txnLineId: 2,
            dateTimeSEC: 1670951363,
            dateTime: '2022-12-13T17:09:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825936513536',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
            txnLineId: 3,
            dateTimeSEC: 1670951363,
            dateTime: '2022-12-13T17:09:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825936479232',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x7cf4019320c3f931d13e8129d5435f72c9dae3b19c28d3f93570eacd1b3e118f',
            txnLineId: 4,
            dateTimeSEC: 1670951363,
            dateTime: '2022-12-13T17:09:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825936513536',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5489295405878752' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5489295405878752' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
        created: 1670951183,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945745408' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945606144' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945730048' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945713408' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945581824' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945570816' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945681410' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945683204' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945684993' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945741312' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945734400' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945715968' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945704704' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945700352' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945692928' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945685251' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945695745' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945692672' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945671424' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945746433' },
            ],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.032795464499352015' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945745408' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945606144' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945730048' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945713408' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945581824' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945570816' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945681410' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945683204' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945684993' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945741312' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945734400' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945715968' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945704704' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945700352' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945692928' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945685251' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945695745' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945692672' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945671424' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825945746433' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
            { coin: 'MOMENT-27000016', coinUnit: 'MOMENT-27000016', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000038', coinUnit: 'MOMENT-20000038', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000039', coinUnit: 'MOMENT-20000039', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-27000043', coinUnit: 'MOMENT-27000043', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-29000050', coinUnit: 'MOMENT-29000050', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-21000057', coinUnit: 'MOMENT-21000057', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-23000068', coinUnit: 'MOMENT-23000068', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-24000076', coinUnit: 'MOMENT-24000076', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000077', coinUnit: 'MOMENT-20000077', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-21000092', coinUnit: 'MOMENT-21000092', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-22000096', coinUnit: 'MOMENT-22000096', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000070', coinUnit: 'MOMENT-31000070', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000741', coinUnit: 'MOMENT-31000741', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000069', coinUnit: 'MOMENT-31000069', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000928', coinUnit: 'MOMENT-31000928', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000740', coinUnit: 'MOMENT-31000740', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000067', coinUnit: 'MOMENT-31000067', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000743', coinUnit: 'MOMENT-31000743', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000742', coinUnit: 'MOMENT-31000742', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000745', coinUnit: 'MOMENT-31000745', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 1,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945745408',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 2,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945606144',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 3,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945730048',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 4,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945713408',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 5,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945581824',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 6,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945570816',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 7,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945681410',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 8,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945683204',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 9,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945684993',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 10,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945741312',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 11,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945734400',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 12,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945715968',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 13,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945704704',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 14,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945700352',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 15,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945692928',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 16,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945685251',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 17,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945695745',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 18,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945692672',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 19,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945671424',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 20,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945746433',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 21,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945745408',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 22,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945606144',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 23,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945730048',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 24,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945713408',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 25,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945581824',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 26,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945570816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 27,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945681410',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 28,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945683204',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 29,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945684993',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 30,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945741312',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 31,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945734400',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 32,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945715968',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 33,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945704704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 34,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945700352',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 35,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945692928',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 36,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945685251',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 37,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945695745',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 38,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945692672',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 39,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945671424',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x96ef05fec77986d7da9712867dbc1a82561bddfd2797ec898486d21d79227980',
            txnLineId: 40,
            dateTimeSEC: 1670951183,
            dateTime: '2022-12-13T17:06:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825945746433',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-32795464499352015' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '32795464499352015' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x330d4661a61dba1b360f7d76b59cb4cdd48d045af1e3fa36c5303d493dcb92ad',
        created: 1670951147,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0A1BBD57033F57E7B6743621b79fCB9Eb2CE3676',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x330d4661a61dba1b360f7d76b59cb4cdd48d045af1e3fa36c5303d493dcb92ad',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00140702751765072' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1407027517650720' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1407027517650720' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
        created: 1670951003,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6B3F349e304Fa1E765CD6D7366aED18B5A576E6d',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0c1a7a33'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.01727010535520818' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832094439168' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832126206467' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832093473024' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832126424320' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832094456320' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832126089472' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832094473984' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832125997570' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
            { coin: 'GTC6-52', coinUnit: 'GTC6-52', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-5000692', coinUnit: 'CITIZEN-5000692', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'GTC6-89', coinUnit: 'GTC6-89', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-5000699', coinUnit: 'CITIZEN-5000699', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'GTC6-111', coinUnit: 'GTC6-111', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-5000702', coinUnit: 'CITIZEN-5000702', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'GTC6-242', coinUnit: 'GTC6-242', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-5000728', coinUnit: 'CITIZEN-5000728', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 1,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832094439168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcff43A597911a9457071d89d2b2AC3D5b1862b86',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 2,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832126206467',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 3,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832093473024',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcff43A597911a9457071d89d2b2AC3D5b1862b86',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 4,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832126424320',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 5,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832094456320',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcff43A597911a9457071d89d2b2AC3D5b1862b86',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 6,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832126089472',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 7,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832094473984',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcff43A597911a9457071d89d2b2AC3D5b1862b86',
          },
          {
            id: '0xb3d655f315834e6e351a8fb84d47ff8f3e0f4800fb0871ad9a0968423b17f625',
            txnLineId: 8,
            dateTimeSEC: 1670951003,
            dateTime: '2022-12-13T17:03:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832125997570',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-17270105355208180' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094439168',
            unit: '399832094439168',
            currencyId: 'COIN.399832094439168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126206467',
            unit: '399832126206467',
            currencyId: 'COIN.399832126206467',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832093473024',
            unit: '399832093473024',
            currencyId: 'COIN.399832093473024',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126424320',
            unit: '399832126424320',
            currencyId: 'COIN.399832126424320',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094456320',
            unit: '399832094456320',
            currencyId: 'COIN.399832094456320',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126089472',
            unit: '399832126089472',
            currencyId: 'COIN.399832126089472',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094473984',
            unit: '399832094473984',
            currencyId: 'COIN.399832094473984',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832125997570',
            unit: '399832125997570',
            currencyId: 'COIN.399832125997570',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094439168',
            unit: '399832094439168',
            currencyId: 'COIN.399832094439168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126206467',
            unit: '399832126206467',
            currencyId: 'COIN.399832126206467',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832093473024',
            unit: '399832093473024',
            currencyId: 'COIN.399832093473024',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126424320',
            unit: '399832126424320',
            currencyId: 'COIN.399832126424320',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094456320',
            unit: '399832094456320',
            currencyId: 'COIN.399832094456320',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832126089472',
            unit: '399832126089472',
            currencyId: 'COIN.399832126089472',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832094473984',
            unit: '399832094473984',
            currencyId: 'COIN.399832094473984',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832125997570',
            unit: '399832125997570',
            currencyId: 'COIN.399832125997570',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '17270105355208180' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xf5d3932fa7fa7f84d02572e1d8605e6c53e051baf06a7ac26b91b611305e9237',
        created: 1670950943,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xE0D1Fa3fBd72db2eD179F80C0459B7dA93Fe4FE8',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf5d3932fa7fa7f84d02572e1d8605e6c53e051baf06a7ac26b91b611305e9237',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.0020394236934451' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2039423693445100' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2039423693445100' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
        created: 1670950919,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.0462' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6B3F349e304Fa1E765CD6D7366aED18B5A576E6d',
            amount: { value: { mathjs: 'BigNumber', value: '0.0462' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb113969'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.040238225717210488' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.0462' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945695745' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945700352' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945715968' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945685251' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945704704' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945671424' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945692672' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945746433' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825936513536' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945692928' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825936479232' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1307.1',
              failedOrIncomplete: false,
              priceId: 'bff0695c-8c8a-4ec6-b5ca-e24d65961e42',
            },
            { coin: 'MOMENT-31000067', coinUnit: 'MOMENT-31000067', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000069', coinUnit: 'MOMENT-31000069', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000070', coinUnit: 'MOMENT-31000070', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000740', coinUnit: 'MOMENT-31000740', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000741', coinUnit: 'MOMENT-31000741', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000742', coinUnit: 'MOMENT-31000742', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000743', coinUnit: 'MOMENT-31000743', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000745', coinUnit: 'MOMENT-31000745', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000746', coinUnit: 'MOMENT-31000746', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000928', coinUnit: 'MOMENT-31000928', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-31000989', coinUnit: 'MOMENT-31000989', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 1,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.0462',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x6B3F349e304Fa1E765CD6D7366aED18B5A576E6d',
            feeAmount: '0.040238225717210488',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 2,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945695745',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 3,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945700352',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 4,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945715968',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 5,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945685251',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 6,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945704704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 7,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945671424',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 8,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945692672',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 9,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945746433',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 10,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825936513536',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 11,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945692928',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x2a2afc778c4d9358847bb16b029763150d2d59f5be6994ce3fae7260108c655a',
            txnLineId: 12,
            dateTimeSEC: 1670950919,
            dateTime: '2022-12-13T17:01:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825936479232',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x93F01412C062C99C6ef105b1BAd93800B5635479',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-40238225717210488' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-46200000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-46200000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945695745',
            unit: '399825945695745',
            currencyId: 'COIN.399825945695745',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945700352',
            unit: '399825945700352',
            currencyId: 'COIN.399825945700352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945715968',
            unit: '399825945715968',
            currencyId: 'COIN.399825945715968',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945685251',
            unit: '399825945685251',
            currencyId: 'COIN.399825945685251',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945704704',
            unit: '399825945704704',
            currencyId: 'COIN.399825945704704',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945671424',
            unit: '399825945671424',
            currencyId: 'COIN.399825945671424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692672',
            unit: '399825945692672',
            currencyId: 'COIN.399825945692672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945746433',
            unit: '399825945746433',
            currencyId: 'COIN.399825945746433',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936513536',
            unit: '399825936513536',
            currencyId: 'COIN.399825936513536',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945692928',
            unit: '399825945692928',
            currencyId: 'COIN.399825945692928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825936479232',
            unit: '399825936479232',
            currencyId: 'COIN.399825936479232',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '40238225717210488' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x92839df2b0fd30c0225124713b8b5aee7b35d1c6b30efbbbff1277f6b6830de9',
        created: 1670853599,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x92839df2b0fd30c0225124713b8b5aee7b35d1c6b30efbbbff1277f6b6830de9',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831782116352' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-45', coinUnit: 'UNKNOWN-45', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x92839df2b0fd30c0225124713b8b5aee7b35d1c6b30efbbbff1277f6b6830de9',
            txnLineId: 1,
            dateTimeSEC: 1670853599,
            dateTime: '2022-12-12T13:59:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831782116352',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831782116352',
            unit: '399831782116352',
            currencyId: 'COIN.399831782116352',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831782116352',
            unit: '399831782116352',
            currencyId: 'COIN.399831782116352',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x83ae6c6d5ad45447c31e83ace96cb7a427b86dcffcad02b7234d1732f5a61a12',
        created: 1670596379,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x83ae6c6d5ad45447c31e83ace96cb7a427b86dcffcad02b7234d1732f5a61a12',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825965931008' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-44', coinUnit: 'UNKNOWN-44', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x83ae6c6d5ad45447c31e83ace96cb7a427b86dcffcad02b7234d1732f5a61a12',
            txnLineId: 1,
            dateTimeSEC: 1670596379,
            dateTime: '2022-12-09T14:32:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825965931008',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825965931008',
            unit: '399825965931008',
            currencyId: 'COIN.399825965931008',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825965931008',
            unit: '399825965931008',
            currencyId: 'COIN.399825965931008',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xb031b67675bb9a2428612df3e572fbf3e3d2cfa0e80d42a1e2a2b4f72eac9968',
        created: 1670415323,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb031b67675bb9a2428612df3e572fbf3e3d2cfa0e80d42a1e2a2b4f72eac9968',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825878189057' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-43', coinUnit: 'UNKNOWN-43', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xb031b67675bb9a2428612df3e572fbf3e3d2cfa0e80d42a1e2a2b4f72eac9968',
            txnLineId: 1,
            dateTimeSEC: 1670415323,
            dateTime: '2022-12-07T12:15:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825878189057',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878189057',
            unit: '399825878189057',
            currencyId: 'COIN.399825878189057',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878189057',
            unit: '399825878189057',
            currencyId: 'COIN.399825878189057',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x2cd1af1f6aa8e7d53a0708ec6ee8d9ec42333398c2749baa29c57f17af02a2bf',
        created: 1670256335,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2cd1af1f6aa8e7d53a0708ec6ee8d9ec42333398c2749baa29c57f17af02a2bf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x695c96e6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825823625472' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-42', coinUnit: 'UNKNOWN-42', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x2cd1af1f6aa8e7d53a0708ec6ee8d9ec42333398c2749baa29c57f17af02a2bf',
            txnLineId: 1,
            dateTimeSEC: 1670256335,
            dateTime: '2022-12-05T16:05:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825823625472',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825823625472',
            unit: '399825823625472',
            currencyId: 'COIN.399825823625472',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825823625472',
            unit: '399825823625472',
            currencyId: 'COIN.399825823625472',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x9ef179bc77c040cac5a604b92fdc90be10f9be276c0db0a0bafc7d09ea5a4f85',
        created: 1670254079,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9ef179bc77c040cac5a604b92fdc90be10f9be276c0db0a0bafc7d09ea5a4f85',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825823625472' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-42', coinUnit: 'UNKNOWN-42', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9ef179bc77c040cac5a604b92fdc90be10f9be276c0db0a0bafc7d09ea5a4f85',
            txnLineId: 1,
            dateTimeSEC: 1670254079,
            dateTime: '2022-12-05T15:27:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825823625472',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825823625472',
            unit: '399825823625472',
            currencyId: 'COIN.399825823625472',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825823625472',
            unit: '399825823625472',
            currencyId: 'COIN.399825823625472',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x4b86c2c0f40090b4c1e53a47f3ba27388f974a45073626fbc331a63cd41c7f54',
        created: 1669983155,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4b86c2c0f40090b4c1e53a47f3ba27388f974a45073626fbc331a63cd41c7f54',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831778388224' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-41', coinUnit: 'UNKNOWN-41', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4b86c2c0f40090b4c1e53a47f3ba27388f974a45073626fbc331a63cd41c7f54',
            txnLineId: 1,
            dateTimeSEC: 1669983155,
            dateTime: '2022-12-02T12:12:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831778388224',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831778388224',
            unit: '399831778388224',
            currencyId: 'COIN.399831778388224',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831778388224',
            unit: '399831778388224',
            currencyId: 'COIN.399831778388224',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xcfdd8a9003f6a4a9b84597b0fc5d1f672288bbaac7521fbd994b57458d5409cc',
        created: 1669808747,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xcfdd8a9003f6a4a9b84597b0fc5d1f672288bbaac7521fbd994b57458d5409cc',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831812777728' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-40', coinUnit: 'UNKNOWN-40', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xcfdd8a9003f6a4a9b84597b0fc5d1f672288bbaac7521fbd994b57458d5409cc',
            txnLineId: 1,
            dateTimeSEC: 1669808747,
            dateTime: '2022-11-30T11:45:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831812777728',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831812777728',
            unit: '399831812777728',
            currencyId: 'COIN.399831812777728',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831812777728',
            unit: '399831812777728',
            currencyId: 'COIN.399831812777728',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe1352e162efac0febd11d5755d715d62aedc44e234fe8254ffe4ae7efa79aaac',
        created: 1669760675,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe1352e162efac0febd11d5755d715d62aedc44e234fe8254ffe4ae7efa79aaac',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-7.3' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1222.52',
              failedOrIncomplete: false,
              priceId: 'd2a7e821-8a44-4849-b493-15c4980dce76',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe1352e162efac0febd11d5755d715d62aedc44e234fe8254ffe4ae7efa79aaac',
            txnLineId: 1,
            dateTimeSEC: 1669760675,
            dateTime: '2022-11-29T22:24:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '7.3',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844cfae4c823ac6389855d47106a70c84f067',
            to: '0xabaa9736156cae7236a117de2082b1543fa0551f',
            feeAmount: '0',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '0' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-7300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-7300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [{ value: { mathjs: 'BigNumber', value: '0' }, coin: '10', unit: '10', currencyId: 'COIN.10' }],
      },
      {
        id: '0x4f6fd0b40643a166a00c64ec3c1d7ac739b2b5b457b84aa1c55d6bdcaad45192',
        created: 1669642007,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4f6fd0b40643a166a00c64ec3c1d7ac739b2b5b457b84aa1c55d6bdcaad45192',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831900945152' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-39', coinUnit: 'UNKNOWN-39', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4f6fd0b40643a166a00c64ec3c1d7ac739b2b5b457b84aa1c55d6bdcaad45192',
            txnLineId: 1,
            dateTimeSEC: 1669642007,
            dateTime: '2022-11-28T13:26:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831900945152',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831900945152',
            unit: '399831900945152',
            currencyId: 'COIN.399831900945152',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831900945152',
            unit: '399831900945152',
            currencyId: 'COIN.399831900945152',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x47a7188b0246e0793d5fb50d660413da86a179372678ace43c61b3f4585588a7',
        created: 1669215575,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x47a7188b0246e0793d5fb50d660413da86a179372678ace43c61b3f4585588a7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831814683392' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-38', coinUnit: 'UNKNOWN-38', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x47a7188b0246e0793d5fb50d660413da86a179372678ace43c61b3f4585588a7',
            txnLineId: 1,
            dateTimeSEC: 1669215575,
            dateTime: '2022-11-23T14:59:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831814683392',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814683392',
            unit: '399831814683392',
            currencyId: 'COIN.399831814683392',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814683392',
            unit: '399831814683392',
            currencyId: 'COIN.399831814683392',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
        created: 1668880475,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022846720' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945741312' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832113204738' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945734400' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-740', coinUnit: 'MPMX-740', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-21000092', coinUnit: 'MOMENT-21000092', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-928', coinUnit: 'MPMX-928', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-22000096', coinUnit: 'MOMENT-22000096', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
            txnLineId: 1,
            dateTimeSEC: 1668880475,
            dateTime: '2022-11-19T17:54:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022846720',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
            txnLineId: 2,
            dateTimeSEC: 1668880475,
            dateTime: '2022-11-19T17:54:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945741312',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
            txnLineId: 3,
            dateTimeSEC: 1668880475,
            dateTime: '2022-11-19T17:54:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832113204738',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xf99bdd89f7e3186e63d71a4a3ffb53cb5cd1c3190ce3771c966f2a82b3346bee',
            txnLineId: 4,
            dateTimeSEC: 1668880475,
            dateTime: '2022-11-19T17:54:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945734400',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022846720',
            unit: '399832022846720',
            currencyId: 'COIN.399832022846720',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832113204738',
            unit: '399832113204738',
            currencyId: 'COIN.399832113204738',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022846720',
            unit: '399832022846720',
            currencyId: 'COIN.399832022846720',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945741312',
            unit: '399825945741312',
            currencyId: 'COIN.399825945741312',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832113204738',
            unit: '399832113204738',
            currencyId: 'COIN.399832113204738',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945734400',
            unit: '399825945734400',
            currencyId: 'COIN.399825945734400',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
        created: 1668880247,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025561600' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945684993' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025607168' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945683204' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-67', coinUnit: 'MPMX-67', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000077', coinUnit: 'MOMENT-20000077', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-69', coinUnit: 'MPMX-69', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-24000076', coinUnit: 'MOMENT-24000076', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
            txnLineId: 1,
            dateTimeSEC: 1668880247,
            dateTime: '2022-11-19T17:50:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025561600',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
            txnLineId: 2,
            dateTimeSEC: 1668880247,
            dateTime: '2022-11-19T17:50:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945684993',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
            txnLineId: 3,
            dateTimeSEC: 1668880247,
            dateTime: '2022-11-19T17:50:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025607168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x046acd9f197aaa2caf0ab829e9a855e0e5dd305bb4fe932b6f2637b432fd34b5',
            txnLineId: 4,
            dateTimeSEC: 1668880247,
            dateTime: '2022-11-19T17:50:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945683204',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025607168',
            unit: '399832025607168',
            currencyId: 'COIN.399832025607168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945684993',
            unit: '399825945684993',
            currencyId: 'COIN.399825945684993',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025607168',
            unit: '399832025607168',
            currencyId: 'COIN.399832025607168',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945683204',
            unit: '399825945683204',
            currencyId: 'COIN.399825945683204',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
        created: 1668795095,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022940928' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945570816' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832114441219' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945681410' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-745', coinUnit: 'MPMX-745', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-21000057', coinUnit: 'MOMENT-21000057', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-989', coinUnit: 'MPMX-989', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-23000068', coinUnit: 'MOMENT-23000068', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
            txnLineId: 1,
            dateTimeSEC: 1668795095,
            dateTime: '2022-11-18T18:11:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022940928',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
            txnLineId: 2,
            dateTimeSEC: 1668795095,
            dateTime: '2022-11-18T18:11:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945570816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
            txnLineId: 3,
            dateTimeSEC: 1668795095,
            dateTime: '2022-11-18T18:11:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832114441219',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xc117e456c147537d6d4f4512076397b67588e0cc1bfad682205f44741b6ce266',
            txnLineId: 4,
            dateTimeSEC: 1668795095,
            dateTime: '2022-11-18T18:11:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945681410',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022940928',
            unit: '399832022940928',
            currencyId: 'COIN.399832022940928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832114441219',
            unit: '399832114441219',
            currencyId: 'COIN.399832114441219',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022940928',
            unit: '399832022940928',
            currencyId: 'COIN.399832022940928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945570816',
            unit: '399825945570816',
            currencyId: 'COIN.399825945570816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832114441219',
            unit: '399832114441219',
            currencyId: 'COIN.399832114441219',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945681410',
            unit: '399825945681410',
            currencyId: 'COIN.399825945681410',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfb303ec9877a12e951cabcdc2f0a518d0556f8c58f224735961d683ba7a7f8e3',
        created: 1668794915,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfb303ec9877a12e951cabcdc2f0a518d0556f8c58f224735961d683ba7a7f8e3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025684736' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945581824' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-70', coinUnit: 'MPMX-70', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-29000050', coinUnit: 'MOMENT-29000050', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfb303ec9877a12e951cabcdc2f0a518d0556f8c58f224735961d683ba7a7f8e3',
            txnLineId: 1,
            dateTimeSEC: 1668794915,
            dateTime: '2022-11-18T18:08:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025684736',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xfb303ec9877a12e951cabcdc2f0a518d0556f8c58f224735961d683ba7a7f8e3',
            txnLineId: 2,
            dateTimeSEC: 1668794915,
            dateTime: '2022-11-18T18:08:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945581824',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025684736',
            unit: '399832025684736',
            currencyId: 'COIN.399832025684736',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025684736',
            unit: '399832025684736',
            currencyId: 'COIN.399832025684736',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945581824',
            unit: '399825945581824',
            currencyId: 'COIN.399825945581824',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe73788e9edea9b6777ae02b9649560533f74bb911d5b8ced9d092655aa924dc3',
        created: 1668781271,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe73788e9edea9b6777ae02b9649560533f74bb911d5b8ced9d092655aa924dc3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825812733696' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-37', coinUnit: 'UNKNOWN-37', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe73788e9edea9b6777ae02b9649560533f74bb911d5b8ced9d092655aa924dc3',
            txnLineId: 1,
            dateTimeSEC: 1668781271,
            dateTime: '2022-11-18T14:21:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825812733696',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825812733696',
            unit: '399825812733696',
            currencyId: 'COIN.399825812733696',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825812733696',
            unit: '399825812733696',
            currencyId: 'COIN.399825812733696',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x2224e22886ae9a69161c61d51559366845cf4f486b91250bc2adbf6425115004',
        created: 1668727331,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2224e22886ae9a69161c61d51559366845cf4f486b91250bc2adbf6425115004',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022986752' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945713408' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-742', coinUnit: 'MPMX-742', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-27000043', coinUnit: 'MOMENT-27000043', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x2224e22886ae9a69161c61d51559366845cf4f486b91250bc2adbf6425115004',
            txnLineId: 1,
            dateTimeSEC: 1668727331,
            dateTime: '2022-11-17T23:22:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022986752',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x2224e22886ae9a69161c61d51559366845cf4f486b91250bc2adbf6425115004',
            txnLineId: 2,
            dateTimeSEC: 1668727331,
            dateTime: '2022-11-17T23:22:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945713408',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022986752',
            unit: '399832022986752',
            currencyId: 'COIN.399832022986752',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022986752',
            unit: '399832022986752',
            currencyId: 'COIN.399832022986752',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945713408',
            unit: '399825945713408',
            currencyId: 'COIN.399825945713408',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
        created: 1668724835,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022656512' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945606144' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022930688' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945730048' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-741', coinUnit: 'MPMX-741', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000038', coinUnit: 'MOMENT-20000038', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-746', coinUnit: 'MPMX-746', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-20000039', coinUnit: 'MOMENT-20000039', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
            txnLineId: 1,
            dateTimeSEC: 1668724835,
            dateTime: '2022-11-17T22:40:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022656512',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
            txnLineId: 2,
            dateTimeSEC: 1668724835,
            dateTime: '2022-11-17T22:40:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945606144',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
            txnLineId: 3,
            dateTimeSEC: 1668724835,
            dateTime: '2022-11-17T22:40:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022930688',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0xacf22785af728e8b0db3da50f478efbfef3691404c0fa2f9940e8887208b3f52',
            txnLineId: 4,
            dateTimeSEC: 1668724835,
            dateTime: '2022-11-17T22:40:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945730048',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945606144',
            unit: '399825945606144',
            currencyId: 'COIN.399825945606144',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945730048',
            unit: '399825945730048',
            currencyId: 'COIN.399825945730048',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x3fee624a057ebd34980cad79ac8cf28ed9fe0861bddafa63dc2796b5662b3514',
        created: 1668620915,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x18B7511938FBe2EE08ADf3d4A24edB00A5C9B783',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b822c8AA80d0DF084E740f919A96D43Ef2537b9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3fee624a057ebd34980cad79ac8cf28ed9fe0861bddafa63dc2796b5662b3514',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x92220038'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022939393' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825945745408' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'BRTMPMX', coinUnit: 'BRTMPMX', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-743', coinUnit: 'MPMX-743', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MOMENT-27000016', coinUnit: 'MOMENT-27000016', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3fee624a057ebd34980cad79ac8cf28ed9fe0861bddafa63dc2796b5662b3514',
            txnLineId: 1,
            dateTimeSEC: 1668620915,
            dateTime: '2022-11-16T17:48:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022939393',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x3fee624a057ebd34980cad79ac8cf28ed9fe0861bddafa63dc2796b5662b3514',
            txnLineId: 2,
            dateTimeSEC: 1668620915,
            dateTime: '2022-11-16T17:48:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825945745408',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825945745408',
            unit: '399825945745408',
            currencyId: 'COIN.399825945745408',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x6659ab01bff53ada30428d3a7c0545fd69414677b241f5a4c09a23c883bc102d',
        created: 1668611111,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6659ab01bff53ada30428d3a7c0545fd69414677b241f5a4c09a23c883bc102d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831767262464' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-36', coinUnit: 'UNKNOWN-36', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6659ab01bff53ada30428d3a7c0545fd69414677b241f5a4c09a23c883bc102d',
            txnLineId: 1,
            dateTimeSEC: 1668611111,
            dateTime: '2022-11-16T15:05:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831767262464',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831767262464',
            unit: '399831767262464',
            currencyId: 'COIN.399831767262464',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831767262464',
            unit: '399831767262464',
            currencyId: 'COIN.399831767262464',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x810995eb25a6f7ba130ad43d2cf6072cc6d7e6b3935f84cc64529cd78ca9a680',
        created: 1668441275,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x810995eb25a6f7ba130ad43d2cf6072cc6d7e6b3935f84cc64529cd78ca9a680',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.399825850966529' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-35', coinUnit: 'UNKNOWN-35', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x810995eb25a6f7ba130ad43d2cf6072cc6d7e6b3935f84cc64529cd78ca9a680',
            txnLineId: 1,
            dateTimeSEC: 1668441275,
            dateTime: '2022-11-14T15:54:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825850966529',
            amount: '2',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399825850966529',
            unit: '399825850966529',
            currencyId: 'COIN.399825850966529',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399825850966529',
            unit: '399825850966529',
            currencyId: 'COIN.399825850966529',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf3a9d5666aa326a3393e10b64f6c65880a5f8e226b1235f67b530247d85fd6bb',
        created: 1668013727,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf3a9d5666aa326a3393e10b64f6c65880a5f8e226b1235f67b530247d85fd6bb',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825977465856' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-34', coinUnit: 'UNKNOWN-34', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf3a9d5666aa326a3393e10b64f6c65880a5f8e226b1235f67b530247d85fd6bb',
            txnLineId: 1,
            dateTimeSEC: 1668013727,
            dateTime: '2022-11-09T17:08:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825977465856',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825977465856',
            unit: '399825977465856',
            currencyId: 'COIN.399825977465856',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825977465856',
            unit: '399825977465856',
            currencyId: 'COIN.399825977465856',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xee7f182dc5851e44e52e1e174ed0148bf1a5e289321133780cf4f3ab6cbed69f',
        created: 1667837147,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xee7f182dc5851e44e52e1e174ed0148bf1a5e289321133780cf4f3ab6cbed69f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826015065601' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-33', coinUnit: 'UNKNOWN-33', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xee7f182dc5851e44e52e1e174ed0148bf1a5e289321133780cf4f3ab6cbed69f',
            txnLineId: 1,
            dateTimeSEC: 1667837147,
            dateTime: '2022-11-07T16:05:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826015065601',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826015065601',
            unit: '399826015065601',
            currencyId: 'COIN.399826015065601',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826015065601',
            unit: '399826015065601',
            currencyId: 'COIN.399826015065601',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa3fc27e814f233d9caaf8f0b138929bfaf21129278386efb2c656ae351d1c7ab',
        created: 1667400971,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa3fc27e814f233d9caaf8f0b138929bfaf21129278386efb2c656ae351d1c7ab',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831792496899' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-32', coinUnit: 'UNKNOWN-32', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa3fc27e814f233d9caaf8f0b138929bfaf21129278386efb2c656ae351d1c7ab',
            txnLineId: 1,
            dateTimeSEC: 1667400971,
            dateTime: '2022-11-02T14:56:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831792496899',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831792496899',
            unit: '399831792496899',
            currencyId: 'COIN.399831792496899',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831792496899',
            unit: '399831792496899',
            currencyId: 'COIN.399831792496899',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xc28c21ad5d7ebf58eaa740461506e4abb64e41709c045a4b6b14a031b2012880',
        created: 1667347619,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc28c21ad5d7ebf58eaa740461506e4abb64e41709c045a4b6b14a031b2012880',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831844905984' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-139', coinUnit: 'QQL-139', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xc28c21ad5d7ebf58eaa740461506e4abb64e41709c045a4b6b14a031b2012880',
            txnLineId: 1,
            dateTimeSEC: 1667347619,
            dateTime: '2022-11-02T00:06:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831844905984',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831844905984',
            unit: '399831844905984',
            currencyId: 'COIN.399831844905984',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831844905984',
            unit: '399831844905984',
            currencyId: 'COIN.399831844905984',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa0d4abfd2d7cbbab1eac60c7dc8b936cf9231d0025ba29cfc473332744891674',
        created: 1667270675,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1053219fD456DB18BfA32938c8D0b5935aae88f4',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa0d4abfd2d7cbbab1eac60c7dc8b936cf9231d0025ba29cfc473332744891674',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00048759096450147' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1598.37',
              failedOrIncomplete: false,
              priceId: '76277b36-2507-4ca3-975c-6ad5737c2f81',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-487590964501470' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '487590964501470' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
        created: 1667266427,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945856256' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945664768' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945729280' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945679616' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945673984' },
            ],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002679225634645956' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831945856256' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831945664768' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831945729280' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831945679616' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831945673984' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
            { coin: 'wm-5', coinUnit: 'wm-5', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'wm-4', coinUnit: 'wm-4', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'wm-3', coinUnit: 'wm-3', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'LIOM-679', coinUnit: 'LIOM-679', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'LIOM-825', coinUnit: 'LIOM-825', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 1,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945856256',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 2,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945664768',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 3,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945729280',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 4,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945679616',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 5,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945673984',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 6,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831945856256',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 7,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831945664768',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 8,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831945729280',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 9,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831945679616',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0xbefa6a512798631e510f12272b5325a3c3d6ee0cbcea8f5d6bfccaef37dbfc74',
            txnLineId: 10,
            dateTimeSEC: 1667266427,
            dateTime: '2022-11-01T01:33:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831945673984',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2679225634645956' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2679225634645956' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xf7a1060a0e85adf912526f46b541b08fdc7277f4384a667f174e01b4631bb491',
        created: 1667266391,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6144226e04dce7F9d9cC468b98D8667648613Ed9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf7a1060a0e85adf912526f46b541b08fdc7277f4384a667f174e01b4631bb491',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.000576809986919815' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-576809986919815' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '576809986919815' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe999d9a2e5d8186de6beed4ab9442cc7e496a91eceea82e40b273ce4ec3af1c0',
        created: 1667266355,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xFAfE3E835759eE0615cE67c3de5B6268E9a0A446',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe999d9a2e5d8186de6beed4ab9442cc7e496a91eceea82e40b273ce4ec3af1c0',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.000564879231431276' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-564879231431276' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '564879231431276' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xc92bc3a35c3ce061b2f4581d9eff07e1b318f89a02c5df715ca40c27775d0191',
        created: 1667266199,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc92bc3a35c3ce061b2f4581d9eff07e1b318f89a02c5df715ca40c27775d0191',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xcce7ec13'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001171974538397955' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-50' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945729280' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
            { coin: 'wm-3', coinUnit: 'wm-3', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xc92bc3a35c3ce061b2f4581d9eff07e1b318f89a02c5df715ca40c27775d0191',
            txnLineId: 1,
            dateTimeSEC: 1667266199,
            dateTime: '2022-11-01T01:29:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945729280',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xc92bc3a35c3ce061b2f4581d9eff07e1b318f89a02c5df715ca40c27775d0191',
            txnLineId: 2,
            dateTimeSEC: 1667266199,
            dateTime: '2022-11-01T01:29:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '50',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            feeAmount: '0.001171974538397955',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1171974538397955' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945729280',
            unit: '399831945729280',
            currencyId: 'COIN.399831945729280',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1171974538397955' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x703d74ce50f222d97239d6fe30675e8a0c548cd7f8b8041064fb4140d743ea86',
        created: 1667266139,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x703d74ce50f222d97239d6fe30675e8a0c548cd7f8b8041064fb4140d743ea86',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xcce7ec13'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00118685282582592' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-50' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945664768' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
            { coin: 'wm-4', coinUnit: 'wm-4', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x703d74ce50f222d97239d6fe30675e8a0c548cd7f8b8041064fb4140d743ea86',
            txnLineId: 1,
            dateTimeSEC: 1667266139,
            dateTime: '2022-11-01T01:28:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945664768',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x703d74ce50f222d97239d6fe30675e8a0c548cd7f8b8041064fb4140d743ea86',
            txnLineId: 2,
            dateTimeSEC: 1667266139,
            dateTime: '2022-11-01T01:28:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '50',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            feeAmount: '0.00118685282582592',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1186852825825920' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945664768',
            unit: '399831945664768',
            currencyId: 'COIN.399831945664768',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1186852825825920' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x6900b027225e646f74650e874ed5b33eaedd17ed2832a9fe7fd7b91deff12ead',
        created: 1667266103,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            amount: { value: { mathjs: 'BigNumber', value: '50' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6900b027225e646f74650e874ed5b33eaedd17ed2832a9fe7fd7b91deff12ead',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xcce7ec13'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00152251959347829' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-50' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945856256' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
            { coin: 'wm-5', coinUnit: 'wm-5', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6900b027225e646f74650e874ed5b33eaedd17ed2832a9fe7fd7b91deff12ead',
            txnLineId: 1,
            dateTimeSEC: 1667266103,
            dateTime: '2022-11-01T01:28:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945856256',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x6900b027225e646f74650e874ed5b33eaedd17ed2832a9fe7fd7b91deff12ead',
            txnLineId: 2,
            dateTimeSEC: 1667266103,
            dateTime: '2022-11-01T01:28:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '50',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xA0AE41B1C3f1eCab6842209Bd1d467bD95DB5d72',
            feeAmount: '0.00152251959347829',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1522519593478290' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-50000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945856256',
            unit: '399831945856256',
            currencyId: 'COIN.399831945856256',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1522519593478290' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xed97bc3c85be76815604923b3a89dc308670d27d05fc98e59956492a2e1f7875',
        created: 1667265083,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xc73B17179Bf0C59cD5860Bb25247D1D1092c1088',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xed97bc3c85be76815604923b3a89dc308670d27d05fc98e59956492a2e1f7875',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00070556614359024' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-705566143590240' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '705566143590240' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x405b0b123b3756f445d3e62289ba0c50405c84160ab7106114c39a7aefd9d356',
        created: 1667264987,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xbDdE08BD57e5C9fD563eE7aC61618CB2ECdc0ce0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x405b0b123b3756f445d3e62289ba0c50405c84160ab7106114c39a7aefd9d356',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.000682504884278023' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-682504884278023' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '682504884278023' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xb5576ba0fb0eabf4d5b4bfcc7aa7c6123930384502500e96b6a8cd81d114be74',
        created: 1667264567,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb5576ba0fb0eabf4d5b4bfcc7aa7c6123930384502500e96b6a8cd81d114be74',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.001182941448985548' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.71',
              failedOrIncomplete: false,
              priceId: '0f188278-4a83-47f5-925a-fcbc27307fb6',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1182941448985548' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1182941448985548' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x9abdaa19ba6980e54304ff7efb1aaaee06e728dea4b042ef29e5140493cf0e5c',
        created: 1667264327,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xE0D1Fa3fBd72db2eD179F80C0459B7dA93Fe4FE8',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9abdaa19ba6980e54304ff7efb1aaaee06e728dea4b042ef29e5140493cf0e5c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00074481654950592' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1569.93',
              failedOrIncomplete: false,
              priceId: 'a95845f7-3d46-4ccb-a1ca-27cfadba8714',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-744816549505920' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '744816549505920' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x197d44301eca9ff09ee3dd0fa726b9aa7704b3e6cdefdf335b185d086c8c800b',
        created: 1667226755,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x197d44301eca9ff09ee3dd0fa726b9aa7704b3e6cdefdf335b185d086c8c800b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831814680832' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-31', coinUnit: 'UNKNOWN-31', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x197d44301eca9ff09ee3dd0fa726b9aa7704b3e6cdefdf335b185d086c8c800b',
            txnLineId: 1,
            dateTimeSEC: 1667226755,
            dateTime: '2022-10-31T14:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831814680832',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814680832',
            unit: '399831814680832',
            currencyId: 'COIN.399831814680832',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831814680832',
            unit: '399831814680832',
            currencyId: 'COIN.399831814680832',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf52650a492736670cfb05b8109390e9363b5f212d42b9dd7b98c3d106fe9c005',
        created: 1667146223,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.59' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '0.59' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf52650a492736670cfb05b8109390e9363b5f212d42b9dd7b98c3d106fe9c005',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001730853208635478' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.59' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945679616' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1583',
              failedOrIncomplete: false,
              priceId: 'ebd31afd-17f9-4104-acb9-92087dab81d3',
            },
            { coin: 'LIOM-679', coinUnit: 'LIOM-679', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf52650a492736670cfb05b8109390e9363b5f212d42b9dd7b98c3d106fe9c005',
            txnLineId: 1,
            dateTimeSEC: 1667146223,
            dateTime: '2022-10-30T16:10:23.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.59',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.001730853208635478',
          },
          {
            id: '0xf52650a492736670cfb05b8109390e9363b5f212d42b9dd7b98c3d106fe9c005',
            txnLineId: 2,
            dateTimeSEC: 1667146223,
            dateTime: '2022-10-30T16:10:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945679616',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xee6b47CE06976c9D29787e2b9F2802717117955C',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1730853208635478' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-590000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-590000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945679616',
            unit: '399831945679616',
            currencyId: 'COIN.399831945679616',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1730853208635478' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x30f1ee9adfeeb2f42479afe61614d6d3f164c5fe68529720cb06c9a22afc182b',
        created: 1667146187,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.51' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '0.51' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x30f1ee9adfeeb2f42479afe61614d6d3f164c5fe68529720cb06c9a22afc182b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.000533689882899533' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1583',
              failedOrIncomplete: false,
              priceId: 'ebd31afd-17f9-4104-acb9-92087dab81d3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-533689882899533' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '533689882899533' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x045eaacd55410f8740e00e0bf300879caf4f2730997d7e46a753a98181da80e1',
        created: 1667145239,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.28' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '0.28' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x045eaacd55410f8740e00e0bf300879caf4f2730997d7e46a753a98181da80e1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001783775197340248' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.28' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831945673984' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1587.73',
              failedOrIncomplete: false,
              priceId: 'faa1ed3d-12fb-4e23-9a1f-8711610ff4fd',
            },
            { coin: 'LIOM-825', coinUnit: 'LIOM-825', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x045eaacd55410f8740e00e0bf300879caf4f2730997d7e46a753a98181da80e1',
            txnLineId: 1,
            dateTimeSEC: 1667145239,
            dateTime: '2022-10-30T15:53:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.28',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.001783775197340248',
          },
          {
            id: '0x045eaacd55410f8740e00e0bf300879caf4f2730997d7e46a753a98181da80e1',
            txnLineId: 2,
            dateTimeSEC: 1667145239,
            dateTime: '2022-10-30T15:53:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831945673984',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x7bEED30332656CA4220CB2ce8E4508fb18013E8d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1783775197340248' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-280000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-280000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831945673984',
            unit: '399831945673984',
            currencyId: 'COIN.399831945673984',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1783775197340248' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x1f18ef2442dcc89d8dd77a7100091ed30bb645c1c2ff1cb2a049206a213fc524',
        created: 1666969403,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1f18ef2442dcc89d8dd77a7100091ed30bb645c1c2ff1cb2a049206a213fc524',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825904438784' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-30', coinUnit: 'UNKNOWN-30', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1f18ef2442dcc89d8dd77a7100091ed30bb645c1c2ff1cb2a049206a213fc524',
            txnLineId: 1,
            dateTimeSEC: 1666969403,
            dateTime: '2022-10-28T15:03:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825904438784',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825904438784',
            unit: '399825904438784',
            currencyId: 'COIN.399825904438784',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825904438784',
            unit: '399825904438784',
            currencyId: 'COIN.399825904438784',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x97113bab8f585f0728a142057b1365f681864f497b0b92a7491644950bdb3ede',
        created: 1666799891,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x97113bab8f585f0728a142057b1365f681864f497b0b92a7491644950bdb3ede',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831821179648' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-29', coinUnit: 'UNKNOWN-29', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x97113bab8f585f0728a142057b1365f681864f497b0b92a7491644950bdb3ede',
            txnLineId: 1,
            dateTimeSEC: 1666799891,
            dateTime: '2022-10-26T15:58:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831821179648',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831821179648',
            unit: '399831821179648',
            currencyId: 'COIN.399831821179648',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831821179648',
            unit: '399831821179648',
            currencyId: 'COIN.399831821179648',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x8d96771b9fc0f3c0f495d7d6d3945c19888b87a52e8a0ab8b2cf48dc6cdafa69',
        created: 1666618931,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8d96771b9fc0f3c0f495d7d6d3945c19888b87a52e8a0ab8b2cf48dc6cdafa69',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825969449984' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-28', coinUnit: 'UNKNOWN-28', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8d96771b9fc0f3c0f495d7d6d3945c19888b87a52e8a0ab8b2cf48dc6cdafa69',
            txnLineId: 1,
            dateTimeSEC: 1666618931,
            dateTime: '2022-10-24T13:42:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825969449984',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825969449984',
            unit: '399825969449984',
            currencyId: 'COIN.399825969449984',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825969449984',
            unit: '399825969449984',
            currencyId: 'COIN.399825969449984',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x2c0d8b6d97f8829bdf226e15d225871d0442afcd35b25fed5183c0b0c7300cdf',
        created: 1666474775,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xd7056F6dc9B94E0F875CefADFD31A706D5724Da9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xc59D9f3eBf7dA9c3b5893df5C34f1d1E8f39dF77',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x2c0d8b6d97f8829bdf226e15d225871d0442afcd35b25fed5183c0b0c7300cdf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xd9d5ee3a'],
        type: 'Unknown',
        walletAmounts: [{ walletId: 'j2R8uH7ZZRxFvECy13V2', amounts: [] }],
        exchangeRates: { exchangeRates: [], exchangeRatesDirty: false },
        txnLines: [],
        fullAmountSet: [],
        fullAmountSetWithoutFees: [],
        paidFees: [],
      },
      {
        id: '0x5e8ffe5c09ef260edbf438962a65ab4ac1486770c9c2556ff2d92a57a164d367',
        created: 1666474703,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xd7056F6dc9B94E0F875CefADFD31A706D5724Da9',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xc59D9f3eBf7dA9c3b5893df5C34f1d1E8f39dF77',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5e8ffe5c09ef260edbf438962a65ab4ac1486770c9c2556ff2d92a57a164d367',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xd9d5ee3a'],
        type: 'Unknown',
        walletAmounts: [{ walletId: 'wYbeINrejmSTvvFz5G3P', amounts: [] }],
        exchangeRates: { exchangeRates: [], exchangeRatesDirty: false },
        txnLines: [],
        fullAmountSet: [],
        fullAmountSetWithoutFees: [],
        paidFees: [],
      },
      {
        id: '0x8204190cf650dcc1c6ca4392e87ba750838d924892e44920aab48566c1e53c36',
        created: 1666366991,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8204190cf650dcc1c6ca4392e87ba750838d924892e44920aab48566c1e53c36',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825846250498' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-27', coinUnit: 'UNKNOWN-27', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8204190cf650dcc1c6ca4392e87ba750838d924892e44920aab48566c1e53c36',
            txnLineId: 1,
            dateTimeSEC: 1666366991,
            dateTime: '2022-10-21T15:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825846250498',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825846250498',
            unit: '399825846250498',
            currencyId: 'COIN.399825846250498',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825846250498',
            unit: '399825846250498',
            currencyId: 'COIN.399825846250498',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xedb4f38f07aa741cd31691bd09c26bd651700fc8b044e244eeccc68a4f4a805d',
        created: 1666276523,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xedb4f38f07aa741cd31691bd09c26bd651700fc8b044e244eeccc68a4f4a805d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.399825980527616' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-26', coinUnit: 'UNKNOWN-26', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xedb4f38f07aa741cd31691bd09c26bd651700fc8b044e244eeccc68a4f4a805d',
            txnLineId: 1,
            dateTimeSEC: 1666276523,
            dateTime: '2022-10-20T14:35:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825980527616',
            amount: '2',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399825980527616',
            unit: '399825980527616',
            currencyId: 'COIN.399825980527616',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399825980527616',
            unit: '399825980527616',
            currencyId: 'COIN.399825980527616',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x6870f2ec9c873841728a644700b72fd07d51cd16396a188b75752e2a4b9cd75e',
        created: 1665680795,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6870f2ec9c873841728a644700b72fd07d51cd16396a188b75752e2a4b9cd75e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '3' }, currencyId: 'COIN.399831790115073' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-25', coinUnit: 'UNKNOWN-25', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6870f2ec9c873841728a644700b72fd07d51cd16396a188b75752e2a4b9cd75e',
            txnLineId: 1,
            dateTimeSEC: 1665680795,
            dateTime: '2022-10-13T17:06:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831790115073',
            amount: '3',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '3' },
            coin: '399831790115073',
            unit: '399831790115073',
            currencyId: 'COIN.399831790115073',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '3' },
            coin: '399831790115073',
            unit: '399831790115073',
            currencyId: 'COIN.399831790115073',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfaaa651fe3166b94deb2c6626038e056f49374c585701d6b88a9c8fae77e060a',
        created: 1665150191,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfaaa651fe3166b94deb2c6626038e056f49374c585701d6b88a9c8fae77e060a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825824369920' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-24', coinUnit: 'UNKNOWN-24', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfaaa651fe3166b94deb2c6626038e056f49374c585701d6b88a9c8fae77e060a',
            txnLineId: 1,
            dateTimeSEC: 1665150191,
            dateTime: '2022-10-07T13:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825824369920',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825824369920',
            unit: '399825824369920',
            currencyId: 'COIN.399825824369920',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825824369920',
            unit: '399825824369920',
            currencyId: 'COIN.399825824369920',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfb55b0ef26119420328330a14aa98df3c038abac11c4d6972e62c114173356c8',
        created: 1665094907,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x25eAff5B179f209Cf186B1cdCbFa463A69Df4C45',
            amount: { value: { mathjs: 'BigNumber', value: '149.9999' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '149.9999' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfb55b0ef26119420328330a14aa98df3c038abac11c4d6972e62c114173356c8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '149.9999' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1350.99',
              failedOrIncomplete: false,
              priceId: 'b086a873-570c-4315-ab21-cf4824950496',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfb55b0ef26119420328330a14aa98df3c038abac11c4d6972e62c114173356c8',
            txnLineId: 1,
            dateTimeSEC: 1665094907,
            dateTime: '2022-10-06T22:21:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '149.9999',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x25eAff5B179f209Cf186B1cdCbFa463A69Df4C45',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '149999900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '149999900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xafa630d8363e99f38a230c6b7d9fd48a1271b019790d831159c3ff56a91bbff6',
        created: 1665066575,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xafa630d8363e99f38a230c6b7d9fd48a1271b019790d831159c3ff56a91bbff6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831792489474' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-23', coinUnit: 'UNKNOWN-23', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xafa630d8363e99f38a230c6b7d9fd48a1271b019790d831159c3ff56a91bbff6',
            txnLineId: 1,
            dateTimeSEC: 1665066575,
            dateTime: '2022-10-06T14:29:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831792489474',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831792489474',
            unit: '399831792489474',
            currencyId: 'COIN.399831792489474',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831792489474',
            unit: '399831792489474',
            currencyId: 'COIN.399831792489474',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x52d805379df9ee98f81cd1f69a7d1c900fab9f603a9a2875f9f12de148b7e6f3',
        created: 1664982647,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x52d805379df9ee98f81cd1f69a7d1c900fab9f603a9a2875f9f12de148b7e6f3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.399831814925572' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-22', coinUnit: 'UNKNOWN-22', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x52d805379df9ee98f81cd1f69a7d1c900fab9f603a9a2875f9f12de148b7e6f3',
            txnLineId: 1,
            dateTimeSEC: 1664982647,
            dateTime: '2022-10-05T15:10:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831814925572',
            amount: '2',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399831814925572',
            unit: '399831814925572',
            currencyId: 'COIN.399831814925572',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2' },
            coin: '399831814925572',
            unit: '399831814925572',
            currencyId: 'COIN.399831814925572',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x5be9f8933dbfa40dde0831cd50a0b514d0d275c7a39a9631c0e91298af541620',
        created: 1664905019,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xd8E36f07F1398a3305fc42e88b58C35Ab5564374',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x55ecA4D1c9eA7021509a7cd88C68c279dABDd37d',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5be9f8933dbfa40dde0831cd50a0b514d0d275c7a39a9631c0e91298af541620',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825879283713' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-14752', coinUnit: 'SUPR-14752', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5be9f8933dbfa40dde0831cd50a0b514d0d275c7a39a9631c0e91298af541620',
            txnLineId: 1,
            dateTimeSEC: 1664905019,
            dateTime: '2022-10-04T17:36:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825879283713',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x55ecA4D1c9eA7021509a7cd88C68c279dABDd37d',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825879283713',
            unit: '399825879283713',
            currencyId: 'COIN.399825879283713',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825879283713',
            unit: '399825879283713',
            currencyId: 'COIN.399825879283713',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x37417788cb30485d96abb21b8f0c82d0ea8b8913b79f72f041f9102d1cf79f70',
        created: 1664900003,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xd8E36f07F1398a3305fc42e88b58C35Ab5564374',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x55ecA4D1c9eA7021509a7cd88C68c279dABDd37d',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x37417788cb30485d96abb21b8f0c82d0ea8b8913b79f72f041f9102d1cf79f70',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831940344320' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-19664', coinUnit: 'SUPR-19664', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x37417788cb30485d96abb21b8f0c82d0ea8b8913b79f72f041f9102d1cf79f70',
            txnLineId: 1,
            dateTimeSEC: 1664900003,
            dateTime: '2022-10-04T16:13:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831940344320',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x55ecA4D1c9eA7021509a7cd88C68c279dABDd37d',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831940344320',
            unit: '399831940344320',
            currencyId: 'COIN.399831940344320',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831940344320',
            unit: '399831940344320',
            currencyId: 'COIN.399831940344320',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x1dd23f384901aea092f9c14a823605799a2014d2fce4ee3e19933c4cf11fde74',
        created: 1664897183,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1dd23f384901aea092f9c14a823605799a2014d2fce4ee3e19933c4cf11fde74',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825950405377' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-21', coinUnit: 'UNKNOWN-21', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1dd23f384901aea092f9c14a823605799a2014d2fce4ee3e19933c4cf11fde74',
            txnLineId: 1,
            dateTimeSEC: 1664897183,
            dateTime: '2022-10-04T15:26:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825950405377',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950405377',
            unit: '399825950405377',
            currencyId: 'COIN.399825950405377',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950405377',
            unit: '399825950405377',
            currencyId: 'COIN.399825950405377',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x6c287bbd086ae61c2755b85eeed18c97b0dc43b7f3ddfe02b95fa2e0a3c8700d',
        created: 1664884415,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6c287bbd086ae61c2755b85eeed18c97b0dc43b7f3ddfe02b95fa2e0a3c8700d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870164992' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-97', coinUnit: 'QQL-97', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6c287bbd086ae61c2755b85eeed18c97b0dc43b7f3ddfe02b95fa2e0a3c8700d',
            txnLineId: 1,
            dateTimeSEC: 1664884415,
            dateTime: '2022-10-04T11:53:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870164992',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870164992',
            unit: '399831870164992',
            currencyId: 'COIN.399831870164992',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870164992',
            unit: '399831870164992',
            currencyId: 'COIN.399831870164992',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xd44fcc26067a1b757437d58ad9841c32f79896a967895675f122cfddfd3a5a8d',
        created: 1664839451,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '8.3' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            amount: { value: { mathjs: 'BigNumber', value: '8.3' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xd44fcc26067a1b757437d58ad9841c32f79896a967895675f122cfddfd3a5a8d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '8.3' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1323.32',
              failedOrIncomplete: false,
              priceId: '7c72b70b-c71f-4819-a79a-b1737c060a75',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xd44fcc26067a1b757437d58ad9841c32f79896a967895675f122cfddfd3a5a8d',
            txnLineId: 1,
            dateTimeSEC: 1664839451,
            dateTime: '2022-10-03T23:24:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '8.3',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '8300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '8300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x99fded9191ed924e14e71681177a120b51183e753bb388fd7a8056ccd307b994',
        created: 1664839043,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x82C7a8f707110f5FBb16184A5933E9F78a34c6ab',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x99fded9191ed924e14e71681177a120b51183e753bb388fd7a8056ccd307b994',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831839525888' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'Emblem.pro-7753376887',
              coinUnit: 'Emblem.pro-7753376887',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x99fded9191ed924e14e71681177a120b51183e753bb388fd7a8056ccd307b994',
            txnLineId: 1,
            dateTimeSEC: 1664839043,
            dateTime: '2022-10-03T23:17:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831839525888',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839525888',
            unit: '399831839525888',
            currencyId: 'COIN.399831839525888',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839525888',
            unit: '399831839525888',
            currencyId: 'COIN.399831839525888',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x247be058bce6d08bc3b2b12bb30238322267d72b90cc4fad0cd451c537145f13',
        created: 1664807339,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x247be058bce6d08bc3b2b12bb30238322267d72b90cc4fad0cd451c537145f13',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825848243712' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-20', coinUnit: 'UNKNOWN-20', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x247be058bce6d08bc3b2b12bb30238322267d72b90cc4fad0cd451c537145f13',
            txnLineId: 1,
            dateTimeSEC: 1664807339,
            dateTime: '2022-10-03T14:28:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825848243712',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825848243712',
            unit: '399825848243712',
            currencyId: 'COIN.399825848243712',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825848243712',
            unit: '399825848243712',
            currencyId: 'COIN.399825848243712',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
        created: 1664659955,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870188288' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870174721' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870171651' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870150401' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870191361' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870161664' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831873211904' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-30855', coinUnit: 'SUPR-30855', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'SUPR-25479', coinUnit: 'SUPR-25479', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'SUPR-25478', coinUnit: 'SUPR-25478', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'SUPR-25480', coinUnit: 'SUPR-25480', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            {
              coin: 'BLOCKS-207000241',
              coinUnit: 'BLOCKS-207000241',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
            {
              coin: 'BLOCKS-207000363',
              coinUnit: 'BLOCKS-207000363',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
            {
              coin: 'BLOCKS-207000222',
              coinUnit: 'BLOCKS-207000222',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 1,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870188288',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 2,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870174721',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 3,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870171651',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 4,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870150401',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 5,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870191361',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 6,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870161664',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x305d4962ec18d6d3ba57a98ede0e52b7db2bc9d0c0a79ec17df8176c0beb5d7c',
            txnLineId: 7,
            dateTimeSEC: 1664659955,
            dateTime: '2022-10-01T21:32:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831873211904',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870188288',
            unit: '399831870188288',
            currencyId: 'COIN.399831870188288',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870174721',
            unit: '399831870174721',
            currencyId: 'COIN.399831870174721',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870171651',
            unit: '399831870171651',
            currencyId: 'COIN.399831870171651',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870150401',
            unit: '399831870150401',
            currencyId: 'COIN.399831870150401',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870191361',
            unit: '399831870191361',
            currencyId: 'COIN.399831870191361',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870161664',
            unit: '399831870161664',
            currencyId: 'COIN.399831870161664',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831873211904',
            unit: '399831873211904',
            currencyId: 'COIN.399831873211904',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870188288',
            unit: '399831870188288',
            currencyId: 'COIN.399831870188288',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870174721',
            unit: '399831870174721',
            currencyId: 'COIN.399831870174721',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870171651',
            unit: '399831870171651',
            currencyId: 'COIN.399831870171651',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870150401',
            unit: '399831870150401',
            currencyId: 'COIN.399831870150401',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870191361',
            unit: '399831870191361',
            currencyId: 'COIN.399831870191361',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870161664',
            unit: '399831870161664',
            currencyId: 'COIN.399831870161664',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831873211904',
            unit: '399831873211904',
            currencyId: 'COIN.399831873211904',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x208d37bc6f3566b7a0477ca4757f1768b688b9b459533562ab94f13801a08257',
        created: 1664643695,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x208d37bc6f3566b7a0477ca4757f1768b688b9b459533562ab94f13801a08257',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870641408' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-86', coinUnit: 'QQL-86', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x208d37bc6f3566b7a0477ca4757f1768b688b9b459533562ab94f13801a08257',
            txnLineId: 1,
            dateTimeSEC: 1664643695,
            dateTime: '2022-10-01T17:01:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870641408',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870641408',
            unit: '399831870641408',
            currencyId: 'COIN.399831870641408',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870641408',
            unit: '399831870641408',
            currencyId: 'COIN.399831870641408',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x80fa58c4d3b95832b732c156a375c79e4a9a78f29fa9619d796d6328dd47acaf',
        created: 1664617307,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x7bA217eC4643A03f3477e9f2976dDAb7bA5b9161',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x80fa58c4d3b95832b732c156a375c79e4a9a78f29fa9619d796d6328dd47acaf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831869573120' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-72', coinUnit: 'QQL-72', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x80fa58c4d3b95832b732c156a375c79e4a9a78f29fa9619d796d6328dd47acaf',
            txnLineId: 1,
            dateTimeSEC: 1664617307,
            dateTime: '2022-10-01T09:41:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831869573120',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x7bA217eC4643A03f3477e9f2976dDAb7bA5b9161',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831869573120',
            unit: '399831869573120',
            currencyId: 'COIN.399831869573120',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831869573120',
            unit: '399831869573120',
            currencyId: 'COIN.399831869573120',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xdb00f26b3bc5c85e2257ce6b39c49b5c5940ec66e9646fb261f4f33a6dc99520',
        created: 1664557931,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xeEC815E73AF687BaBA9A7CD213add4b5e05B316e',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x845dD2a7eE2a92A0518AB2135365Ed63fdbA0C88',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xdb00f26b3bc5c85e2257ce6b39c49b5c5940ec66e9646fb261f4f33a6dc99520',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832027443200' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'QQL-24', coinUnit: 'QQL-24', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xdb00f26b3bc5c85e2257ce6b39c49b5c5940ec66e9646fb261f4f33a6dc99520',
            txnLineId: 1,
            dateTimeSEC: 1664557931,
            dateTime: '2022-09-30T17:12:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832027443200',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xeEC815E73AF687BaBA9A7CD213add4b5e05B316e',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832027443200',
            unit: '399832027443200',
            currencyId: 'COIN.399832027443200',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832027443200',
            unit: '399832027443200',
            currencyId: 'COIN.399832027443200',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
        created: 1664550263,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826690766080' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826690716672' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826690754816' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826690791424' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826690761728' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'QQL-MP-540', coinUnit: 'QQL-MP-540', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-MP-539', coinUnit: 'QQL-MP-539', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-MP-542', coinUnit: 'QQL-MP-542', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-MP-541', coinUnit: 'QQL-MP-541', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'QQL-MP-626', coinUnit: 'QQL-MP-626', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            txnLineId: 1,
            dateTimeSEC: 1664550263,
            dateTime: '2022-09-30T15:04:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826690766080',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            txnLineId: 2,
            dateTimeSEC: 1664550263,
            dateTime: '2022-09-30T15:04:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826690716672',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            txnLineId: 3,
            dateTimeSEC: 1664550263,
            dateTime: '2022-09-30T15:04:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826690754816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            txnLineId: 4,
            dateTimeSEC: 1664550263,
            dateTime: '2022-09-30T15:04:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826690791424',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x97a49eb83c6e26b98dac3de2e8389c07a8d9f56a60d237bafd551ce51f523db6',
            txnLineId: 5,
            dateTimeSEC: 1664550263,
            dateTime: '2022-09-30T15:04:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826690761728',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xA467bE8B4B9B82e0C5574b70940250e5916a9638',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690766080',
            unit: '399826690766080',
            currencyId: 'COIN.399826690766080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690716672',
            unit: '399826690716672',
            currencyId: 'COIN.399826690716672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690754816',
            unit: '399826690754816',
            currencyId: 'COIN.399826690754816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690791424',
            unit: '399826690791424',
            currencyId: 'COIN.399826690791424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690761728',
            unit: '399826690761728',
            currencyId: 'COIN.399826690761728',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690766080',
            unit: '399826690766080',
            currencyId: 'COIN.399826690766080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690716672',
            unit: '399826690716672',
            currencyId: 'COIN.399826690716672',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690754816',
            unit: '399826690754816',
            currencyId: 'COIN.399826690754816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690791424',
            unit: '399826690791424',
            currencyId: 'COIN.399826690791424',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826690761728',
            unit: '399826690761728',
            currencyId: 'COIN.399826690761728',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x187853dfca39539a3ba9450a72e144a58c840293bc2ab2352ad8ef1c9d70004b',
        created: 1664544407,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x187853dfca39539a3ba9450a72e144a58c840293bc2ab2352ad8ef1c9d70004b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x8d80ff0a'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831879467264' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831944769024' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831944745728' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'DISP-1557', coinUnit: 'DISP-1557', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'DIDA-1862', coinUnit: 'DIDA-1862', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'DISP-1862', coinUnit: 'DISP-1862', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x187853dfca39539a3ba9450a72e144a58c840293bc2ab2352ad8ef1c9d70004b',
            txnLineId: 1,
            dateTimeSEC: 1664544407,
            dateTime: '2022-09-30T13:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831879467264',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
          },
          {
            id: '0x187853dfca39539a3ba9450a72e144a58c840293bc2ab2352ad8ef1c9d70004b',
            txnLineId: 2,
            dateTimeSEC: 1664544407,
            dateTime: '2022-09-30T13:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831944769024',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
          },
          {
            id: '0x187853dfca39539a3ba9450a72e144a58c840293bc2ab2352ad8ef1c9d70004b',
            txnLineId: 3,
            dateTimeSEC: 1664544407,
            dateTime: '2022-09-30T13:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831944745728',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831879467264',
            unit: '399831879467264',
            currencyId: 'COIN.399831879467264',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831944769024',
            unit: '399831944769024',
            currencyId: 'COIN.399831944769024',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831944745728',
            unit: '399831944745728',
            currencyId: 'COIN.399831944745728',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831879467264',
            unit: '399831879467264',
            currencyId: 'COIN.399831879467264',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831944769024',
            unit: '399831944769024',
            currencyId: 'COIN.399831944769024',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831944745728',
            unit: '399831944745728',
            currencyId: 'COIN.399831944745728',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x73a9753c4b9b71f39d7dd4182d4a9c0dbf0996f75ad704b1ddf5da0f42c28b0f',
        created: 1664544215,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xDc8D7ECAf56616581EC3B86A978c538E811A89Fa',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x73a9753c4b9b71f39d7dd4182d4a9c0dbf0996f75ad704b1ddf5da0f42c28b0f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x6a761202'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831879450880' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'DIDA-1557', coinUnit: 'DIDA-1557', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x73a9753c4b9b71f39d7dd4182d4a9c0dbf0996f75ad704b1ddf5da0f42c28b0f',
            txnLineId: 1,
            dateTimeSEC: 1664544215,
            dateTime: '2022-09-30T13:23:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831879450880',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
            to: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831879450880',
            unit: '399831879450880',
            currencyId: 'COIN.399831879450880',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831879450880',
            unit: '399831879450880',
            currencyId: 'COIN.399831879450880',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe96754d37a10c3dfe5cf6ba58ae1640c7eab4b2521f5b29bd79ccf7f42d8a6f9',
        created: 1664456555,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe96754d37a10c3dfe5cf6ba58ae1640c7eab4b2521f5b29bd79ccf7f42d8a6f9',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825845748992' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-19', coinUnit: 'UNKNOWN-19', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe96754d37a10c3dfe5cf6ba58ae1640c7eab4b2521f5b29bd79ccf7f42d8a6f9',
            txnLineId: 1,
            dateTimeSEC: 1664456555,
            dateTime: '2022-09-29T13:02:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825845748992',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825845748992',
            unit: '399825845748992',
            currencyId: 'COIN.399825845748992',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825845748992',
            unit: '399825845748992',
            currencyId: 'COIN.399825845748992',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x48979afd95e8e2ffcaa62caa38a8d9aba95fb2cead6c458627d8707c862bb734',
        created: 1664374523,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x48979afd95e8e2ffcaa62caa38a8d9aba95fb2cead6c458627d8707c862bb734',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825975038208' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-18', coinUnit: 'UNKNOWN-18', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x48979afd95e8e2ffcaa62caa38a8d9aba95fb2cead6c458627d8707c862bb734',
            txnLineId: 1,
            dateTimeSEC: 1664374523,
            dateTime: '2022-09-28T14:15:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825975038208',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825975038208',
            unit: '399825975038208',
            currencyId: 'COIN.399825975038208',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825975038208',
            unit: '399825975038208',
            currencyId: 'COIN.399825975038208',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x89b54645de980f92bf87fb36d3fd256a02628220fe7275860e510f4a49804dd3',
        created: 1664290235,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x89b54645de980f92bf87fb36d3fd256a02628220fe7275860e510f4a49804dd3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825951744256' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-17', coinUnit: 'UNKNOWN-17', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x89b54645de980f92bf87fb36d3fd256a02628220fe7275860e510f4a49804dd3',
            txnLineId: 1,
            dateTimeSEC: 1664290235,
            dateTime: '2022-09-27T14:50:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825951744256',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951744256',
            unit: '399825951744256',
            currencyId: 'COIN.399825951744256',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951744256',
            unit: '399825951744256',
            currencyId: 'COIN.399825951744256',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x21d295c629bf04bf99ddbcd33f0a064bb0dd1885592f26b7b4c1e0d73a7f878f',
        created: 1664064827,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x99509E88aeAe37AaC31Ed2e1e7f9B315353180E7',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xA6c094488af4c7bd41A398658ED79523F296ba82',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x21d295c629bf04bf99ddbcd33f0a064bb0dd1885592f26b7b4c1e0d73a7f878f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831839528448' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'VM-6', coinUnit: 'VM-6', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x21d295c629bf04bf99ddbcd33f0a064bb0dd1885592f26b7b4c1e0d73a7f878f',
            txnLineId: 1,
            dateTimeSEC: 1664064827,
            dateTime: '2022-09-25T00:13:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831839528448',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x99509E88aeAe37AaC31Ed2e1e7f9B315353180E7',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839528448',
            unit: '399831839528448',
            currencyId: 'COIN.399831839528448',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839528448',
            unit: '399831839528448',
            currencyId: 'COIN.399831839528448',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x621ea592797f68772b553c088c6bea2e4bab9b7eb4857af67118ca2dad3fc278',
        created: 1663787447,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x621ea592797f68772b553c088c6bea2e4bab9b7eb4857af67118ca2dad3fc278',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831879450880' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831879467264' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'DIDA-1557', coinUnit: 'DIDA-1557', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'DISP-1557', coinUnit: 'DISP-1557', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x621ea592797f68772b553c088c6bea2e4bab9b7eb4857af67118ca2dad3fc278',
            txnLineId: 1,
            dateTimeSEC: 1663787447,
            dateTime: '2022-09-21T19:10:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831879450880',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x621ea592797f68772b553c088c6bea2e4bab9b7eb4857af67118ca2dad3fc278',
            txnLineId: 2,
            dateTimeSEC: 1663787447,
            dateTime: '2022-09-21T19:10:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831879467264',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879450880',
            unit: '399831879450880',
            currencyId: 'COIN.399831879450880',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879467264',
            unit: '399831879467264',
            currencyId: 'COIN.399831879467264',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879450880',
            unit: '399831879450880',
            currencyId: 'COIN.399831879450880',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879467264',
            unit: '399831879467264',
            currencyId: 'COIN.399831879467264',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
        created: 1663786151,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831878459138' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831878465792' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831878976512' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831878465025' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: '1972-1490', coinUnit: '1972-1490', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '1972-2256', coinUnit: '1972-2256', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '1972-2037', coinUnit: '1972-2037', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: '1972-2207', coinUnit: '1972-2207', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
            txnLineId: 1,
            dateTimeSEC: 1663786151,
            dateTime: '2022-09-21T18:49:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831878459138',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
            txnLineId: 2,
            dateTimeSEC: 1663786151,
            dateTime: '2022-09-21T18:49:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831878465792',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
            txnLineId: 3,
            dateTimeSEC: 1663786151,
            dateTime: '2022-09-21T18:49:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831878976512',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x23ad0b67421c3ebb68840f919a07cc10132db5979ed3678ebc041d9bc53f277e',
            txnLineId: 4,
            dateTimeSEC: 1663786151,
            dateTime: '2022-09-21T18:49:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831878465025',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878459138',
            unit: '399831878459138',
            currencyId: 'COIN.399831878459138',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878465792',
            unit: '399831878465792',
            currencyId: 'COIN.399831878465792',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878976512',
            unit: '399831878976512',
            currencyId: 'COIN.399831878976512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878465025',
            unit: '399831878465025',
            currencyId: 'COIN.399831878465025',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878459138',
            unit: '399831878459138',
            currencyId: 'COIN.399831878459138',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878465792',
            unit: '399831878465792',
            currencyId: 'COIN.399831878465792',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878976512',
            unit: '399831878976512',
            currencyId: 'COIN.399831878976512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878465025',
            unit: '399831878465025',
            currencyId: 'COIN.399831878465025',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x6e8b4118d9aa8db4ef140d22b35ee39ba4e3c05545ce24f3aa14625094433927',
        created: 1663705667,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '109' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '109' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6e8b4118d9aa8db4ef140d22b35ee39ba4e3c05545ce24f3aa14625094433927',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000640381037079' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-109' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1343.34',
              failedOrIncomplete: false,
              priceId: '4ef6dff1-49b6-47e0-a9ba-a07ec6932e05',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6e8b4118d9aa8db4ef140d22b35ee39ba4e3c05545ce24f3aa14625094433927',
            txnLineId: 1,
            dateTimeSEC: 1663705667,
            dateTime: '2022-09-20T20:27:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '109',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            feeAmount: '0.000640381037079',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-640381037079000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-109000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-109000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '640381037079000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
        created: 1663705607,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.007542959244198194' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832026057472' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025561600' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022930688' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022939393' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022896128' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1343.34',
              failedOrIncomplete: false,
              priceId: '4ef6dff1-49b6-47e0-a9ba-a07ec6932e05',
            },
            { coin: 'MPMX-81', coinUnit: 'MPMX-81', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-67', coinUnit: 'MPMX-67', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-746', coinUnit: 'MPMX-746', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-743', coinUnit: 'MPMX-743', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-739', coinUnit: 'MPMX-739', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            txnLineId: 1,
            dateTimeSEC: 1663705607,
            dateTime: '2022-09-20T20:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832026057472',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
          {
            id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            txnLineId: 2,
            dateTimeSEC: 1663705607,
            dateTime: '2022-09-20T20:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025561600',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
          {
            id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            txnLineId: 3,
            dateTimeSEC: 1663705607,
            dateTime: '2022-09-20T20:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022930688',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
          {
            id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            txnLineId: 4,
            dateTimeSEC: 1663705607,
            dateTime: '2022-09-20T20:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022939393',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
          {
            id: '0x08ba16bde00fa3df530a72725636be30b82ad987c714f9bf90063ca02d2a525a',
            txnLineId: 5,
            dateTimeSEC: 1663705607,
            dateTime: '2022-09-20T20:26:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022896128',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-7542959244198194' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832026057472',
            unit: '399832026057472',
            currencyId: 'COIN.399832026057472',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022896128',
            unit: '399832022896128',
            currencyId: 'COIN.399832022896128',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832026057472',
            unit: '399832026057472',
            currencyId: 'COIN.399832026057472',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022896128',
            unit: '399832022896128',
            currencyId: 'COIN.399832022896128',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '7542959244198194' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x9788280e0a5e3bb0f64b888b0f2db4292c6b4cc637752536315c9b404ff6c2bc',
        created: 1663705535,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9788280e0a5e3bb0f64b888b0f2db4292c6b4cc637752536315c9b404ff6c2bc',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000818664420021' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1343.34',
              failedOrIncomplete: false,
              priceId: '4ef6dff1-49b6-47e0-a9ba-a07ec6932e05',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9788280e0a5e3bb0f64b888b0f2db4292c6b4cc637752536315c9b404ff6c2bc',
            txnLineId: 1,
            dateTimeSEC: 1663705535,
            dateTime: '2022-09-20T20:25:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            feeAmount: '0.000818664420021',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-818664420021000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '818664420021000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xdf5d1646d932b87c6df192f8c8f165c825b937c016f378780f4b71c68746bca7',
        created: 1663705403,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xdf5d1646d932b87c6df192f8c8f165c825b937c016f378780f4b71c68746bca7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.002743105250993274' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1343.34',
              failedOrIncomplete: false,
              priceId: '4ef6dff1-49b6-47e0-a9ba-a07ec6932e05',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2743105250993274' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2743105250993274' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x924201e802ba70a6dfb150b4a773c7043dd00de6c6cb4335b3140762cadd2384',
        created: 1663704647,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x924201e802ba70a6dfb150b4a773c7043dd00de6c6cb4335b3140762cadd2384',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00716022295785423' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022656512' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1343.34',
              failedOrIncomplete: false,
              priceId: '4ef6dff1-49b6-47e0-a9ba-a07ec6932e05',
            },
            { coin: 'MPMX-741', coinUnit: 'MPMX-741', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x924201e802ba70a6dfb150b4a773c7043dd00de6c6cb4335b3140762cadd2384',
            txnLineId: 1,
            dateTimeSEC: 1663704647,
            dateTime: '2022-09-20T20:10:47.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022656512',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-7160222957854230' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '7160222957854230' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x71d832c43764354c450f232d89b1be24004731d66bb74bd806ba15bc7dae32d8',
        created: 1663688699,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6b6dD0C1aAB55052bfAac891C3fB81A1Cd7230ec',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x71d832c43764354c450f232d89b1be24004731d66bb74bd806ba15bc7dae32d8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831878959360' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'COG-188', coinUnit: 'COG-188', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x71d832c43764354c450f232d89b1be24004731d66bb74bd806ba15bc7dae32d8',
            txnLineId: 1,
            dateTimeSEC: 1663688699,
            dateTime: '2022-09-20T15:44:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831878959360',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xaBaa9736156caE7236A117de2082b1543FA0551F',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878959360',
            unit: '399831878959360',
            currencyId: 'COIN.399831878959360',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831878959360',
            unit: '399831878959360',
            currencyId: 'COIN.399831878959360',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf106cd3de7255fffe42af89053e3605c9496379d5408d391ffe845aa53bb5a53',
        created: 1663684607,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf106cd3de7255fffe42af89053e3605c9496379d5408d391ffe845aa53bb5a53',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831803302912' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-16', coinUnit: 'UNKNOWN-16', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf106cd3de7255fffe42af89053e3605c9496379d5408d391ffe845aa53bb5a53',
            txnLineId: 1,
            dateTimeSEC: 1663684607,
            dateTime: '2022-09-20T14:36:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831803302912',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803302912',
            unit: '399831803302912',
            currencyId: 'COIN.399831803302912',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803302912',
            unit: '399831803302912',
            currencyId: 'COIN.399831803302912',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
        created: 1663616363,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831944769024' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831944745728' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831946542080' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831946572288' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'DIDA-1862', coinUnit: 'DIDA-1862', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'DISP-1862', coinUnit: 'DISP-1862', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'FND-38183', coinUnit: 'FND-38183', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MWNYFW-1', coinUnit: 'MWNYFW-1', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
            txnLineId: 1,
            dateTimeSEC: 1663616363,
            dateTime: '2022-09-19T19:39:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831944769024',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
            txnLineId: 2,
            dateTimeSEC: 1663616363,
            dateTime: '2022-09-19T19:39:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831944745728',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
            txnLineId: 3,
            dateTimeSEC: 1663616363,
            dateTime: '2022-09-19T19:39:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831946542080',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x80b4920edfd912528f9db4e121c49eb650ede7bd02f8ae7b6aaba5f0d44632c3',
            txnLineId: 4,
            dateTimeSEC: 1663616363,
            dateTime: '2022-09-19T19:39:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831946572288',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831944769024',
            unit: '399831944769024',
            currencyId: 'COIN.399831944769024',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831944745728',
            unit: '399831944745728',
            currencyId: 'COIN.399831944745728',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831946542080',
            unit: '399831946542080',
            currencyId: 'COIN.399831946542080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831946572288',
            unit: '399831946572288',
            currencyId: 'COIN.399831946572288',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831944769024',
            unit: '399831944769024',
            currencyId: 'COIN.399831944769024',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831944745728',
            unit: '399831944745728',
            currencyId: 'COIN.399831944745728',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831946542080',
            unit: '399831946542080',
            currencyId: 'COIN.399831946542080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831946572288',
            unit: '399831946572288',
            currencyId: 'COIN.399831946572288',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x78a67e2bb62d0045fbed7ac6c964f3578d255eb2e6963c7d759d2826fee5aa5d',
        created: 1663528163,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x509a050f573BE0D5E01a73C3726E17161729558B',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x78a67e2bb62d0045fbed7ac6c964f3578d255eb2e6963c7d759d2826fee5aa5d',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831782062080' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'WMVG-0', coinUnit: 'WMVG-0', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x78a67e2bb62d0045fbed7ac6c964f3578d255eb2e6963c7d759d2826fee5aa5d',
            txnLineId: 1,
            dateTimeSEC: 1663528163,
            dateTime: '2022-09-18T19:09:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831782062080',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831782062080',
            unit: '399831782062080',
            currencyId: 'COIN.399831782062080',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831782062080',
            unit: '399831782062080',
            currencyId: 'COIN.399831782062080',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x32cbaed9503ee6c79ef99bdbd85fa3bfba553775cd8d6b0e447d26746d54c051',
        created: 1663431503,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x32cbaed9503ee6c79ef99bdbd85fa3bfba553775cd8d6b0e447d26746d54c051',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825960825600' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-37876', coinUnit: 'SUPR-37876', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x32cbaed9503ee6c79ef99bdbd85fa3bfba553775cd8d6b0e447d26746d54c051',
            txnLineId: 1,
            dateTimeSEC: 1663431503,
            dateTime: '2022-09-17T16:18:23.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825960825600',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825960825600',
            unit: '399825960825600',
            currencyId: 'COIN.399825960825600',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825960825600',
            unit: '399825960825600',
            currencyId: 'COIN.399825960825600',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x405f35f6a52c74b1cda8e2a239e9b34f3d837eb2f3ae672e4f579fe7ce80d43e',
        created: 1663377587,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x82C7a8f707110f5FBb16184A5933E9F78a34c6ab',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x405f35f6a52c74b1cda8e2a239e9b34f3d837eb2f3ae672e4f579fe7ce80d43e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831870176512' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'Emblem.pro-2956513823',
              coinUnit: 'Emblem.pro-2956513823',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x405f35f6a52c74b1cda8e2a239e9b34f3d837eb2f3ae672e4f579fe7ce80d43e',
            txnLineId: 1,
            dateTimeSEC: 1663377587,
            dateTime: '2022-09-17T01:19:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831870176512',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870176512',
            unit: '399831870176512',
            currencyId: 'COIN.399831870176512',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831870176512',
            unit: '399831870176512',
            currencyId: 'COIN.399831870176512',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfd444c9a08ef0fd15774f03edf6532b46f6ed2fb5664dbbc4c2a4a4f0ef44f41',
        created: 1663377491,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x82C7a8f707110f5FBb16184A5933E9F78a34c6ab',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfd444c9a08ef0fd15774f03edf6532b46f6ed2fb5664dbbc4c2a4a4f0ef44f41',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826016274944' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'Emblem.pro-4042894433',
              coinUnit: 'Emblem.pro-4042894433',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfd444c9a08ef0fd15774f03edf6532b46f6ed2fb5664dbbc4c2a4a4f0ef44f41',
            txnLineId: 1,
            dateTimeSEC: 1663377491,
            dateTime: '2022-09-17T01:18:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826016274944',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBf30B36042Deabe02daCC1E88050517F15079A54',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826016274944',
            unit: '399826016274944',
            currencyId: 'COIN.399826016274944',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826016274944',
            unit: '399826016274944',
            currencyId: 'COIN.399826016274944',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xdd2adbca32c049befdb63f4516581ee77cc44c15ce7c887f5d79d15bcb054e85',
        created: 1663367975,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xdd2adbca32c049befdb63f4516581ee77cc44c15ce7c887f5d79d15bcb054e85',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000682602109209904' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022870528' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1431.79',
              failedOrIncomplete: false,
              priceId: 'c65834c1-924f-4793-b7de-2cb1ee6d5fe6',
            },
            { coin: 'MPMX-744', coinUnit: 'MPMX-744', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xdd2adbca32c049befdb63f4516581ee77cc44c15ce7c887f5d79d15bcb054e85',
            txnLineId: 1,
            dateTimeSEC: 1663367975,
            dateTime: '2022-09-16T22:39:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022870528',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1B108378C6e3d9E233864A6eed8e02e0966E8ec8',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-682602109209904' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022870528',
            unit: '399832022870528',
            currencyId: 'COIN.399832022870528',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022870528',
            unit: '399832022870528',
            currencyId: 'COIN.399832022870528',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '682602109209904' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xb7a729def78f8bb07100a5adf8af6b3257cdcdf7e15f1b863f2a3bddce8f558f',
        created: 1663358375,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb7a729def78f8bb07100a5adf8af6b3257cdcdf7e15f1b863f2a3bddce8f558f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00049970090650156' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832022911488' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1428.19',
              failedOrIncomplete: false,
              priceId: '25ebce88-2eec-4d1f-9eb5-56ac49c80848',
            },
            { coin: 'MPMX-68', coinUnit: 'MPMX-68', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xb7a729def78f8bb07100a5adf8af6b3257cdcdf7e15f1b863f2a3bddce8f558f',
            txnLineId: 1,
            dateTimeSEC: 1663358375,
            dateTime: '2022-09-16T19:59:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832022911488',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x8Dbbca57Ea56290Efa14D835bBfd34fAF1d89753',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-499700906501560' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022911488',
            unit: '399832022911488',
            currencyId: 'COIN.399832022911488',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832022911488',
            unit: '399832022911488',
            currencyId: 'COIN.399832022911488',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '499700906501560' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
        created: 1663357391,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '8.877400295420974892' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '8.877400295420974892' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xf88a859c'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004313419210035972' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-8.877400295420974892' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025655040' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832025561600' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025611265' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022911488' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025561089' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832025607168' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832025839872' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832025684736' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1428.19',
              failedOrIncomplete: false,
              priceId: '25ebce88-2eec-4d1f-9eb5-56ac49c80848',
            },
            { coin: 'MPMX Option-67', coinUnit: 'MPMX Option-67', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-67', coinUnit: 'MPMX-67', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX Option-68', coinUnit: 'MPMX Option-68', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-68', coinUnit: 'MPMX-68', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX Option-69', coinUnit: 'MPMX Option-69', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-69', coinUnit: 'MPMX-69', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX Option-70', coinUnit: 'MPMX Option-70', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-70', coinUnit: 'MPMX-70', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 1,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025655040',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 2,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832025561600',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 3,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025611265',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 4,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022911488',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 5,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025561089',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 6,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832025607168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 7,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832025839872',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0000000000000000000000000000000000000000',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 8,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832025684736',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x3a7e411bfa28645189a260e391bde2cb02613a8131bc6c4623286900d618e880',
            txnLineId: 9,
            dateTimeSEC: 1663357391,
            dateTime: '2022-09-16T19:43:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '8.877400295420974892',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            feeAmount: '0.004313419210035972',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4313419210035972' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-8877400295420974892' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025655040',
            unit: '399832025655040',
            currencyId: 'COIN.399832025655040',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025611265',
            unit: '399832025611265',
            currencyId: 'COIN.399832025611265',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022911488',
            unit: '399832022911488',
            currencyId: 'COIN.399832022911488',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561089',
            unit: '399832025561089',
            currencyId: 'COIN.399832025561089',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025607168',
            unit: '399832025607168',
            currencyId: 'COIN.399832025607168',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025839872',
            unit: '399832025839872',
            currencyId: 'COIN.399832025839872',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025684736',
            unit: '399832025684736',
            currencyId: 'COIN.399832025684736',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-8877400295420974892' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025655040',
            unit: '399832025655040',
            currencyId: 'COIN.399832025655040',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025561600',
            unit: '399832025561600',
            currencyId: 'COIN.399832025561600',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025611265',
            unit: '399832025611265',
            currencyId: 'COIN.399832025611265',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022911488',
            unit: '399832022911488',
            currencyId: 'COIN.399832022911488',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025561089',
            unit: '399832025561089',
            currencyId: 'COIN.399832025561089',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025607168',
            unit: '399832025607168',
            currencyId: 'COIN.399832025607168',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832025839872',
            unit: '399832025839872',
            currencyId: 'COIN.399832025839872',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832025684736',
            unit: '399832025684736',
            currencyId: 'COIN.399832025684736',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4313419210035972' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x60970d82e31cd632ccf016ec3dde29885b4cde3c120aa26a748d14ab4824befd',
        created: 1663350251,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe06F5FAE754e81Bc050215fF89B03d9e9FF20700',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x60970d82e31cd632ccf016ec3dde29885b4cde3c120aa26a748d14ab4824befd',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x31bdd895'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '8.035450516986706056' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1421.13',
              failedOrIncomplete: false,
              priceId: 'd12683f6-783f-4d11-9bab-6a33342f5532',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x60970d82e31cd632ccf016ec3dde29885b4cde3c120aa26a748d14ab4824befd',
            txnLineId: 1,
            dateTimeSEC: 1663350251,
            dateTime: '2022-09-16T17:44:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '8.035450516986706056',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5cd2e8a282d5fd21731af6bfaa0c0b1e57',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '8035450516986706056' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '8035450516986706056' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
        created: 1663349435,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '27.20827178729689808' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            amount: { value: { mathjs: 'BigNumber', value: '27.20827178729689808' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x5578ad42'],
        type: 'Unknown',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.0038508283785059' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-27.20827178729689808' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1.41802067946824224' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022896128' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022846720' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022656512' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022986752' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022939393' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022870528' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022940928' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832022930688' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1421.13',
              failedOrIncomplete: false,
              priceId: 'd12683f6-783f-4d11-9bab-6a33342f5532',
            },
            { coin: 'MPMX-739', coinUnit: 'MPMX-739', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-740', coinUnit: 'MPMX-740', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-741', coinUnit: 'MPMX-741', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-742', coinUnit: 'MPMX-742', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-743', coinUnit: 'MPMX-743', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-744', coinUnit: 'MPMX-744', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-745', coinUnit: 'MPMX-745', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPMX-746', coinUnit: 'MPMX-746', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 1,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022896128',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 2,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022846720',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 3,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022656512',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 4,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022986752',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 5,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022939393',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 6,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022870528',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 7,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022940928',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 8,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832022930688',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0000000000000000000000000000000000000000',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 9,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.41802067946824224',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x51992c5cd2e8a282d5fd21731af6bfaa0c0b1e57',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
          {
            id: '0x5c9ea554defaba8c2cdd25772d674a8fff426cf74f875eac8e73d98f361372b7',
            txnLineId: 10,
            dateTimeSEC: 1663349435,
            dateTime: '2022-09-16T17:30:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '27.20827178729689808',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x51992c5CD2E8A282d5fD21731Af6BFaA0C0B1E57',
            feeAmount: '0.0038508283785059',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3850828378505900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-27208271787296898080' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1418020679468242240' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022896128',
            unit: '399832022896128',
            currencyId: 'COIN.399832022896128',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022846720',
            unit: '399832022846720',
            currencyId: 'COIN.399832022846720',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022986752',
            unit: '399832022986752',
            currencyId: 'COIN.399832022986752',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022870528',
            unit: '399832022870528',
            currencyId: 'COIN.399832022870528',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022940928',
            unit: '399832022940928',
            currencyId: 'COIN.399832022940928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-27208271787296898080' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1418020679468242240' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022896128',
            unit: '399832022896128',
            currencyId: 'COIN.399832022896128',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022846720',
            unit: '399832022846720',
            currencyId: 'COIN.399832022846720',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022656512',
            unit: '399832022656512',
            currencyId: 'COIN.399832022656512',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022986752',
            unit: '399832022986752',
            currencyId: 'COIN.399832022986752',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022939393',
            unit: '399832022939393',
            currencyId: 'COIN.399832022939393',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022870528',
            unit: '399832022870528',
            currencyId: 'COIN.399832022870528',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022940928',
            unit: '399832022940928',
            currencyId: 'COIN.399832022940928',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832022930688',
            unit: '399832022930688',
            currencyId: 'COIN.399832022930688',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3850828378505900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x6ed42bc0b0af693c304d7299417d4df28e722a15933a31c64fa53af51ebce2ca',
        created: 1663249367,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6ed42bc0b0af693c304d7299417d4df28e722a15933a31c64fa53af51ebce2ca',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831879922688' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'NOUN-443', coinUnit: 'NOUN-443', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6ed42bc0b0af693c304d7299417d4df28e722a15933a31c64fa53af51ebce2ca',
            txnLineId: 1,
            dateTimeSEC: 1663249367,
            dateTime: '2022-09-15T13:42:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831879922688',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x85f935FE6f380Ee8F9F78A1d0F2a7625Ee18A243',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879922688',
            unit: '399831879922688',
            currencyId: 'COIN.399831879922688',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879922688',
            unit: '399831879922688',
            currencyId: 'COIN.399831879922688',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x9f5f5fabf548cbbf226a0417d3162da084a8a886aebde517424bc8a0eb32ffd6',
        created: 1663219667,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x3f3ACE347c89fA6a3C0E158d17329D88aCFB3476',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xE23366CcCF6c5318b47621cEAC3296d480b5Ebc8',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9f5f5fabf548cbbf226a0417d3162da084a8a886aebde517424bc8a0eb32ffd6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x4720717b'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '3245' }, currencyId: 'COIN.385711164240128' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'merge-eth.io', coinUnit: 'merge-eth.io', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9f5f5fabf548cbbf226a0417d3162da084a8a886aebde517424bc8a0eb32ffd6.0xE23366CcCF6c5318b47621cEAC3296d480b5Ebc8',
            txnLineId: 1,
            dateTimeSEC: 1663219667,
            dateTime: '2022-09-15T05:27:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.385711164240128',
            amount: '3245',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '3245' },
            coin: '385711164240128',
            unit: '385711164240128',
            currencyId: 'COIN.385711164240128',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '3245' },
            coin: '385711164240128',
            unit: '385711164240128',
            currencyId: 'COIN.385711164240128',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
        created: 1663217609,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826276145920' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826276043008' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826276089088' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826276075264' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826276465408' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BLOCKS-189000736',
              coinUnit: 'BLOCKS-189000736',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
            {
              coin: 'BLOCKS-189000437',
              coinUnit: 'BLOCKS-189000437',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
            { coin: 'CITIZEN-3000223', coinUnit: 'CITIZEN-3000223', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-929', coinUnit: 'CITIZEN-929', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'CITIZEN-488', coinUnit: 'CITIZEN-488', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            txnLineId: 1,
            dateTimeSEC: 1663217609,
            dateTime: '2022-09-15T04:53:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826276145920',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            txnLineId: 2,
            dateTimeSEC: 1663217609,
            dateTime: '2022-09-15T04:53:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826276043008',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            txnLineId: 3,
            dateTimeSEC: 1663217609,
            dateTime: '2022-09-15T04:53:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826276089088',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            txnLineId: 4,
            dateTimeSEC: 1663217609,
            dateTime: '2022-09-15T04:53:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826276075264',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x50a41f9b18a51448a582cfb91f1769e2abdd8d188aa1025c61d52c8dbc04dab8',
            txnLineId: 5,
            dateTimeSEC: 1663217609,
            dateTime: '2022-09-15T04:53:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826276465408',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x004Ac13695329785E9005E2948C54E021C1f202d',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276145920',
            unit: '399826276145920',
            currencyId: 'COIN.399826276145920',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276043008',
            unit: '399826276043008',
            currencyId: 'COIN.399826276043008',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276089088',
            unit: '399826276089088',
            currencyId: 'COIN.399826276089088',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276075264',
            unit: '399826276075264',
            currencyId: 'COIN.399826276075264',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276465408',
            unit: '399826276465408',
            currencyId: 'COIN.399826276465408',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276145920',
            unit: '399826276145920',
            currencyId: 'COIN.399826276145920',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276043008',
            unit: '399826276043008',
            currencyId: 'COIN.399826276043008',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276089088',
            unit: '399826276089088',
            currencyId: 'COIN.399826276089088',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276075264',
            unit: '399826276075264',
            currencyId: 'COIN.399826276075264',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826276465408',
            unit: '399826276465408',
            currencyId: 'COIN.399826276465408',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x313b27131ae9cf8881505fbe0e7dbcb5af00d71d286f8b30d842abd5b98f25f1',
        created: 1663217492,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '8.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x67EEa11DCc60d6d10AaB9f30b5AC2638f7656D89',
            amount: { value: { mathjs: 'BigNumber', value: '8.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x313b27131ae9cf8881505fbe0e7dbcb5af00d71d286f8b30d842abd5b98f25f1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000274230439245' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-8.5' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1601.36',
              failedOrIncomplete: false,
              priceId: 'ff8d6715-c148-4fcc-a059-d8eff5722231',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x313b27131ae9cf8881505fbe0e7dbcb5af00d71d286f8b30d842abd5b98f25f1',
            txnLineId: 1,
            dateTimeSEC: 1663217492,
            dateTime: '2022-09-15T04:51:32.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '8.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x67EEa11DCc60d6d10AaB9f30b5AC2638f7656D89',
            feeAmount: '0.000274230439245',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-274230439245000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-8500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-8500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '274230439245000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe764fba9aaa19a868f1a559a05417937739a0b355a33c88e473293950287c596',
        created: 1663204665,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe764fba9aaa19a868f1a559a05417937739a0b355a33c88e473293950287c596',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004165927346177025' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.5' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399827073095168' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1629.87',
              failedOrIncomplete: false,
              priceId: 'ea07dc15-408a-4318-b33d-5d42ac1e2ccb',
            },
            { coin: 'BLOCKS-95000162', coinUnit: 'BLOCKS-95000162', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe764fba9aaa19a868f1a559a05417937739a0b355a33c88e473293950287c596',
            txnLineId: 1,
            dateTimeSEC: 1663204665,
            dateTime: '2022-09-15T01:17:45.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399827073095168',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a6942F9Fe75108EDe12c65a579201114D8c7D18',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xe764fba9aaa19a868f1a559a05417937739a0b355a33c88e473293950287c596',
            txnLineId: 2,
            dateTimeSEC: 1663204665,
            dateTime: '2022-09-15T01:17:45.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.004165927346177025',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4165927346177025' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399827073095168',
            unit: '399827073095168',
            currencyId: 'COIN.399827073095168',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399827073095168',
            unit: '399827073095168',
            currencyId: 'COIN.399827073095168',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4165927346177025' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x4b0d398a21e67e1e9731d0ec5406afa063bb23c93e286525c09591dae39c563c',
        created: 1663204659,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.29' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '2.29' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4b0d398a21e67e1e9731d0ec5406afa063bb23c93e286525c09591dae39c563c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004381314925407324' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.29' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826884984064' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1629.87',
              failedOrIncomplete: false,
              priceId: 'ea07dc15-408a-4318-b33d-5d42ac1e2ccb',
            },
            { coin: 'BLOCKS-95000024', coinUnit: 'BLOCKS-95000024', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4b0d398a21e67e1e9731d0ec5406afa063bb23c93e286525c09591dae39c563c',
            txnLineId: 1,
            dateTimeSEC: 1663204659,
            dateTime: '2022-09-15T01:17:39.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826884984064',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x8F903cFC0Af3C2EC0d872c57538AF5e071544a57',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x4b0d398a21e67e1e9731d0ec5406afa063bb23c93e286525c09591dae39c563c',
            txnLineId: 2,
            dateTimeSEC: 1663204659,
            dateTime: '2022-09-15T01:17:39.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.29',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.004381314925407324',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4381314925407324' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2290000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826884984064',
            unit: '399826884984064',
            currencyId: 'COIN.399826884984064',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2290000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826884984064',
            unit: '399826884984064',
            currencyId: 'COIN.399826884984064',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4381314925407324' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xfab36d033dfacbd111764694b599e927fd7202833e34b5dd7215d22e1843b45b',
        created: 1663086564,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xF183d7fb6D86f60c1D6794Ed29CE9cB3919EfEAf',
            amount: { value: { mathjs: 'BigNumber', value: '10.55' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xE468cE99444174Bd3bBBEd09209577d25D1ad673',
            amount: { value: { mathjs: 'BigNumber', value: '10.55' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfab36d033dfacbd111764694b599e927fd7202833e34b5dd7215d22e1843b45b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb7751c71'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '10.01' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1611.69',
              failedOrIncomplete: false,
              priceId: '313a54f6-f6f6-46ed-a5cd-328c895b4e82',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1610.44',
              failedOrIncomplete: false,
              priceId: '48cb6875-fdd5-4448-8a3c-c8194e80bbee',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfab36d033dfacbd111764694b599e927fd7202833e34b5dd7215d22e1843b45b',
            txnLineId: 1,
            dateTimeSEC: 1663086564,
            dateTime: '2022-09-13T16:29:24.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '10.01',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xe468ce99444174bd3bbbed09209577d25d1ad673',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '10010000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '10010000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa9042b17b68b0fe838f8f9917f3dc20bff82d3f03c5380cc7aaf20ad9de3726e',
        created: 1663004171,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6170B3C3A54C3d8c854934cBC314eD479b2B29A3',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa9042b17b68b0fe838f8f9917f3dc20bff82d3f03c5380cc7aaf20ad9de3726e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x622dcbd7'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00244748338671736' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.5' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832026728704' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1729.39',
              failedOrIncomplete: false,
              priceId: 'cdc34654-f40d-44f3-bb26-14251aa2e42f',
            },
            { coin: 'KNIT-2', coinUnit: 'KNIT-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa9042b17b68b0fe838f8f9917f3dc20bff82d3f03c5380cc7aaf20ad9de3726e',
            txnLineId: 1,
            dateTimeSEC: 1663004171,
            dateTime: '2022-09-12T17:36:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832026728704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xfFBc92E11575Fe4DA9093F53957956D991C78268',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xa9042b17b68b0fe838f8f9917f3dc20bff82d3f03c5380cc7aaf20ad9de3726e',
            txnLineId: 2,
            dateTimeSEC: 1663004171,
            dateTime: '2022-09-12T17:36:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x6170B3C3A54C3d8c854934cBC314eD479b2B29A3',
            feeAmount: '0.00244748338671736',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2447483386717360' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832026728704',
            unit: '399832026728704',
            currencyId: 'COIN.399832026728704',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832026728704',
            unit: '399832026728704',
            currencyId: 'COIN.399832026728704',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2447483386717360' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xf3206dcb178c277cd1354b10a7c7567796ab567ade1918ea84d70730b2f834e5',
        created: 1663002754,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '10.01' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xE468cE99444174Bd3bBBEd09209577d25D1ad673',
            amount: { value: { mathjs: 'BigNumber', value: '10.01' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf3206dcb178c277cd1354b10a7c7567796ab567ade1918ea84d70730b2f834e5',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb7751c71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003753066483096552' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-10.01' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1729.39',
              failedOrIncomplete: false,
              priceId: 'cdc34654-f40d-44f3-bb26-14251aa2e42f',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1717.01',
              failedOrIncomplete: false,
              priceId: '2c3f5c55-297b-4162-ac81-2f3bd0858b7a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf3206dcb178c277cd1354b10a7c7567796ab567ade1918ea84d70730b2f834e5',
            txnLineId: 1,
            dateTimeSEC: 1663002754,
            dateTime: '2022-09-12T17:12:34.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '10.01',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xE468cE99444174Bd3bBBEd09209577d25D1ad673',
            feeAmount: '0.003753066483096552',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3753066483096552' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-10010000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-10010000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3753066483096552' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x4ed97afd10f89acb0e332ccd7f820f236b8a9e3885d8a1f7112290ba261662de',
        created: 1662997631,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x42069ABFE407C60cf4ae4112bEDEaD391dBa1cdB',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4ed97afd10f89acb0e332ccd7f820f236b8a9e3885d8a1f7112290ba261662de',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Transfer',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825968271617' }],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001812606932788045' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825968271617' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1706.81',
              failedOrIncomplete: false,
              priceId: 'cc2385bf-2af1-4383-a1e7-407399531bce',
            },
            { coin: 'CDB-909', coinUnit: 'CDB-909', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4ed97afd10f89acb0e332ccd7f820f236b8a9e3885d8a1f7112290ba261662de',
            txnLineId: 1,
            dateTimeSEC: 1662997631,
            dateTime: '2022-09-12T15:47:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825968271617',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x4ed97afd10f89acb0e332ccd7f820f236b8a9e3885d8a1f7112290ba261662de',
            txnLineId: 2,
            dateTimeSEC: 1662997631,
            dateTime: '2022-09-12T15:47:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825968271617',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1812606932788045' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1812606932788045' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x15a1e32c90326f6d6865e8b7ca7bc875717c8b59d4cc4ed11f384113aa7244d2',
        created: 1662997342,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '3.39' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '3.39' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x15a1e32c90326f6d6865e8b7ca7bc875717c8b59d4cc4ed11f384113aa7244d2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003926040150295644' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-3.39' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825968271617' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1706.81',
              failedOrIncomplete: false,
              priceId: 'cc2385bf-2af1-4383-a1e7-407399531bce',
            },
            { coin: 'CDB-909', coinUnit: 'CDB-909', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x15a1e32c90326f6d6865e8b7ca7bc875717c8b59d4cc4ed11f384113aa7244d2',
            txnLineId: 1,
            dateTimeSEC: 1662997342,
            dateTime: '2022-09-12T15:42:22.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825968271617',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xCdfd4566a6AC4cf42aD8f8c51679aF9C76834692',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x15a1e32c90326f6d6865e8b7ca7bc875717c8b59d4cc4ed11f384113aa7244d2',
            txnLineId: 2,
            dateTimeSEC: 1662997342,
            dateTime: '2022-09-12T15:42:22.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '3.39',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.003926040150295644',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3926040150295644' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-3390000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-3390000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968271617',
            unit: '399825968271617',
            currencyId: 'COIN.399825968271617',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3926040150295644' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
        created: 1662751261,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0000000000c2d145a2526bD8C716263bFeBe1A72',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x32389b71'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937956352' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937582080' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937890816' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826458380800' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937654272' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937651456' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937628160' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937796096' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937676544' },
            ],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.012162542870427208' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937956352' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937582080' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937890816' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399826458380800' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937654272' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937651456' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937628160' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937796096' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399831937676544' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
            { coin: 'MPKZ-65', coinUnit: 'MPKZ-65', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPKZ-2', coinUnit: 'MPKZ-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPKZ-8', coinUnit: 'MPKZ-8', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'SUPR-37476', coinUnit: 'SUPR-37476', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPKZ-9', coinUnit: 'MPKZ-9', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'MPKZ-39', coinUnit: 'MPKZ-39', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'TINY-1', coinUnit: 'TINY-1', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'TINY-2', coinUnit: 'TINY-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'TINY-3', coinUnit: 'TINY-3', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 1,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937956352',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 2,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937582080',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 3,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937890816',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 4,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826458380800',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 5,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937654272',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 6,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937651456',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 7,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937628160',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 8,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937796096',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 9,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937676544',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 10,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937956352',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 11,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937582080',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 12,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937890816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 13,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399826458380800',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 14,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937654272',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 15,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937651456',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 16,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937628160',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 17,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937796096',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8c15b270da83b1d0957be359b4e98188110a7ac1ab6f8345b7c6756865789c18',
            txnLineId: 18,
            dateTimeSEC: 1662751261,
            dateTime: '2022-09-09T19:21:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399831937676544',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '-12162542870427208' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937654272',
            unit: '399831937654272',
            currencyId: 'COIN.399831937654272',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937654272',
            unit: '399831937654272',
            currencyId: 'COIN.399831937654272',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937654272',
            unit: '399831937654272',
            currencyId: 'COIN.399831937654272',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937654272',
            unit: '399831937654272',
            currencyId: 'COIN.399831937654272',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '12162542870427208' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe47fc65c6970bcd603c92708e554b25dc294d6d261f70f6e6d5910f54bbd0d74',
        created: 1662751209,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3a277F563b56dfEb3C040945Dbb5463340904985',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe47fc65c6970bcd603c92708e554b25dc294d6d261f70f6e6d5910f54bbd0d74',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.00138141120586617' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1381411205866170' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1381411205866170' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xc07b2f9048e4e28899814a23d0d483289090538decec91bcf70140dcc8a861e6',
        created: 1662751169,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc07b2f9048e4e28899814a23d0d483289090538decec91bcf70140dcc8a861e6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.001186635406258302' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1186635406258302' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1186635406258302' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x36659564aa327e13a4a2ead71ba51d29fe10589aedeb5452521ef06876d1df9f',
        created: 1662751126,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xA4F6105B612f913e468F6B27FCbb48c3569ACbE7',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x36659564aa327e13a4a2ead71ba51d29fe10589aedeb5452521ef06876d1df9f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xa22cb465'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.001086315646501533' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1086315646501533' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1086315646501533' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x8ae173224bbd99580060a6becb955754d551286823068166c5e66914c96281ee',
        created: 1662750281,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xFFb6D97Bd1E7B7bd08595096d15037401A1f416B',
            amount: { value: { mathjs: 'BigNumber', value: '8' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '8' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8ae173224bbd99580060a6becb955754d551286823068166c5e66914c96281ee',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8ae173224bbd99580060a6becb955754d551286823068166c5e66914c96281ee',
            txnLineId: 1,
            dateTimeSEC: 1662750281,
            dateTime: '2022-09-09T19:04:41.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '6.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe0d8d1039651a1ef017ca98e89fd4d3d3abf109e12a567b559a906de790759ac',
        created: 1662750253,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe0d8d1039651a1ef017ca98e89fd4d3d3abf109e12a567b559a906de790759ac',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001833447146039628' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-6.9' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1722.29',
              failedOrIncomplete: false,
              priceId: 'ff008cfd-6d5b-43b6-b94e-5620ec6a1a6a',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe0d8d1039651a1ef017ca98e89fd4d3d3abf109e12a567b559a906de790759ac',
            txnLineId: 1,
            dateTimeSEC: 1662750253,
            dateTime: '2022-09-09T19:04:13.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '6.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.001833447146039628',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1833447146039628' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1833447146039628' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xde615874d2f0cba274a60c80ab1f1b0392c23e62b4cbc8948b9f12f00de39169',
        created: 1662747707,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x8BcFC9B4982B281dd63073eAB45881e02a419775',
            amount: { value: { mathjs: 'BigNumber', value: '1.71' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.71' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xde615874d2f0cba274a60c80ab1f1b0392c23e62b4cbc8948b9f12f00de39169',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1710.3',
              failedOrIncomplete: false,
              priceId: '33d77e67-44b9-42f2-a905-c6eff015052d',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xde615874d2f0cba274a60c80ab1f1b0392c23e62b4cbc8948b9f12f00de39169',
            txnLineId: 1,
            dateTimeSEC: 1662747707,
            dateTime: '2022-09-09T18:21:47.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x877ad479d29045e87a2cc228b552f06206429102eb7f5c1eda887a8a46edf305',
        created: 1662746898,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x877ad479d29045e87a2cc228b552f06206429102eb7f5c1eda887a8a46edf305',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x90dbbe86'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003495663393455158' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937956352' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1710.3',
              failedOrIncomplete: false,
              priceId: '33d77e67-44b9-42f2-a905-c6eff015052d',
            },
            { coin: 'MPKZ-65', coinUnit: 'MPKZ-65', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x877ad479d29045e87a2cc228b552f06206429102eb7f5c1eda887a8a46edf305',
            txnLineId: 1,
            dateTimeSEC: 1662746898,
            dateTime: '2022-09-09T18:08:18.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937956352',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3495663393455158' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937956352',
            unit: '399831937956352',
            currencyId: 'COIN.399831937956352',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3495663393455158' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xff14ca6c378458072117d3184d253b9123f2b98f58bdd40b329fd4d5f353e670',
        created: 1662746470,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xff14ca6c378458072117d3184d253b9123f2b98f58bdd40b329fd4d5f353e670',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x90dbbe86'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003454790690450098' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937582080' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1710.3',
              failedOrIncomplete: false,
              priceId: '33d77e67-44b9-42f2-a905-c6eff015052d',
            },
            { coin: 'MPKZ-2', coinUnit: 'MPKZ-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xff14ca6c378458072117d3184d253b9123f2b98f58bdd40b329fd4d5f353e670',
            txnLineId: 1,
            dateTimeSEC: 1662746470,
            dateTime: '2022-09-09T18:01:10.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937582080',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3454790690450098' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937582080',
            unit: '399831937582080',
            currencyId: 'COIN.399831937582080',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3454790690450098' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x5307d3e02d6df583a0d754f8e3e56d5071f1ec5a68375f5125a6ec72effa457e',
        created: 1662746470,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5307d3e02d6df583a0d754f8e3e56d5071f1ec5a68375f5125a6ec72effa457e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x90dbbe86'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003454790690450098' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937890816' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1710.3',
              failedOrIncomplete: false,
              priceId: '33d77e67-44b9-42f2-a905-c6eff015052d',
            },
            { coin: 'MPKZ-8', coinUnit: 'MPKZ-8', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5307d3e02d6df583a0d754f8e3e56d5071f1ec5a68375f5125a6ec72effa457e',
            txnLineId: 1,
            dateTimeSEC: 1662746470,
            dateTime: '2022-09-09T18:01:10.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937890816',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3454790690450098' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937890816',
            unit: '399831937890816',
            currencyId: 'COIN.399831937890816',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3454790690450098' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xdbd3f7829b12ca8680d3d2567de6be5bf8e8a8dd87654da8b1d22fdcae707d14',
        created: 1662746373,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xdbd3f7829b12ca8680d3d2567de6be5bf8e8a8dd87654da8b1d22fdcae707d14',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x90dbbe86'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002655161810711475' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937651456' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
            { coin: 'MPKZ-39', coinUnit: 'MPKZ-39', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xdbd3f7829b12ca8680d3d2567de6be5bf8e8a8dd87654da8b1d22fdcae707d14',
            txnLineId: 1,
            dateTimeSEC: 1662746373,
            dateTime: '2022-09-09T17:59:33.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937651456',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2655161810711475' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937651456',
            unit: '399831937651456',
            currencyId: 'COIN.399831937651456',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2655161810711475' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x05d759425b901f8f8de11c6e38e7cf702fec555693713cf2f56b18d1015181dd',
        created: 1662746065,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '5.55' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '5.55' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x05d759425b901f8f8de11c6e38e7cf702fec555693713cf2f56b18d1015181dd',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003515787183234564' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-5.55' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x05d759425b901f8f8de11c6e38e7cf702fec555693713cf2f56b18d1015181dd',
            txnLineId: 1,
            dateTimeSEC: 1662746065,
            dateTime: '2022-09-09T17:54:25.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '5.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.003515787183234564',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3515787183234564' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-5550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-5550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3515787183234564' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x76c2b1fe1d0fcd00281a33a404e5b085f2bce54445aecfda0b1e3d967c1ef596',
        created: 1662745724,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '8.42069' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '8.42069' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x76c2b1fe1d0fcd00281a33a404e5b085f2bce54445aecfda0b1e3d967c1ef596',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001920678840878028' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-8.42069' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x76c2b1fe1d0fcd00281a33a404e5b085f2bce54445aecfda0b1e3d967c1ef596',
            txnLineId: 1,
            dateTimeSEC: 1662745724,
            dateTime: '2022-09-09T17:48:44.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '8.42069',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.001920678840878028',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1920678840878028' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-8420690000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-8420690000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1920678840878028' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x68ea83d50eaf6da18ab849211f03659e357eaecdee252853c03ab66fcb1c0b9f',
        created: 1662745638,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x789b992EA181cF4fCECf31f8FBdF6135F034a822',
            amount: { value: { mathjs: 'BigNumber', value: '4.77' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '4.77' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x68ea83d50eaf6da18ab849211f03659e357eaecdee252853c03ab66fcb1c0b9f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '4.2' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x68ea83d50eaf6da18ab849211f03659e357eaecdee252853c03ab66fcb1c0b9f',
            txnLineId: 1,
            dateTimeSEC: 1662745638,
            dateTime: '2022-09-09T17:47:18.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '4.2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '4200000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '4200000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x54cd0119eb7f4542b27e28748fcfe6a42af9cbac4567872c756bdc679aaa7183',
        created: 1662745259,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xB630AbD9a5367763B7CBa316e870c4A54064CC9F',
            amount: { value: { mathjs: 'BigNumber', value: '7.59' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '7.59' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x54cd0119eb7f4542b27e28748fcfe6a42af9cbac4567872c756bdc679aaa7183',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x54cd0119eb7f4542b27e28748fcfe6a42af9cbac4567872c756bdc679aaa7183',
            txnLineId: 1,
            dateTimeSEC: 1662745259,
            dateTime: '2022-09-09T17:40:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '6.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xce0dbb916263960f297f759dd3c051d51531b192ec8c5e2578090e1b3457ce69',
        created: 1662745064,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '4.2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '4.2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xce0dbb916263960f297f759dd3c051d51531b192ec8c5e2578090e1b3457ce69',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002396464261892232' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-4.2' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xce0dbb916263960f297f759dd3c051d51531b192ec8c5e2578090e1b3457ce69',
            txnLineId: 1,
            dateTimeSEC: 1662745064,
            dateTime: '2022-09-09T17:37:44.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '4.2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.002396464261892232',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2396464261892232' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-4200000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-4200000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2396464261892232' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x78eb5c5cbb174a48e6ceea934c1e72c60f84f00f87e149e733a610cf7ee2253f',
        created: 1662744815,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xda3c325aB45b30AeB476B026FE6A777443cA04f3',
            amount: { value: { mathjs: 'BigNumber', value: '3.65' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '3.65' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x78eb5c5cbb174a48e6ceea934c1e72c60f84f00f87e149e733a610cf7ee2253f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '3.3' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x78eb5c5cbb174a48e6ceea934c1e72c60f84f00f87e149e733a610cf7ee2253f',
            txnLineId: 1,
            dateTimeSEC: 1662744815,
            dateTime: '2022-09-09T17:33:35.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '3.3',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x17647585f167fe5f0bd789d63d5a565e2d0f653c477fa258d363611e50b4ab18',
        created: 1662744714,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '6.9' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x17647585f167fe5f0bd789d63d5a565e2d0f653c477fa258d363611e50b4ab18',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002779449019925676' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-6.9' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x17647585f167fe5f0bd789d63d5a565e2d0f653c477fa258d363611e50b4ab18',
            txnLineId: 1,
            dateTimeSEC: 1662744714,
            dateTime: '2022-09-09T17:31:54.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '6.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.002779449019925676',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2779449019925676' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-6900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2779449019925676' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x0c7c08651e7cbf0589cdeda09bfe6db9621eb1629db90af2371f17ecceff64e1',
        created: 1662744585,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xB630AbD9a5367763B7CBa316e870c4A54064CC9F',
            amount: { value: { mathjs: 'BigNumber', value: '6.259' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '6.259' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x0c7c08651e7cbf0589cdeda09bfe6db9621eb1629db90af2371f17ecceff64e1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '5.69' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x0c7c08651e7cbf0589cdeda09bfe6db9621eb1629db90af2371f17ecceff64e1',
            txnLineId: 1,
            dateTimeSEC: 1662744585,
            dateTime: '2022-09-09T17:29:45.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '5.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '5690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '5690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x8d68764bc62f8eae5b62768feb0b2e3e4fe713a5f59f63d1e4d354ac30d109b0',
        created: 1662744497,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '3.3' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '3.3' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8d68764bc62f8eae5b62768feb0b2e3e4fe713a5f59f63d1e4d354ac30d109b0',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00278300414480466' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-3.3' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8d68764bc62f8eae5b62768feb0b2e3e4fe713a5f59f63d1e4d354ac30d109b0',
            txnLineId: 1,
            dateTimeSEC: 1662744497,
            dateTime: '2022-09-09T17:28:17.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '3.3',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00278300414480466',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2783004144804660' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2783004144804660' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x1068cbdf475ace1b690ef79eb800bce2e8aa49b463230f31be50b117a8b361da',
        created: 1662744410,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xda3c325aB45b30AeB476B026FE6A777443cA04f3',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1068cbdf475ace1b690ef79eb800bce2e8aa49b463230f31be50b117a8b361da',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1068cbdf475ace1b690ef79eb800bce2e8aa49b463230f31be50b117a8b361da',
            txnLineId: 1,
            dateTimeSEC: 1662744410,
            dateTime: '2022-09-09T17:26:50.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xba49914595c278a1de285be3c98985e7c72a52a4ce4c7863be57aea223a0a3e8',
        created: 1662744330,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xba49914595c278a1de285be3c98985e7c72a52a4ce4c7863be57aea223a0a3e8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.005896488785754756' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.5' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xba49914595c278a1de285be3c98985e7c72a52a4ce4c7863be57aea223a0a3e8',
            txnLineId: 1,
            dateTimeSEC: 1662744330,
            dateTime: '2022-09-09T17:25:30.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.005896488785754756',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5896488785754756' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5896488785754756' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x4501af84ea5be0856cff92481fa6ef8d2330a989a172eb8e11019bd88f8e3d0c',
        created: 1662744077,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x8BcFC9B4982B281dd63073eAB45881e02a419775',
            amount: { value: { mathjs: 'BigNumber', value: '2.2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4501af84ea5be0856cff92481fa6ef8d2330a989a172eb8e11019bd88f8e3d0c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4501af84ea5be0856cff92481fa6ef8d2330a989a172eb8e11019bd88f8e3d0c',
            txnLineId: 1,
            dateTimeSEC: 1662744077,
            dateTime: '2022-09-09T17:21:17.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x054afd8173cf2691f1c48668eed0d9e8c381c744f6ffe4367ef5630911e4487e',
        created: 1662744069,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '5.69' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '5.69' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x054afd8173cf2691f1c48668eed0d9e8c381c744f6ffe4367ef5630911e4487e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004469751913073748' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-5.69' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x054afd8173cf2691f1c48668eed0d9e8c381c744f6ffe4367ef5630911e4487e',
            txnLineId: 1,
            dateTimeSEC: 1662744069,
            dateTime: '2022-09-09T17:21:09.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '5.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.004469751913073748',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4469751913073748' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-5690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-5690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4469751913073748' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x76fc62463816b1bc7cfa045e7bc38f227bda4f0533951c9bff53477617ccfbfb',
        created: 1662743717,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '4.69' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '4.69' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x76fc62463816b1bc7cfa045e7bc38f227bda4f0533951c9bff53477617ccfbfb',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.002209600431892998' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2209600431892998' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2209600431892998' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x5f0a7e3bbe666dd9c5d1c7ff2d408f7188bd95e6797c02e2a5c843cd79e34b58',
        created: 1662743615,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5f0a7e3bbe666dd9c5d1c7ff2d408f7188bd95e6797c02e2a5c843cd79e34b58',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002706024698113548' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5f0a7e3bbe666dd9c5d1c7ff2d408f7188bd95e6797c02e2a5c843cd79e34b58',
            txnLineId: 1,
            dateTimeSEC: 1662743615,
            dateTime: '2022-09-09T17:13:35.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.002706024698113548',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2706024698113548' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2706024698113548' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x86d9adbf7be393295b856d4c2eccb24c85c1035624414062ed03773f03db8c3c',
        created: 1662743479,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '3.3' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '3.3' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x86d9adbf7be393295b856d4c2eccb24c85c1035624414062ed03773f03db8c3c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003980256677990208' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-3.3' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x86d9adbf7be393295b856d4c2eccb24c85c1035624414062ed03773f03db8c3c',
            txnLineId: 1,
            dateTimeSEC: 1662743479,
            dateTime: '2022-09-09T17:11:19.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '3.3',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.003980256677990208',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3980256677990208' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-3300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3980256677990208' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x539e82e90cfafa67710bfa0c0884a883870218538e72f3f93b01b648d71e0698',
        created: 1662743479,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xB630AbD9a5367763B7CBa316e870c4A54064CC9F',
            amount: { value: { mathjs: 'BigNumber', value: '4.059' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '4.059' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x539e82e90cfafa67710bfa0c0884a883870218538e72f3f93b01b648d71e0698',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '3.69' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x539e82e90cfafa67710bfa0c0884a883870218538e72f3f93b01b648d71e0698',
            txnLineId: 1,
            dateTimeSEC: 1662743479,
            dateTime: '2022-09-09T17:11:19.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '3.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '3690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '3690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa1182964a42df2d54114825ee37792355938e2cd300bb2bc53d0efae36af341f',
        created: 1662743401,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.55' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.55' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa1182964a42df2d54114825ee37792355938e2cd300bb2bc53d0efae36af341f',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00329470350235446' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.55' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa1182964a42df2d54114825ee37792355938e2cd300bb2bc53d0efae36af341f',
            txnLineId: 1,
            dateTimeSEC: 1662743401,
            dateTime: '2022-09-09T17:10:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00329470350235446',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3294703502354460' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3294703502354460' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x495b74c2b4f703ec014684df875b1228266cee2aa978363b62afa2c0a8f51799',
        created: 1662743401,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '3.69' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '3.69' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x495b74c2b4f703ec014684df875b1228266cee2aa978363b62afa2c0a8f51799',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00317172091548046' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-3.69' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x495b74c2b4f703ec014684df875b1228266cee2aa978363b62afa2c0a8f51799',
            txnLineId: 1,
            dateTimeSEC: 1662743401,
            dateTime: '2022-09-09T17:10:01.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '3.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00317172091548046',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3171720915480460' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-3690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-3690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3171720915480460' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x1f8228177fae3acf218f4e06c84d8980daffabb4c5b1c9ebbe4da9b5fdea7ec2',
        created: 1662743401,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x789b992EA181cF4fCECf31f8FBdF6135F034a822',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1f8228177fae3acf218f4e06c84d8980daffabb4c5b1c9ebbe4da9b5fdea7ec2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1f8228177fae3acf218f4e06c84d8980daffabb4c5b1c9ebbe4da9b5fdea7ec2',
            txnLineId: 1,
            dateTimeSEC: 1662743401,
            dateTime: '2022-09-09T17:10:01.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xcaec2f59d01ee46e417e3a044dfa2c09693a80bcea7fbe54920d89021fc74574',
        created: 1662743340,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x8BcFC9B4982B281dd63073eAB45881e02a419775',
            amount: { value: { mathjs: 'BigNumber', value: '1.71' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.71' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xcaec2f59d01ee46e417e3a044dfa2c09693a80bcea7fbe54920d89021fc74574',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xcaec2f59d01ee46e417e3a044dfa2c09693a80bcea7fbe54920d89021fc74574',
            txnLineId: 1,
            dateTimeSEC: 1662743340,
            dateTime: '2022-09-09T17:09:00.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xee68beb27dc7e396b9cb92abfbe4829713da32296f7fe814b3ee24343cdddb1a',
        created: 1662743334,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.3' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.3' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xee68beb27dc7e396b9cb92abfbe4829713da32296f7fe814b3ee24343cdddb1a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.003130831904443236' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.3' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xee68beb27dc7e396b9cb92abfbe4829713da32296f7fe814b3ee24343cdddb1a',
            txnLineId: 1,
            dateTimeSEC: 1662743334,
            dateTime: '2022-09-09T17:08:54.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.3',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.003130831904443236',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-3130831904443236' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2300000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '3130831904443236' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xfa92a0b0faed62b1006d9dff8a75aa350661119736093d389699c98b02000a33',
        created: 1662743290,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x04Df8D02f912d34FEf12a1B0488ee56FD6f7416c',
            amount: { value: { mathjs: 'BigNumber', value: '2.31' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.31' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfa92a0b0faed62b1006d9dff8a75aa350661119736093d389699c98b02000a33',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2.1' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfa92a0b0faed62b1006d9dff8a75aa350661119736093d389699c98b02000a33',
            txnLineId: 1,
            dateTimeSEC: 1662743290,
            dateTime: '2022-09-09T17:08:10.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '2.1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2100000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2100000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x5d30caee1417c7a78450634c4e8c7842dd07d7535b0ec25f845ae9b47f08afca',
        created: 1662743242,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xCa11d10CEb098f597a0CAb28117fC3465991a63c',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.75' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5d30caee1417c7a78450634c4e8c7842dd07d7535b0ec25f845ae9b47f08afca',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5d30caee1417c7a78450634c4e8c7842dd07d7535b0ec25f845ae9b47f08afca',
            txnLineId: 1,
            dateTimeSEC: 1662743242,
            dateTime: '2022-09-09T17:07:22.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x75d2d639fbb9939657e9e4126ca3de4c8c948ee8ce2fc905ee6a2c2edb3d5ba4',
        created: 1662743166,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x3c9240441cB5E062Df79CDF73d970314445b4c03',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x75d2d639fbb9939657e9e4126ca3de4c8c948ee8ce2fc905ee6a2c2edb3d5ba4',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1.69' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x75d2d639fbb9939657e9e4126ca3de4c8c948ee8ce2fc905ee6a2c2edb3d5ba4',
            txnLineId: 1,
            dateTimeSEC: 1662743166,
            dateTime: '2022-09-09T17:06:06.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe409cc9432e29f01f6f3b2f1d97b546061bbe9d5f8b9e4d8d0e9f47dfe70c18a',
        created: 1662743121,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe409cc9432e29f01f6f3b2f1d97b546061bbe9d5f8b9e4d8d0e9f47dfe70c18a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.0043877973871473' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.5' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe409cc9432e29f01f6f3b2f1d97b546061bbe9d5f8b9e4d8d0e9f47dfe70c18a',
            txnLineId: 1,
            dateTimeSEC: 1662743121,
            dateTime: '2022-09-09T17:05:21.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.0043877973871473',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4387797387147300' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4387797387147300' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x5dc96ca9f31a6d1e90412809e6ff578ea722b0191fa74023debee7b8d6434ee7',
        created: 1662743080,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5dc96ca9f31a6d1e90412809e6ff578ea722b0191fa74023debee7b8d6434ee7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.0052708552888089' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.55' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5dc96ca9f31a6d1e90412809e6ff578ea722b0191fa74023debee7b8d6434ee7',
            txnLineId: 1,
            dateTimeSEC: 1662743080,
            dateTime: '2022-09-09T17:04:40.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.0052708552888089',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5270855288808900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5270855288808900' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x4f1ed86c8ed87735dd0385909b41b2592e9ac3d23f9a9e58b0b3fe3ac9b89448',
        created: 1662743080,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.55' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4f1ed86c8ed87735dd0385909b41b2592e9ac3d23f9a9e58b0b3fe3ac9b89448',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00503883422333075' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.55' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4f1ed86c8ed87735dd0385909b41b2592e9ac3d23f9a9e58b0b3fe3ac9b89448',
            txnLineId: 1,
            dateTimeSEC: 1662743080,
            dateTime: '2022-09-09T17:04:40.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.55',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00503883422333075',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5038834223330750' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1550000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5038834223330750' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x6d092826974f18b0cc557c795476caf2697a3531a68ced98da03d40b0314853a',
        created: 1662743008,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xB630AbD9a5367763B7CBa316e870c4A54064CC9F',
            amount: { value: { mathjs: 'BigNumber', value: '2.09' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.09' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x6d092826974f18b0cc557c795476caf2697a3531a68ced98da03d40b0314853a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1.9' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x6d092826974f18b0cc557c795476caf2697a3531a68ced98da03d40b0314853a',
            txnLineId: 1,
            dateTimeSEC: 1662743008,
            dateTime: '2022-09-09T17:03:28.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x3a3548e060be10c2614d0a4cb0c03cc9093fd799',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xf0814d5281a0fb24234087ac4bbdfeb20316f290f6b31996577dd1156affbfaf',
        created: 1662742960,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.9' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.9' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xf0814d5281a0fb24234087ac4bbdfeb20316f290f6b31996577dd1156affbfaf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.005444922523911108' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.9' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xf0814d5281a0fb24234087ac4bbdfeb20316f290f6b31996577dd1156affbfaf',
            txnLineId: 1,
            dateTimeSEC: 1662742960,
            dateTime: '2022-09-09T17:02:40.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.9',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.005444922523911108',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5444922523911108' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1900000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5444922523911108' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xe07372ce1a137a5b5fe2853fec96b19cd06c448898274ee5921ac2ff7dde9acf',
        created: 1662742906,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.69' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '1.69' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe07372ce1a137a5b5fe2853fec96b19cd06c448898274ee5921ac2ff7dde9acf',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00621816580411908' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.69' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe07372ce1a137a5b5fe2853fec96b19cd06c448898274ee5921ac2ff7dde9acf',
            txnLineId: 1,
            dateTimeSEC: 1662742906,
            dateTime: '2022-09-09T17:01:46.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00621816580411908',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-6218165804119080' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '6218165804119080' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xeac070680762f60c17357c05f6c9dfe4b6723f83fee8269bcbc7013b57506ba3',
        created: 1662742869,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.1' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.1' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xeac070680762f60c17357c05f6c9dfe4b6723f83fee8269bcbc7013b57506ba3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00535788212673547' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.1' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xeac070680762f60c17357c05f6c9dfe4b6723f83fee8269bcbc7013b57506ba3',
            txnLineId: 1,
            dateTimeSEC: 1662742869,
            dateTime: '2022-09-09T17:01:09.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.00535788212673547',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-5357882126735470' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2100000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2100000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '5357882126735470' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x469bcc4440f54b29ab328629625d537a79574302503bec595202302b8c7521b2',
        created: 1662742802,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            amount: { value: { mathjs: 'BigNumber', value: '2.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x469bcc4440f54b29ab328629625d537a79574302503bec595202302b8c7521b2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x505dc8d6'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004502169871167276' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-2.5' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1704.77',
              failedOrIncomplete: false,
              priceId: '454b1f27-64cf-4f22-8bf1-f51d4951196f',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x469bcc4440f54b29ab328629625d537a79574302503bec595202302b8c7521b2',
            txnLineId: 1,
            dateTimeSEC: 1662742802,
            dateTime: '2022-09-09T17:00:02.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '2.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x3A3548e060Be10c2614d0a4Cb0c03CC9093fD799',
            feeAmount: '0.004502169871167276',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4502169871167276' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-2500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4502169871167276' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xbe5f2339236cf74ca65366f005b82001de42a990f97e6ed8d0f85e82acecd72a',
        created: 1662693497,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '100' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '100' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbe5f2339236cf74ca65366f005b82001de42a990f97e6ed8d0f85e82acecd72a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '100' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1672.47',
              failedOrIncomplete: false,
              priceId: 'caa74944-2c1b-4626-8693-06ea18818e01',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xbe5f2339236cf74ca65366f005b82001de42a990f97e6ed8d0f85e82acecd72a',
            txnLineId: 1,
            dateTimeSEC: 1662693497,
            dateTime: '2022-09-09T03:18:17.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '100',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '100000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '100000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xdcf39cd503c3a41c8e713e95b11d711611e0dec05a970985a1f7e28a7847813a',
        created: 1662693382,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xdcf39cd503c3a41c8e713e95b11d711611e0dec05a970985a1f7e28a7847813a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1672.47',
              failedOrIncomplete: false,
              priceId: 'caa74944-2c1b-4626-8693-06ea18818e01',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xdcf39cd503c3a41c8e713e95b11d711611e0dec05a970985a1f7e28a7847813a',
            txnLineId: 1,
            dateTimeSEC: 1662693382,
            dateTime: '2022-09-09T03:16:22.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.01',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '10000000000000000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [
          { value: { mathjs: 'BigNumber', value: '10000000000000000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        paidFees: [],
      },
      {
        id: '0x86291399d5f702fc9509af5536226d8c694cc0d61ec9a009433a8db591b6596c',
        created: 1662561589,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x86291399d5f702fc9509af5536226d8c694cc0d61ec9a009433a8db591b6596c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825870039041' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-15', coinUnit: 'UNKNOWN-15', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x86291399d5f702fc9509af5536226d8c694cc0d61ec9a009433a8db591b6596c',
            txnLineId: 1,
            dateTimeSEC: 1662561589,
            dateTime: '2022-09-07T14:39:49.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825870039041',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825870039041',
            unit: '399825870039041',
            currencyId: 'COIN.399825870039041',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825870039041',
            unit: '399825870039041',
            currencyId: 'COIN.399825870039041',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfd8de6d78e41338bd2e847331a065d6e1dfef3edeaa1515230c531869e5fb622',
        created: 1662479398,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfd8de6d78e41338bd2e847331a065d6e1dfef3edeaa1515230c531869e5fb622',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x2e1a7d4d'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000623849984633788' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '8' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-8' }, currencyId: 'COIN.2882' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1669.77',
              failedOrIncomplete: false,
              priceId: '274077c2-7ad8-46c1-9125-c56b2fd4e363',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1661.6',
              failedOrIncomplete: false,
              priceId: '5d78980f-e86e-4c83-9947-9a8348c0c87b',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfd8de6d78e41338bd2e847331a065d6e1dfef3edeaa1515230c531869e5fb622',
            txnLineId: 1,
            dateTimeSEC: 1662479398,
            dateTime: '2022-09-06T15:49:58.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '8',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
          {
            id: '0xfd8de6d78e41338bd2e847331a065d6e1dfef3edeaa1515230c531869e5fb622.0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            txnLineId: 2,
            dateTimeSEC: 1662479398,
            dateTime: '2022-09-06T15:49:58.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.2882',
            amount: '8',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
            to: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-623849984633788' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '8000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '-8' }, coin: '2882', unit: '2882', currencyId: 'COIN.2882' },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '8000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '-8' }, coin: '2882', unit: '2882', currencyId: 'COIN.2882' },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '623849984633788' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x604ab88c873b985faf909ea45fa787866dbd9730cd7e0bdbe303c4783d3b86ab',
        created: 1662476766,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xAca333DB2c8F8ef2142cF01c112Cf2444b01004e',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x495f947276749Ce646f68AC8c248420045cb7b5e',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x604ab88c873b985faf909ea45fa787866dbd9730cd7e0bdbe303c4783d3b86ab',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xf242432a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825876172800' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'OPENSTORE-7808616392',
              coinUnit: 'OPENSTORE-7808616392',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x604ab88c873b985faf909ea45fa787866dbd9730cd7e0bdbe303c4783d3b86ab',
            txnLineId: 1,
            dateTimeSEC: 1662476766,
            dateTime: '2022-09-06T15:06:06.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825876172800',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xAca333DB2c8F8ef2142cF01c112Cf2444b01004e',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876172800',
            unit: '399825876172800',
            currencyId: 'COIN.399825876172800',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876172800',
            unit: '399825876172800',
            currencyId: 'COIN.399825876172800',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x11ced79005ea7d97eb3e96175481cb2057b89b815ef1981ba2f48e76ca194be2',
        created: 1662244411,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xbe062622487A9c3F2fB3A0603778BB92D78BdBb4',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0131C9A2FE609F03F6F7A0cF850A417A648C695e',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x11ced79005ea7d97eb3e96175481cb2057b89b815ef1981ba2f48e76ca194be2',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x75992d3c'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1800' }, currencyId: 'COIN.385711601624832' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'mbird.fun', coinUnit: 'mbird.fun', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x11ced79005ea7d97eb3e96175481cb2057b89b815ef1981ba2f48e76ca194be2.0x0131C9A2FE609F03F6F7A0cF850A417A648C695e',
            txnLineId: 1,
            dateTimeSEC: 1662244411,
            dateTime: '2022-09-03T22:33:31.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.385711601624832',
            amount: '1800',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
            to: '0x1b7844cfae4c823ac6389855d47106a70c84f067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1800' },
            coin: '385711601624832',
            unit: '385711601624832',
            currencyId: 'COIN.385711601624832',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1800' },
            coin: '385711601624832',
            unit: '385711601624832',
            currencyId: 'COIN.385711601624832',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xc1d314afdb9eff13f341cc3a1f29e89fef29f6ee4ea605c2ce81eed3e71956ad',
        created: 1662224497,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xC5A2f28BAcF1425BFAEA14834486F0e9d0832155',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xc1d314afdb9eff13f341cc3a1f29e89fef29f6ee4ea605c2ce81eed3e71956ad',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x534665e9'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399826458380800' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-37476', coinUnit: 'SUPR-37476', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xc1d314afdb9eff13f341cc3a1f29e89fef29f6ee4ea605c2ce81eed3e71956ad',
            txnLineId: 1,
            dateTimeSEC: 1662224497,
            dateTime: '2022-09-03T17:01:37.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399826458380800',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xC5A2f28BAcF1425BFAEA14834486F0e9d0832155',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399826458380800',
            unit: '399826458380800',
            currencyId: 'COIN.399826458380800',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x74890565b308d99afd64f07a37c8f6acdc96f65d0c3526ef30f2332a7bb30550',
        created: 1662219366,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '4.326' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '4.326' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x74890565b308d99afd64f07a37c8f6acdc96f65d0c3526ef30f2332a7bb30550',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0f2b2532'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001054374841359744' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-4.326' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x74890565b308d99afd64f07a37c8f6acdc96f65d0c3526ef30f2332a7bb30550',
            txnLineId: 1,
            dateTimeSEC: 1662219366,
            dateTime: '2022-09-03T15:36:06.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '4.326',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            feeAmount: '0.001054374841359744',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1054374841359744' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-4326000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-4326000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1054374841359744' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xa1fc058bd2132d5adf685c0a090b57bba19511c4b42b6593fbd80740ea8aba2b',
        created: 1662219153,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa1fc058bd2132d5adf685c0a090b57bba19511c4b42b6593fbd80740ea8aba2b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb3ffb760'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002318728863039002' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-4120' }, currencyId: 'COIN.376550994001152' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937628160' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
            {
              coin: 'RARE',
              coinUnit: 'RARE',
              fiat: 'USD',
              rate: '0.2042',
              failedOrIncomplete: false,
              priceId: 'b43b8786-85fd-42dd-b301-9befca317dbb',
            },
            { coin: 'TINY-1', coinUnit: 'TINY-1', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa1fc058bd2132d5adf685c0a090b57bba19511c4b42b6593fbd80740ea8aba2b',
            txnLineId: 1,
            dateTimeSEC: 1662219153,
            dateTime: '2022-09-03T15:32:33.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937628160',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xC5A2f28BAcF1425BFAEA14834486F0e9d0832155',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xa1fc058bd2132d5adf685c0a090b57bba19511c4b42b6593fbd80740ea8aba2b.0xba5BDe662c17e2aDFF1075610382B9B691296350',
            txnLineId: 2,
            dateTimeSEC: 1662219153,
            dateTime: '2022-09-03T15:32:33.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.376550994001152',
            amount: '4120',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
            to: '0x6d7c44773c52d396f43c2d511b81aa168e9a7a42',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2318728863039002' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937628160',
            unit: '399831937628160',
            currencyId: 'COIN.399831937628160',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2318728863039002' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xa9f55b9c382a51dfa9a4fa3c7e415cb5f449788ab6362bf9d63bff4eae92880c',
        created: 1662219079,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa9f55b9c382a51dfa9a4fa3c7e415cb5f449788ab6362bf9d63bff4eae92880c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb3ffb760'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002042330855012389' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-4120' }, currencyId: 'COIN.376550994001152' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937796096' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
            {
              coin: 'RARE',
              coinUnit: 'RARE',
              fiat: 'USD',
              rate: '0.2042',
              failedOrIncomplete: false,
              priceId: 'b43b8786-85fd-42dd-b301-9befca317dbb',
            },
            { coin: 'TINY-2', coinUnit: 'TINY-2', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa9f55b9c382a51dfa9a4fa3c7e415cb5f449788ab6362bf9d63bff4eae92880c',
            txnLineId: 1,
            dateTimeSEC: 1662219079,
            dateTime: '2022-09-03T15:31:19.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937796096',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xC5A2f28BAcF1425BFAEA14834486F0e9d0832155',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xa9f55b9c382a51dfa9a4fa3c7e415cb5f449788ab6362bf9d63bff4eae92880c.0xba5BDe662c17e2aDFF1075610382B9B691296350',
            txnLineId: 2,
            dateTimeSEC: 1662219079,
            dateTime: '2022-09-03T15:31:19.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.376550994001152',
            amount: '4120',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
            to: '0x6d7c44773c52d396f43c2d511b81aa168e9a7a42',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2042330855012389' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937796096',
            unit: '399831937796096',
            currencyId: 'COIN.399831937796096',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2042330855012389' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x1acab58cd26a6b48ce606614a308acc8f1f30bd7b1cfb1283c6f3d1fc263bf16',
        created: 1662219039,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.007866350154375462' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
            amount: { value: { mathjs: 'BigNumber', value: '0.007866350154375462' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1acab58cd26a6b48ce606614a308acc8f1f30bd7b1cfb1283c6f3d1fc263bf16',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x5ae401dc'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000915680092722915' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.007866350154375462' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '0.000039136070419778' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '60.00000000000000678' }, currencyId: 'COIN.376550994001152' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1549.93',
              failedOrIncomplete: false,
              priceId: 'd0d33ce5-ea2e-4527-99fa-8a020043ebe2',
            },
            {
              coin: 'RARE',
              coinUnit: 'RARE',
              fiat: 'USD',
              rate: '0.2042',
              failedOrIncomplete: false,
              priceId: 'b43b8786-85fd-42dd-b301-9befca317dbb',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1acab58cd26a6b48ce606614a308acc8f1f30bd7b1cfb1283c6f3d1fc263bf16',
            txnLineId: 1,
            dateTimeSEC: 1662219039,
            dateTime: '2022-09-03T15:30:39.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.000039136070419778',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
          {
            id: '0x1acab58cd26a6b48ce606614a308acc8f1f30bd7b1cfb1283c6f3d1fc263bf16',
            txnLineId: 2,
            dateTimeSEC: 1662219039,
            dateTime: '2022-09-03T15:30:39.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.007866350154375462',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
            feeAmount: '0.000915680092722915',
          },
          {
            id: '0x1acab58cd26a6b48ce606614a308acc8f1f30bd7b1cfb1283c6f3d1fc263bf16.0xba5BDe662c17e2aDFF1075610382B9B691296350',
            txnLineId: 3,
            dateTimeSEC: 1662219039,
            dateTime: '2022-09-03T15:30:39.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.376550994001152',
            amount: '60.00000000000000678',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x496ab075f43991dd794baf982dc3d9817a49b33a',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-915680092722915' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          { value: { mathjs: 'BigNumber', value: '-7866350154375462' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          { value: { mathjs: 'BigNumber', value: '39136070419778' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '60.00000000000000678' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
        ],
        fullAmountSetWithoutFees: [
          { value: { mathjs: 'BigNumber', value: '-7866350154375462' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          { value: { mathjs: 'BigNumber', value: '39136070419778' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '60.00000000000000678' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '915680092722915' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xae934d420e92aca765e64da9ba6a210921519bc88bce7ee859558806caa9dbf6',
        created: 1662219015,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xae934d420e92aca765e64da9ba6a210921519bc88bce7ee859558806caa9dbf6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb3ffb760'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002555769344794675' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-4120' }, currencyId: 'COIN.376550994001152' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831937676544' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
            {
              coin: 'RARE',
              coinUnit: 'RARE',
              fiat: 'USD',
              rate: '0.2042',
              failedOrIncomplete: false,
              priceId: 'b43b8786-85fd-42dd-b301-9befca317dbb',
            },
            { coin: 'TINY-3', coinUnit: 'TINY-3', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xae934d420e92aca765e64da9ba6a210921519bc88bce7ee859558806caa9dbf6',
            txnLineId: 1,
            dateTimeSEC: 1662219015,
            dateTime: '2022-09-03T15:30:15.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831937676544',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xC5A2f28BAcF1425BFAEA14834486F0e9d0832155',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0xae934d420e92aca765e64da9ba6a210921519bc88bce7ee859558806caa9dbf6.0xba5BDe662c17e2aDFF1075610382B9B691296350',
            txnLineId: 2,
            dateTimeSEC: 1662219015,
            dateTime: '2022-09-03T15:30:15.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.376550994001152',
            amount: '4120',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
            to: '0x6d7c44773c52d396f43c2d511b81aa168e9a7a42',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2555769344794675' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-4120' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831937676544',
            unit: '399831937676544',
            currencyId: 'COIN.399831937676544',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2555769344794675' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x3fe3cee6012e5a4709a74ec7843c9de68fdb0adf9c2664f64685e8b7b884d36c',
        created: 1662218973,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.667503020245087837' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
            amount: { value: { mathjs: 'BigNumber', value: '1.667503020245087837' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x3fe3cee6012e5a4709a74ec7843c9de68fdb0adf9c2664f64685e8b7b884d36c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x5ae401dc'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00113666086297157' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.667503020245087837' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '0.008296034926592476' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '12300' }, currencyId: 'COIN.376550994001152' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
            {
              coin: 'RARE',
              coinUnit: 'RARE',
              fiat: 'USD',
              rate: '0.2042',
              failedOrIncomplete: false,
              priceId: 'b43b8786-85fd-42dd-b301-9befca317dbb',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1549.93',
              failedOrIncomplete: false,
              priceId: 'd0d33ce5-ea2e-4527-99fa-8a020043ebe2',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x3fe3cee6012e5a4709a74ec7843c9de68fdb0adf9c2664f64685e8b7b884d36c',
            txnLineId: 1,
            dateTimeSEC: 1662218973,
            dateTime: '2022-09-03T15:29:33.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.008296034926592476',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
          {
            id: '0x3fe3cee6012e5a4709a74ec7843c9de68fdb0adf9c2664f64685e8b7b884d36c',
            txnLineId: 2,
            dateTimeSEC: 1662218973,
            dateTime: '2022-09-03T15:29:33.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.667503020245087837',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
            feeAmount: '0.00113666086297157',
          },
          {
            id: '0x3fe3cee6012e5a4709a74ec7843c9de68fdb0adf9c2664f64685e8b7b884d36c.0xba5BDe662c17e2aDFF1075610382B9B691296350',
            txnLineId: 3,
            dateTimeSEC: 1662218973,
            dateTime: '2022-09-03T15:29:33.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.376550994001152',
            amount: '12300',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x7685cd3ddd862b8745b1082a6acb19e14eaa74f3',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1136660862971570' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1667503020245087837' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '8296034926592476' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '12300' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1667503020245087837' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '8296034926592476' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '12300' },
            coin: '376550994001152',
            unit: '376550994001152',
            currencyId: 'COIN.376550994001152',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1136660862971570' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xbfd44c3b4252a068ea4e8369495195a26112a74f37faf6f9623a1dc67a5a67d8',
        created: 1662218792,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xba5BDe662c17e2aDFF1075610382B9B691296350',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xbfd44c3b4252a068ea4e8369495195a26112a74f37faf6f9623a1dc67a5a67d8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x095ea7b3'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.0003656790194466' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1548.35',
              failedOrIncomplete: false,
              priceId: '269c9860-94fc-42ce-8835-511291ad71ad',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-365679019446600' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '365679019446600' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x76c22dee76bfdcb697e7c55c8c1bc9fd87560621ba92e0c54fcfb0ec1cb11f3b',
        created: 1662065189,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x76c22dee76bfdcb697e7c55c8c1bc9fd87560621ba92e0c54fcfb0ec1cb11f3b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Transfer',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825878183430' }],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.001414794049259871' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825878183430' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1580.22',
              failedOrIncomplete: false,
              priceId: '2eddbf38-b3c9-48ee-8b90-8f20136798dd',
            },
            {
              coin: 'BLOCKS-215000630',
              coinUnit: 'BLOCKS-215000630',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x76c22dee76bfdcb697e7c55c8c1bc9fd87560621ba92e0c54fcfb0ec1cb11f3b',
            txnLineId: 1,
            dateTimeSEC: 1662065189,
            dateTime: '2022-09-01T20:46:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825878183430',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x76c22dee76bfdcb697e7c55c8c1bc9fd87560621ba92e0c54fcfb0ec1cb11f3b',
            txnLineId: 2,
            dateTimeSEC: 1662065189,
            dateTime: '2022-09-01T20:46:29.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825878183430',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1414794049259871' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1414794049259871' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x9eb7360e61ba6cea602105d31ea54ee0f99d3d2da7d35aa4b79610c684b619c9',
        created: 1662060659,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '15.5' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            amount: { value: { mathjs: 'BigNumber', value: '15.5' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9eb7360e61ba6cea602105d31ea54ee0f99d3d2da7d35aa4b79610c684b619c9',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfb0f3ee1'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.004399155158967444' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-15.5' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825878183430' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1564.97',
              failedOrIncomplete: false,
              priceId: '90564d94-ce8c-44d1-a6aa-1120f69455b5',
            },
            {
              coin: 'BLOCKS-215000630',
              coinUnit: 'BLOCKS-215000630',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9eb7360e61ba6cea602105d31ea54ee0f99d3d2da7d35aa4b79610c684b619c9',
            txnLineId: 1,
            dateTimeSEC: 1662060659,
            dateTime: '2022-09-01T19:30:59.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825878183430',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x8A09eD6A990324d26fD4070d6C81b2BBD3288cfD',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
          {
            id: '0x9eb7360e61ba6cea602105d31ea54ee0f99d3d2da7d35aa4b79610c684b619c9',
            txnLineId: 2,
            dateTimeSEC: 1662060659,
            dateTime: '2022-09-01T19:30:59.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '15.5',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x00000000006c3852cbEf3e08E8dF289169EdE581',
            feeAmount: '0.004399155158967444',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-4399155158967444' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-15500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-15500000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825878183430',
            unit: '399825878183430',
            currencyId: 'COIN.399825878183430',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '4399155158967444' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x1b1827f529ca78b19719f208ad6547580b9dfc15596c86b35c382df0a928a794',
        created: 1661954242,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1b1827f529ca78b19719f208ad6547580b9dfc15596c86b35c382df0a928a794',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825946125313' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-14', coinUnit: 'UNKNOWN-14', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1b1827f529ca78b19719f208ad6547580b9dfc15596c86b35c382df0a928a794',
            txnLineId: 1,
            dateTimeSEC: 1661954242,
            dateTime: '2022-08-31T13:57:22.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825946125313',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825946125313',
            unit: '399825946125313',
            currencyId: 'COIN.399825946125313',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825946125313',
            unit: '399825946125313',
            currencyId: 'COIN.399825946125313',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x302907fa9021beafd1e35c3e94171af08d410c95a913972f48f3cfc1eb3d0416',
        created: 1661883491,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x302907fa9021beafd1e35c3e94171af08d410c95a913972f48f3cfc1eb3d0416',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x23b872dd'],
        type: 'Transfer',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825950125568' }],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00124474928873244' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825950125568' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1506.62',
              failedOrIncomplete: false,
              priceId: 'f4bd3758-9766-4d81-94a5-d0a1cf1dc941',
            },
            { coin: 'SUPR-28029', coinUnit: 'SUPR-28029', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x302907fa9021beafd1e35c3e94171af08d410c95a913972f48f3cfc1eb3d0416',
            txnLineId: 1,
            dateTimeSEC: 1661883491,
            dateTime: '2022-08-30T18:18:11.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825950125568',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x302907fa9021beafd1e35c3e94171af08d410c95a913972f48f3cfc1eb3d0416',
            txnLineId: 2,
            dateTimeSEC: 1661883491,
            dateTime: '2022-08-30T18:18:11.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825950125568',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1244749288732440' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1244749288732440' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x7fe6b3fa4b862736457c285293e7d267d48c9fa30e72872d750e3db0c9f1a10e',
        created: 1661883069,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x7e8FCd1046ae351Af66C12b75B051FE550aFe71e',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7fe6b3fa4b862736457c285293e7d267d48c9fa30e72872d750e3db0c9f1a10e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x534665e9'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825950125568' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-28029', coinUnit: 'SUPR-28029', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7fe6b3fa4b862736457c285293e7d267d48c9fa30e72872d750e3db0c9f1a10e',
            txnLineId: 1,
            dateTimeSEC: 1661883069,
            dateTime: '2022-08-30T18:11:09.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825950125568',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x7e8FCd1046ae351Af66C12b75B051FE550aFe71e',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825950125568',
            unit: '399825950125568',
            currencyId: 'COIN.399825950125568',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x175db79b01c45056636f816db9b7452e3c1b7aae8942c36e794748135376f185',
        created: 1661878831,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '185.4' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '185.4' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x175db79b01c45056636f816db9b7452e3c1b7aae8942c36e794748135376f185',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0f2b2532'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.006083260352734408' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-185.4' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1489.45',
              failedOrIncomplete: false,
              priceId: 'f4b20827-f1f9-46c3-8aec-4bc43c2a5795',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x175db79b01c45056636f816db9b7452e3c1b7aae8942c36e794748135376f185',
            txnLineId: 1,
            dateTimeSEC: 1661878831,
            dateTime: '2022-08-30T17:00:31.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '185.4',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            feeAmount: '0.006083260352734408',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-6083260352734408' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-185400000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-185400000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '6083260352734408' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x5023fc79a01d3a9435c3708ba3bf6d2c4815ff78bc876347c24f41da7d931fe7',
        created: 1661877243,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '200' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '200' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5023fc79a01d3a9435c3708ba3bf6d2c4815ff78bc876347c24f41da7d931fe7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '200' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1487.48',
              failedOrIncomplete: false,
              priceId: '83aa6553-ce5f-4cee-b56c-a1e64fb10bb3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x5023fc79a01d3a9435c3708ba3bf6d2c4815ff78bc876347c24f41da7d931fe7',
            txnLineId: 1,
            dateTimeSEC: 1661877243,
            dateTime: '2022-08-30T16:34:03.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '200',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '200000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '200000000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xa56a320626f35b5b5a6c9cb734ae189b446b67ed48b9d896688acc2a0bf1f2e3',
        created: 1661877165,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            amount: { value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xa56a320626f35b5b5a6c9cb734ae189b446b67ed48b9d896688acc2a0bf1f2e3',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: [],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '0.01' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1487.48',
              failedOrIncomplete: false,
              priceId: '83aa6553-ce5f-4cee-b56c-a1e64fb10bb3',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xa56a320626f35b5b5a6c9cb734ae189b446b67ed48b9d896688acc2a0bf1f2e3',
            txnLineId: 1,
            dateTimeSEC: 1661877165,
            dateTime: '2022-08-30T16:32:45.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.01',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xceB69F6342eCE283b2F5c9088Ff249B5d0Ae66ea',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '10000000000000000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [
          { value: { mathjs: 'BigNumber', value: '10000000000000000' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        paidFees: [],
      },
      {
        id: '0x74eeacc31133ad962cfbe119bfaba964c00bc07e72e2d27c1a1fa0281c84a60c',
        created: 1661799968,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x74eeacc31133ad962cfbe119bfaba964c00bc07e72e2d27c1a1fa0281c84a60c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825872828416' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-13', coinUnit: 'UNKNOWN-13', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x74eeacc31133ad962cfbe119bfaba964c00bc07e72e2d27c1a1fa0281c84a60c',
            txnLineId: 1,
            dateTimeSEC: 1661799968,
            dateTime: '2022-08-29T19:06:08.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825872828416',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825872828416',
            unit: '399825872828416',
            currencyId: 'COIN.399825872828416',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825872828416',
            unit: '399825872828416',
            currencyId: 'COIN.399825872828416',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x965e97534b5550ddfe5ccc856ef594eb0984fbe56b004a6b60dc6bd86deab099',
        created: 1661638000,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x5457Ef2D4E248d62B7194f57901361024Af9A3F0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x965e97534b5550ddfe5ccc856ef594eb0984fbe56b004a6b60dc6bd86deab099',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831874537984' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [{ coin: 'ᗩᗰ-32', coinUnit: 'ᗩᗰ-32', fiat: 'USD', rate: '0', failedOrIncomplete: false }],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x965e97534b5550ddfe5ccc856ef594eb0984fbe56b004a6b60dc6bd86deab099',
            txnLineId: 1,
            dateTimeSEC: 1661638000,
            dateTime: '2022-08-27T22:06:40.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831874537984',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831874537984',
            unit: '399831874537984',
            currencyId: 'COIN.399831874537984',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831874537984',
            unit: '399831874537984',
            currencyId: 'COIN.399831874537984',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x18ed9259232e2ca51a5d492ecff99ebfec9bcaf7d0b6af63b33c4b5afc6ae055',
        created: 1661541757,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xdd012153e008346591153fFf28B0DD6724f0C256',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x18ed9259232e2ca51a5d492ecff99ebfec9bcaf7d0b6af63b33c4b5afc6ae055',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831764070656' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BEEPLESPRINGCOLLECTION-100020006',
              coinUnit: 'BEEPLESPRINGCOLLECTION-100020006',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x18ed9259232e2ca51a5d492ecff99ebfec9bcaf7d0b6af63b33c4b5afc6ae055',
            txnLineId: 1,
            dateTimeSEC: 1661541757,
            dateTime: '2022-08-26T19:22:37.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831764070656',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831764070656',
            unit: '399831764070656',
            currencyId: 'COIN.399831764070656',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831764070656',
            unit: '399831764070656',
            currencyId: 'COIN.399831764070656',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe8e35845d2c7e68f56c892bccbb51820f6e7c313a13c5e69e4e42958f9f025ae',
        created: 1661541727,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xdd012153e008346591153fFf28B0DD6724f0C256',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe8e35845d2c7e68f56c892bccbb51820f6e7c313a13c5e69e4e42958f9f025ae',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831817004293' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BEEPLESPRINGCOLLECTION-100040006',
              coinUnit: 'BEEPLESPRINGCOLLECTION-100040006',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe8e35845d2c7e68f56c892bccbb51820f6e7c313a13c5e69e4e42958f9f025ae',
            txnLineId: 1,
            dateTimeSEC: 1661541727,
            dateTime: '2022-08-26T19:22:07.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831817004293',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831817004293',
            unit: '399831817004293',
            currencyId: 'COIN.399831817004293',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831817004293',
            unit: '399831817004293',
            currencyId: 'COIN.399831817004293',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x7d324803e0d2c83b9eb4cee0579a1bcc93491c15a90e316f846a7cc411bb5bc7',
        created: 1661541692,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xdd012153e008346591153fFf28B0DD6724f0C256',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7d324803e0d2c83b9eb4cee0579a1bcc93491c15a90e316f846a7cc411bb5bc7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831819993090' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BEEPLESPRINGCOLLECTION-100030006',
              coinUnit: 'BEEPLESPRINGCOLLECTION-100030006',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7d324803e0d2c83b9eb4cee0579a1bcc93491c15a90e316f846a7cc411bb5bc7',
            txnLineId: 1,
            dateTimeSEC: 1661541692,
            dateTime: '2022-08-26T19:21:32.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831819993090',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831819993090',
            unit: '399831819993090',
            currencyId: 'COIN.399831819993090',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831819993090',
            unit: '399831819993090',
            currencyId: 'COIN.399831819993090',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x7dcef4960f257add0ed2c897ef62072bdb66522fcba07e6950ce75832384d702',
        created: 1661541673,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xdd012153e008346591153fFf28B0DD6724f0C256',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7dcef4960f257add0ed2c897ef62072bdb66522fcba07e6950ce75832384d702',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831769861888' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BEEPLESPRINGCOLLECTION-100010006',
              coinUnit: 'BEEPLESPRINGCOLLECTION-100010006',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7dcef4960f257add0ed2c897ef62072bdb66522fcba07e6950ce75832384d702',
            txnLineId: 1,
            dateTimeSEC: 1661541673,
            dateTime: '2022-08-26T19:21:13.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831769861888',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831769861888',
            unit: '399831769861888',
            currencyId: 'COIN.399831769861888',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831769861888',
            unit: '399831769861888',
            currencyId: 'COIN.399831769861888',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x03c72759236f7dad7387568a03b0b308bcd9dbd999700e3a6cbe8328dca758a8',
        created: 1661541642,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xdd012153e008346591153fFf28B0DD6724f0C256',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x03c72759236f7dad7387568a03b0b308bcd9dbd999700e3a6cbe8328dca758a8',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831803304449' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'BEEPLESPRINGCOLLECTION-100050006',
              coinUnit: 'BEEPLESPRINGCOLLECTION-100050006',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x03c72759236f7dad7387568a03b0b308bcd9dbd999700e3a6cbe8328dca758a8',
            txnLineId: 1,
            dateTimeSEC: 1661541642,
            dateTime: '2022-08-26T19:20:42.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831803304449',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803304449',
            unit: '399831803304449',
            currencyId: 'COIN.399831803304449',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831803304449',
            unit: '399831803304449',
            currencyId: 'COIN.399831803304449',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x550a1f5cb79b95f1925fa99cec90afd65fc064cfb979ecb988fec886b559ba81',
        created: 1661541628,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x343fF9C3b20115e25B4438Fa1bAd6482E158f957',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x550a1f5cb79b95f1925fa99cec90afd65fc064cfb979ecb988fec886b559ba81',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831839529985' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'CRYPTOCUBESCREATORSTHERD-2040016000',
              coinUnit: 'CRYPTOCUBESCREATORSTHERD-2040016000',
              fiat: 'USD',
              rate: '0',
              failedOrIncomplete: false,
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x550a1f5cb79b95f1925fa99cec90afd65fc064cfb979ecb988fec886b559ba81',
            txnLineId: 1,
            dateTimeSEC: 1661541628,
            dateTime: '2022-08-26T19:20:28.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831839529985',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839529985',
            unit: '399831839529985',
            currencyId: 'COIN.399831839529985',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831839529985',
            unit: '399831839529985',
            currencyId: 'COIN.399831839529985',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xfc1f433a19a674e6d8b3d4243297bbf9332e7d3cfbe26481288c2333c94beaf4',
        created: 1661541614,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfc1f433a19a674e6d8b3d4243297bbf9332e7d3cfbe26481288c2333c94beaf4',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x23b872dd'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825876163328' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-6176', coinUnit: 'SUPR-6176', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfc1f433a19a674e6d8b3d4243297bbf9332e7d3cfbe26481288c2333c94beaf4',
            txnLineId: 1,
            dateTimeSEC: 1661541614,
            dateTime: '2022-08-26T19:20:14.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825876163328',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xe1D29d0a39962a9a8d2A297ebe82e166F8b8EC18',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876163328',
            unit: '399825876163328',
            currencyId: 'COIN.399825876163328',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825876163328',
            unit: '399825876163328',
            currencyId: 'COIN.399825876163328',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xe677f42c7b3acacd4c8a983e6009287aa49947598286cd1a37bb64db1565aa34',
        created: 1661441832,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xe677f42c7b3acacd4c8a983e6009287aa49947598286cd1a37bb64db1565aa34',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831781674499' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-12', coinUnit: 'UNKNOWN-12', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xe677f42c7b3acacd4c8a983e6009287aa49947598286cd1a37bb64db1565aa34',
            txnLineId: 1,
            dateTimeSEC: 1661441832,
            dateTime: '2022-08-25T15:37:12.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831781674499',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831781674499',
            unit: '399831781674499',
            currencyId: 'COIN.399831781674499',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831781674499',
            unit: '399831781674499',
            currencyId: 'COIN.399831781674499',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xadf6dd7ccdb18e869e615015ea70bf2c91c7d91f47d8f9ae011fc881f096c68a',
        created: 1661440916,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x4B76837F8D8Ad0A28590d06E53dCD44b6B7D4554',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x33FD426905F149f8376e227d0C9D3340AaD17aF1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xadf6dd7ccdb18e869e615015ea70bf2c91c7d91f47d8f9ae011fc881f096c68a',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xfeeb5a9a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831812772864' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'UNKNOWN-11', coinUnit: 'UNKNOWN-11', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xadf6dd7ccdb18e869e615015ea70bf2c91c7d91f47d8f9ae011fc881f096c68a',
            txnLineId: 1,
            dateTimeSEC: 1661440916,
            dateTime: '2022-08-25T15:21:56.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831812772864',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x0000000000000000000000000000000000000000',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831812772864',
            unit: '399831812772864',
            currencyId: 'COIN.399831812772864',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831812772864',
            unit: '399831812772864',
            currencyId: 'COIN.399831812772864',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x5e07e2ee32e068c1f69499d97951ae88bcff040d4643ecad4a07a6406a3a561c',
        created: 1661141831,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x5e07e2ee32e068c1f69499d97951ae88bcff040d4643ecad4a07a6406a3a561c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x7430e0c6'],
        type: 'ContractExecution',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '-0.001068509068256472' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1607.58',
              failedOrIncomplete: false,
              priceId: '7eabece4-6bf0-4c8d-9670-f380d30a2ba7',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1068509068256472' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
        fullAmountSetWithoutFees: [],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1068509068256472' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xb7940d3c820cc50b6db09ad5b0e235010c9b4e0c52a27b220f73f39f0e97129e',
        created: 1661127319,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x48D26fbD89dDA10c72cD8DC7B82b9eeE0AF6B48d',
            amount: { value: { mathjs: 'BigNumber', value: '1.221' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '1.221' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xb7940d3c820cc50b6db09ad5b0e235010c9b4e0c52a27b220f73f39f0e97129e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1.11' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1610.13',
              failedOrIncomplete: false,
              priceId: '57669256-1992-4888-b60b-e31d8b028ef4',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xb7940d3c820cc50b6db09ad5b0e235010c9b4e0c52a27b220f73f39f0e97129e',
            txnLineId: 1,
            dateTimeSEC: 1661127319,
            dateTime: '2022-08-22T00:15:19.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '1.11',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xcda72070e455bb31c7690a170224ce43623d0b6f',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1110000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1110000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0xaf097d1f04b49a1740af497faee811255453b429ca0e3a21e579d5569b004b1c',
        created: 1661127146,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '1.11' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '1.11' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xaf097d1f04b49a1740af497faee811255453b429ca0e3a21e579d5569b004b1c',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.0004325356887705' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1.11' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1610.13',
              failedOrIncomplete: false,
              priceId: '57669256-1992-4888-b60b-e31d8b028ef4',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xaf097d1f04b49a1740af497faee811255453b429ca0e3a21e579d5569b004b1c',
            txnLineId: 1,
            dateTimeSEC: 1661127146,
            dateTime: '2022-08-22T00:12:26.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '1.11',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            feeAmount: '0.0004325356887705',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-432535688770500' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1110000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1110000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '432535688770500' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xfd22062ec4f9869796e25e35b84dd30211c595f9eda83d50d5c82e191a9bd7a6',
        created: 1661126860,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x48D26fbD89dDA10c72cD8DC7B82b9eeE0AF6B48d',
            amount: { value: { mathjs: 'BigNumber', value: '0.9768' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '0.9768' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xfd22062ec4f9869796e25e35b84dd30211c595f9eda83d50d5c82e191a9bd7a6',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '0.888' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1610.13',
              failedOrIncomplete: false,
              priceId: '57669256-1992-4888-b60b-e31d8b028ef4',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xfd22062ec4f9869796e25e35b84dd30211c595f9eda83d50d5c82e191a9bd7a6',
            txnLineId: 1,
            dateTimeSEC: 1661126860,
            dateTime: '2022-08-22T00:07:40.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.888',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xcda72070e455bb31c7690a170224ce43623d0b6f',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '888000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '888000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x0dd427446626b2e7db3e3121bea9887d0a90731c7f2b59991f5af62742c67168',
        created: 1661126684,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.888' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '0.888' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x0dd427446626b2e7db3e3121bea9887d0a90731c7f2b59991f5af62742c67168',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00039230925780816' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.888' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1610.13',
              failedOrIncomplete: false,
              priceId: '57669256-1992-4888-b60b-e31d8b028ef4',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x0dd427446626b2e7db3e3121bea9887d0a90731c7f2b59991f5af62742c67168',
            txnLineId: 1,
            dateTimeSEC: 1661126684,
            dateTime: '2022-08-22T00:04:44.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.888',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            feeAmount: '0.00039230925780816',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-392309257808160' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-888000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-888000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '392309257808160' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0xacfc6200a37c1e321afdc2334cfd0d8bcaebf46a583e4d4914d59fc453479238',
        created: 1661126295,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x48D26fbD89dDA10c72cD8DC7B82b9eeE0AF6B48d',
            amount: { value: { mathjs: 'BigNumber', value: '0.7182' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '0.7182' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0xacfc6200a37c1e321afdc2334cfd0d8bcaebf46a583e4d4914d59fc453479238',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '0.6529' }, currencyId: 'COIN.10' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1617.86',
              failedOrIncomplete: false,
              priceId: '8a3b5a02-1edb-4097-b104-f6a1974ae940',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0xacfc6200a37c1e321afdc2334cfd0d8bcaebf46a583e4d4914d59fc453479238',
            txnLineId: 1,
            dateTimeSEC: 1661126295,
            dateTime: '2022-08-21T23:58:15.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.6529',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xcda72070e455bb31c7690a170224ce43623d0b6f',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '652900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '652900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x8ae1cca48cc529d16f27a14aa957d7f15c791397d138c5d73a116eb1c2bd328b',
        created: 1661125971,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.6529' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            amount: { value: { mathjs: 'BigNumber', value: '0.6529' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8ae1cca48cc529d16f27a14aa957d7f15c791397d138c5d73a116eb1c2bd328b',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xb6aff8c1'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000388809734825189' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.6529' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1617.86',
              failedOrIncomplete: false,
              priceId: '8a3b5a02-1edb-4097-b104-f6a1974ae940',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8ae1cca48cc529d16f27a14aa957d7f15c791397d138c5d73a116eb1c2bd328b',
            txnLineId: 1,
            dateTimeSEC: 1661125971,
            dateTime: '2022-08-21T23:52:51.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.6529',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0xcDA72070E455bb31C7690a170224Ce43623d0B6f',
            feeAmount: '0.000388809734825189',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-388809734825189' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-652900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-652900000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '388809734825189' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x76a55b37baa8754aab0233032b4ee4c2338c053d6811c19cea977bb3f02d23c7',
        created: 1660934187,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x4b10701Bfd7BFEdc47d50562b76b436fbB5BdB3B',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x76a55b37baa8754aab0233032b4ee4c2338c053d6811c19cea977bb3f02d23c7',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002620872802173406' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399832077960704' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1699.31',
              failedOrIncomplete: false,
              priceId: 'e9aa0334-310a-4ca2-8bd3-2c9e8f266748',
            },
            { coin: 'LILNOUN-1489', coinUnit: 'LILNOUN-1489', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x76a55b37baa8754aab0233032b4ee4c2338c053d6811c19cea977bb3f02d23c7',
            txnLineId: 1,
            dateTimeSEC: 1660934187,
            dateTime: '2022-08-19T18:36:27.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399832077960704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2620872802173406' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832077960704',
            unit: '399832077960704',
            currencyId: 'COIN.399832077960704',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399832077960704',
            unit: '399832077960704',
            currencyId: 'COIN.399832077960704',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2620872802173406' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x363d3da9ca3d58b956ff57c6d0e990c6a35386cbe79e2e2ec5e76ec325d0ab06',
        created: 1660934074,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0.173388305986420084' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x0fc584529a2AEfA997697FAfAcbA5831faC0c22d',
            amount: { value: { mathjs: 'BigNumber', value: '0.173388305986420084' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x363d3da9ca3d58b956ff57c6d0e990c6a35386cbe79e2e2ec5e76ec325d0ab06',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x7fc82484'],
        type: 'Trade',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.009047222379024238' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-0.173388305986420084' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '0.001716715900855644' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399832077960704' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1699.31',
              failedOrIncomplete: false,
              priceId: 'e9aa0334-310a-4ca2-8bd3-2c9e8f266748',
            },
            {
              coin: 'WETH',
              coinUnit: 'WETH',
              fiat: 'USD',
              rate: '1701.43',
              failedOrIncomplete: false,
              priceId: '0cce9a57-58d2-4c1d-bfcf-c9a04f31c7cb',
            },
            { coin: 'LIL', coinUnit: 'LIL', fiat: 'USD', rate: '0', failedOrIncomplete: false },
            { coin: 'LILNOUN-1489', coinUnit: 'LILNOUN-1489', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x363d3da9ca3d58b956ff57c6d0e990c6a35386cbe79e2e2ec5e76ec325d0ab06',
            txnLineId: 1,
            dateTimeSEC: 1660934074,
            dateTime: '2022-08-19T18:34:34.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.10',
            amount: '0.001716715900855644',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x0fc584529a2aefa997697fafacba5831fac0c22d',
            to: '0xbb367b95c4e118b872ebaa64734b2fcfa5c252ef',
          },
          {
            id: '0x363d3da9ca3d58b956ff57c6d0e990c6a35386cbe79e2e2ec5e76ec325d0ab06',
            txnLineId: 2,
            dateTimeSEC: 1660934074,
            dateTime: '2022-08-19T18:34:34.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '0.173388305986420084',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x0fc584529a2AEfA997697FAfAcbA5831faC0c22d',
            feeAmount: '0.009047222379024238',
          },
          {
            id: '0x363d3da9ca3d58b956ff57c6d0e990c6a35386cbe79e2e2ec5e76ec325d0ab06',
            txnLineId: 3,
            dateTimeSEC: 1660934074,
            dateTime: '2022-08-19T18:34:34.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399832077960704',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x18222A762Bf67024193De25e1CdC7AA6e614C695',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-9047222379024238' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-173388305986420084' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '1716715900855644' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832077960704',
            unit: '399832077960704',
            currencyId: 'COIN.399832077960704',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-173388305986420084' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
          { value: { mathjs: 'BigNumber', value: '1716715900855644' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399832077960704',
            unit: '399832077960704',
            currencyId: 'COIN.399832077960704',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '9047222379024238' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x7a0f000e0e26c15beb518df024fceaf21b44eee9ff988584ab7d20a256876e3e',
        created: 1660932846,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x4B4fA26161F7d9f387B01Ad6Bf0169Ba5b0F3A85',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x7a0f000e0e26c15beb518df024fceaf21b44eee9ff988584ab7d20a256876e3e',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xf242432a'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399831879187712' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: '3a85-ERC1155-3', coinUnit: '3a85-ERC1155-3', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x7a0f000e0e26c15beb518df024fceaf21b44eee9ff988584ab7d20a256876e3e',
            txnLineId: 1,
            dateTimeSEC: 1660932846,
            dateTime: '2022-08-19T18:14:06.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399831879187712',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879187712',
            unit: '399831879187712',
            currencyId: 'COIN.399831879187712',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399831879187712',
            unit: '399831879187712',
            currencyId: 'COIN.399831879187712',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x1b274041330a00db88a8d46723454023746027a1ee58c4ffd45b0e017b48e5b1',
        created: 1660932795,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x1b274041330a00db88a8d46723454023746027a1ee58c4ffd45b0e017b48e5b1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x23b872dd'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825968283904' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-18241', coinUnit: 'SUPR-18241', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x1b274041330a00db88a8d46723454023746027a1ee58c4ffd45b0e017b48e5b1',
            txnLineId: 1,
            dateTimeSEC: 1660932795,
            dateTime: '2022-08-19T18:13:15.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825968283904',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0x45ae5145454cABd4Ab6F61D3023bF19002059C50',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968283904',
            unit: '399825968283904',
            currencyId: 'COIN.399825968283904',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825968283904',
            unit: '399825968283904',
            currencyId: 'COIN.399825968283904',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x05d675ae9977c901a2c6fc9b55f91043f015f696c7a25bdc9d49c6cd121de242',
        created: 1660803892,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x05d675ae9977c901a2c6fc9b55f91043f015f696c7a25bdc9d49c6cd121de242',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x42842e0e'],
        type: 'Transfer',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825911116544' }],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00157844105715857' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825911116544' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1839.45',
              failedOrIncomplete: false,
              priceId: 'd38ff457-5236-469f-87d6-edbd3baf859c',
            },
            { coin: 'NOUN-412', coinUnit: 'NOUN-412', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x05d675ae9977c901a2c6fc9b55f91043f015f696c7a25bdc9d49c6cd121de242',
            txnLineId: 1,
            dateTimeSEC: 1660803892,
            dateTime: '2022-08-18T06:24:52.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825911116544',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x05d675ae9977c901a2c6fc9b55f91043f015f696c7a25bdc9d49c6cd121de242',
            txnLineId: 2,
            dateTimeSEC: 1660803892,
            dateTime: '2022-08-18T06:24:52.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825911116544',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-1578441057158570' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '1578441057158570' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x9e52adf8d58cfce5546b8ef167e1f2322c7d30e36d63caf82d85c32032dec294',
        created: 1660803629,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0x85906cF629ae1DA297548769ecE3e3E6a4f3288f',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb2341612271e122ff20905c9e389c3d7f0F222a1',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x9e52adf8d58cfce5546b8ef167e1f2322c7d30e36d63caf82d85c32032dec294',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0xcbd5796f'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825911116544' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'NOUN-412', coinUnit: 'NOUN-412', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x9e52adf8d58cfce5546b8ef167e1f2322c7d30e36d63caf82d85c32032dec294',
            txnLineId: 1,
            dateTimeSEC: 1660803629,
            dateTime: '2022-08-18T06:20:29.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825911116544',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0x830BD73E4184ceF73443C15111a1DF14e495C706',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825911116544',
            unit: '399825911116544',
            currencyId: 'COIN.399825911116544',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x45274b03093ac977e51aca00f759dbb8487df4c1ef0fda0bf3c8ab56a7c950ad',
        created: 1660803048,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '76.69' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x830BD73E4184ceF73443C15111a1DF14e495C706',
            amount: { value: { mathjs: 'BigNumber', value: '76.69' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x45274b03093ac977e51aca00f759dbb8487df4c1ef0fda0bf3c8ab56a7c950ad',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x659dd2b4'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.000560107495925928' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-76.69' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1839.45',
              failedOrIncomplete: false,
              priceId: 'd38ff457-5236-469f-87d6-edbd3baf859c',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x45274b03093ac977e51aca00f759dbb8487df4c1ef0fda0bf3c8ab56a7c950ad',
            txnLineId: 1,
            dateTimeSEC: 1660803048,
            dateTime: '2022-08-18T06:10:48.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '76.69',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x830BD73E4184ceF73443C15111a1DF14e495C706',
            feeAmount: '0.000560107495925928',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-560107495925928' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-76690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-76690000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '560107495925928' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x8179caeef51c7ac4b848c4eb6f33ef4b771605ddbb3dc2cd680c1827427c6761',
        created: 1660786230,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0xb932a70A57673d89f4acfFBE830E8ed7f75Fb9e0',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x8179caeef51c7ac4b848c4eb6f33ef4b771605ddbb3dc2cd680c1827427c6761',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x23b872dd'],
        type: 'Transfer',
        walletAmounts: [
          {
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825951384064' }],
          },
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.00076868442973871' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-1' }, currencyId: 'COIN.399825951384064' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1857.33',
              failedOrIncomplete: false,
              priceId: '1e6f198c-5f9b-4b6b-956a-d0a14cad8d20',
            },
            { coin: 'SUPR-14664', coinUnit: 'SUPR-14664', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x8179caeef51c7ac4b848c4eb6f33ef4b771605ddbb3dc2cd680c1827427c6761',
            txnLineId: 1,
            dateTimeSEC: 1660786230,
            dateTime: '2022-08-18T01:30:30.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825951384064',
            amount: '1',
            walletId: 'j2R8uH7ZZRxFvECy13V2',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
          {
            id: '0x8179caeef51c7ac4b848c4eb6f33ef4b771605ddbb3dc2cd680c1827427c6761',
            txnLineId: 2,
            dateTimeSEC: 1660786230,
            dateTime: '2022-08-18T01:30:30.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.399825951384064',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x1b7844CfaE4C823Ac6389855D47106a70c84F067',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-768684429738710' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '768684429738710' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
      {
        id: '0x4fc5b4a4f91e0ea9bdf280f8901b23279a411051d18cb9613fe7b68375034ae1',
        created: 1660785939,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xE2E58b5BEc8F79c7a681C9efBeC12b3ECe7A76fd',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '0' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x4fc5b4a4f91e0ea9bdf280f8901b23279a411051d18cb9613fe7b68375034ae1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x534665e9'],
        type: 'Receive',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [{ value: { mathjs: 'BigNumber', value: '1' }, currencyId: 'COIN.399825951384064' }],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            { coin: 'SUPR-14664', coinUnit: 'SUPR-14664', fiat: 'USD', rate: '0', failedOrIncomplete: false },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x4fc5b4a4f91e0ea9bdf280f8901b23279a411051d18cb9613fe7b68375034ae1',
            txnLineId: 1,
            dateTimeSEC: 1660785939,
            dateTime: '2022-08-18T01:25:39.000Z',
            operation: 'DEPOSIT',
            assetId: 'COIN.399825951384064',
            amount: '1',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xE2E58b5BEc8F79c7a681C9efBeC12b3ECe7A76fd',
            to: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
          },
        ],
        fullAmountSet: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '1' },
            coin: '399825951384064',
            unit: '399825951384064',
            currencyId: 'COIN.399825951384064',
          },
        ],
        paidFees: [],
      },
      {
        id: '0x118b6b4c37c02230fb525eca931a24b9082601c71dda3ffb170966d584ba76b1',
        created: 1660785799,
        categorizationStatus: 'Uncategorized',
        reconciliationStatus: 'Unreconciled',
        from: [
          {
            address: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            amount: { value: { mathjs: 'BigNumber', value: '97.85' }, currencyId: 'COIN.10' },
          },
        ],
        to: [
          {
            address: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            amount: { value: { mathjs: 'BigNumber', value: '97.85' }, currencyId: 'COIN.10' },
          },
        ],
        hasMatchedInvoices: false,
        ignore: false,
        isCombined: false,
        isCombinedSubTransaction: false,
        readonly: false,
        viewLinks: [
          {
            link: 'https://etherscan.io/tx/0x118b6b4c37c02230fb525eca931a24b9082601c71dda3ffb170966d584ba76b1',
            name: 'Etherscan',
            viewSource: 1,
          },
        ],
        methods: ['0x0f2b2532'],
        type: 'Send',
        walletAmounts: [
          {
            walletId: 'wYbeINrejmSTvvFz5G3P',
            amounts: [
              { value: { mathjs: 'BigNumber', value: '-0.002211743025561584' }, currencyId: 'COIN.10' },
              { value: { mathjs: 'BigNumber', value: '-97.85' }, currencyId: 'COIN.10' },
            ],
          },
        ],
        exchangeRates: {
          exchangeRates: [
            {
              coin: 'ETH',
              coinUnit: 'Ether',
              fiat: 'USD',
              rate: '1857.33',
              failedOrIncomplete: false,
              priceId: '1e6f198c-5f9b-4b6b-956a-d0a14cad8d20',
            },
          ],
          exchangeRatesDirty: false,
        },
        txnLines: [
          {
            id: '0x118b6b4c37c02230fb525eca931a24b9082601c71dda3ffb170966d584ba76b1',
            txnLineId: 1,
            dateTimeSEC: 1660785799,
            dateTime: '2022-08-18T01:23:19.000Z',
            operation: 'WITHDRAW',
            assetId: 'COIN.10',
            feeAssetId: 'COIN.10',
            amount: '97.85',
            walletId: 'wYbeINrejmSTvvFz5G3P',
            from: '0xBB367b95C4E118B872EbaA64734B2FcfA5c252Ef',
            to: '0x6D7c44773C52D396F43c2D511B81aa168E9a7a42',
            feeAmount: '0.002211743025561584',
          },
        ],
        fullAmountSet: [
          { value: { mathjs: 'BigNumber', value: '-2211743025561584' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
          {
            value: { mathjs: 'BigNumber', value: '-97850000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        fullAmountSetWithoutFees: [
          {
            value: { mathjs: 'BigNumber', value: '-97850000000000000000' },
            coin: '10',
            unit: '10',
            currencyId: 'COIN.10',
          },
        ],
        paidFees: [
          { value: { mathjs: 'BigNumber', value: '2211743025561584' }, coin: '10', unit: '10', currencyId: 'COIN.10' },
        ],
      },
    ],
    coins: [
      { currencyId: 'COIN.407023829387264', ticker: 'MEMES-71', unit: 'MEMES-71' },
      { currencyId: 'COIN.10', ticker: 'ETH', unit: 'Ether' },
      { currencyId: 'COIN.406617079046656', ticker: 'ABXBMG-416', unit: 'ABXBMG-416' },
      { currencyId: 'COIN.406557851551232', ticker: 'MEMES-70', unit: 'MEMES-70' },
      { currencyId: 'COIN.400900180992768', ticker: 'FF001-1012032433', unit: 'FF001-1012032433' },
      { currencyId: 'COIN.405903518430720', ticker: 'MEMES-69', unit: 'MEMES-69' },
      { currencyId: 'COIN.404717032766720', ticker: 'MEMES-68', unit: 'MEMES-68' },
      { currencyId: 'COIN.404072106487808', ticker: 'MEMES-67', unit: 'MEMES-67' },
      { currencyId: 'COIN.403362470660096', ticker: '✺RUGCVL-13812', unit: '✺RUGCVL-13812' },
      { currencyId: 'COIN.403362470707968', ticker: '✺RUGCVL-13807', unit: '✺RUGCVL-13807' },
      { currencyId: 'COIN.403362470726656', ticker: '✺RUGCVL-13811', unit: '✺RUGCVL-13811' },
      { currencyId: 'COIN.403362471554304', ticker: '✺RUGCVL-13806', unit: '✺RUGCVL-13806' },
      { currencyId: 'COIN.403362470976512', ticker: '✺RUGCVL-13805', unit: '✺RUGCVL-13805' },
      { currencyId: 'COIN.403303532939265', ticker: 'UNKNOWN-66', unit: 'UNKNOWN-66' },
      { currencyId: 'COIN.403303540018433', ticker: 'UNKNOWN-65', unit: 'UNKNOWN-65' },
      { currencyId: 'COIN.401281082699776', ticker: 'UNKNOWN-64', unit: 'UNKNOWN-64' },
      { currencyId: 'COIN.400900188574208', ticker: 'QQL-186', unit: 'QQL-186' },
      { currencyId: 'COIN.399826690754816', ticker: 'QQL-MP-542', unit: 'QQL-MP-542' },
      { currencyId: 'COIN.400900233885697', ticker: 'UNKNOWN-63', unit: 'UNKNOWN-63' },
      { currencyId: 'COIN.400900170454016', ticker: 'QQL-184', unit: 'QQL-184' },
      { currencyId: 'COIN.400900170343168', ticker: 'QQL-183', unit: 'QQL-183' },
      { currencyId: 'COIN.400900180968960', ticker: 'QQL-182', unit: 'QQL-182' },
      { currencyId: 'COIN.399826690791424', ticker: 'QQL-MP-541', unit: 'QQL-MP-541' },
      { currencyId: 'COIN.399826690766080', ticker: 'QQL-MP-540', unit: 'QQL-MP-540' },
      { currencyId: 'COIN.399826690716672', ticker: 'QQL-MP-539', unit: 'QQL-MP-539' },
      { currencyId: 'COIN.399825882877186', ticker: 'UNKNOWN-62', unit: 'UNKNOWN-62' },
      { currencyId: 'COIN.399825972578048', ticker: 'UNKNOWN-61', unit: 'UNKNOWN-61' },
      { currencyId: 'COIN.399826007599617', ticker: 'UNKNOWN-60', unit: 'UNKNOWN-60' },
      { currencyId: 'COIN.399825845658112', ticker: 'UNKNOWN-59', unit: 'UNKNOWN-59' },
      { currencyId: 'COIN.399831821071873', ticker: 'UNKNOWN-58', unit: 'UNKNOWN-58' },
      { currencyId: 'COIN.399825987811072', ticker: 'UNKNOWN-57', unit: 'UNKNOWN-57' },
      { currencyId: 'COIN.399824115556096', ticker: 'PORSCHE', unit: 'PORSCHE' },
      { currencyId: 'COIN.399831826113536', ticker: 'UNKNOWN-56', unit: 'UNKNOWN-56' },
      { currencyId: 'COIN.399831786460416', ticker: 'UNKNOWN-55', unit: 'UNKNOWN-55' },
      { currencyId: 'COIN.399825912974848', ticker: 'UNKNOWN-54', unit: 'UNKNOWN-54' },
      { currencyId: 'COIN.399825876809986', ticker: 'UNKNOWN-53', unit: 'UNKNOWN-53' },
      { currencyId: 'COIN.399825832248832', ticker: 'UNKNOWN-52', unit: 'UNKNOWN-52' },
      { currencyId: 'COIN.399831803292416', ticker: 'UNKNOWN-51', unit: 'UNKNOWN-51' },
      { currencyId: 'COIN.399825934128128', ticker: 'UNKNOWN-50', unit: 'UNKNOWN-50' },
      { currencyId: 'COIN.399825876776192', ticker: 'UNKNOWN-49', unit: 'UNKNOWN-49' },
      { currencyId: 'COIN.399831814695682', ticker: '7102-ERC1155-15', unit: '7102-ERC1155-15' },
      { currencyId: 'COIN.399825882874115', ticker: 'UNKNOWN-48', unit: 'UNKNOWN-48' },
      { currencyId: 'COIN.399825876163328', ticker: 'SUPR-6176', unit: 'SUPR-6176' },
      { currencyId: 'COIN.399825914359809', ticker: 'UNKNOWN-47', unit: 'UNKNOWN-47' },
      { currencyId: 'COIN.399827292599040', ticker: 'QQL-MP-627', unit: 'QQL-MP-627' },
      { currencyId: 'COIN.399825910181124', ticker: 'UNKNOWN-46', unit: 'UNKNOWN-46' },
      { currencyId: 'COIN.399832026728704', ticker: 'KNIT-2', unit: 'KNIT-2' },
      { currencyId: 'COIN.399825936479232', ticker: 'MOMENT-31000989', unit: 'MOMENT-31000989' },
      { currencyId: 'COIN.399825936513536', ticker: 'MOMENT-31000746', unit: 'MOMENT-31000746' },
      { currencyId: 'COIN.399825945745408', ticker: 'MOMENT-27000016', unit: 'MOMENT-27000016' },
      { currencyId: 'COIN.399825945606144', ticker: 'MOMENT-20000038', unit: 'MOMENT-20000038' },
      { currencyId: 'COIN.399825945730048', ticker: 'MOMENT-20000039', unit: 'MOMENT-20000039' },
      { currencyId: 'COIN.399825945713408', ticker: 'MOMENT-27000043', unit: 'MOMENT-27000043' },
      { currencyId: 'COIN.399825945581824', ticker: 'MOMENT-29000050', unit: 'MOMENT-29000050' },
      { currencyId: 'COIN.399825945570816', ticker: 'MOMENT-21000057', unit: 'MOMENT-21000057' },
      { currencyId: 'COIN.399825945681410', ticker: 'MOMENT-23000068', unit: 'MOMENT-23000068' },
      { currencyId: 'COIN.399825945683204', ticker: 'MOMENT-24000076', unit: 'MOMENT-24000076' },
      { currencyId: 'COIN.399825945684993', ticker: 'MOMENT-20000077', unit: 'MOMENT-20000077' },
      { currencyId: 'COIN.399825945741312', ticker: 'MOMENT-21000092', unit: 'MOMENT-21000092' },
      { currencyId: 'COIN.399825945734400', ticker: 'MOMENT-22000096', unit: 'MOMENT-22000096' },
      { currencyId: 'COIN.399825945715968', ticker: 'MOMENT-31000070', unit: 'MOMENT-31000070' },
      { currencyId: 'COIN.399825945704704', ticker: 'MOMENT-31000741', unit: 'MOMENT-31000741' },
      { currencyId: 'COIN.399825945700352', ticker: 'MOMENT-31000069', unit: 'MOMENT-31000069' },
      { currencyId: 'COIN.399825945692928', ticker: 'MOMENT-31000928', unit: 'MOMENT-31000928' },
      { currencyId: 'COIN.399825945685251', ticker: 'MOMENT-31000740', unit: 'MOMENT-31000740' },
      { currencyId: 'COIN.399825945695745', ticker: 'MOMENT-31000067', unit: 'MOMENT-31000067' },
      { currencyId: 'COIN.399825945692672', ticker: 'MOMENT-31000743', unit: 'MOMENT-31000743' },
      { currencyId: 'COIN.399825945671424', ticker: 'MOMENT-31000742', unit: 'MOMENT-31000742' },
      { currencyId: 'COIN.399825945746433', ticker: 'MOMENT-31000745', unit: 'MOMENT-31000745' },
      { currencyId: 'COIN.399832094439168', ticker: 'GTC6-52', unit: 'GTC6-52' },
      { currencyId: 'COIN.399832126206467', ticker: 'CITIZEN-5000692', unit: 'CITIZEN-5000692' },
      { currencyId: 'COIN.399832093473024', ticker: 'GTC6-89', unit: 'GTC6-89' },
      { currencyId: 'COIN.399832126424320', ticker: 'CITIZEN-5000699', unit: 'CITIZEN-5000699' },
      { currencyId: 'COIN.399832094456320', ticker: 'GTC6-111', unit: 'GTC6-111' },
      { currencyId: 'COIN.399832126089472', ticker: 'CITIZEN-5000702', unit: 'CITIZEN-5000702' },
      { currencyId: 'COIN.399832094473984', ticker: 'GTC6-242', unit: 'GTC6-242' },
      { currencyId: 'COIN.399832125997570', ticker: 'CITIZEN-5000728', unit: 'CITIZEN-5000728' },
      { currencyId: 'COIN.399831782116352', ticker: 'UNKNOWN-45', unit: 'UNKNOWN-45' },
      { currencyId: 'COIN.399825965931008', ticker: 'UNKNOWN-44', unit: 'UNKNOWN-44' },
      { currencyId: 'COIN.399825878189057', ticker: 'UNKNOWN-43', unit: 'UNKNOWN-43' },
      { currencyId: 'COIN.399825823625472', ticker: 'UNKNOWN-42', unit: 'UNKNOWN-42' },
      { currencyId: 'COIN.399831778388224', ticker: 'UNKNOWN-41', unit: 'UNKNOWN-41' },
      { currencyId: 'COIN.399831812777728', ticker: 'UNKNOWN-40', unit: 'UNKNOWN-40' },
      { currencyId: 'COIN.399831900945152', ticker: 'UNKNOWN-39', unit: 'UNKNOWN-39' },
      { currencyId: 'COIN.399831814683392', ticker: 'UNKNOWN-38', unit: 'UNKNOWN-38' },
      { currencyId: 'COIN.399832022846720', ticker: 'MPMX-740', unit: 'MPMX-740' },
      { currencyId: 'COIN.399832113204738', ticker: 'MPMX-928', unit: 'MPMX-928' },
      { currencyId: 'COIN.399832025561600', ticker: 'MPMX-67', unit: 'MPMX-67' },
      { currencyId: 'COIN.399832025607168', ticker: 'MPMX-69', unit: 'MPMX-69' },
      { currencyId: 'COIN.399832022940928', ticker: 'MPMX-745', unit: 'MPMX-745' },
      { currencyId: 'COIN.399832114441219', ticker: 'MPMX-989', unit: 'MPMX-989' },
      { currencyId: 'COIN.399832025684736', ticker: 'MPMX-70', unit: 'MPMX-70' },
      { currencyId: 'COIN.399825812733696', ticker: 'UNKNOWN-37', unit: 'UNKNOWN-37' },
      { currencyId: 'COIN.399832022986752', ticker: 'MPMX-742', unit: 'MPMX-742' },
      { currencyId: 'COIN.399832022656512', ticker: 'MPMX-741', unit: 'MPMX-741' },
      { currencyId: 'COIN.399832022930688', ticker: 'MPMX-746', unit: 'MPMX-746' },
      { currencyId: 'COIN.399832022939393', ticker: 'MPMX-743', unit: 'MPMX-743' },
      { currencyId: 'COIN.399831767262464', ticker: 'UNKNOWN-36', unit: 'UNKNOWN-36' },
      { currencyId: 'COIN.399825850966529', ticker: 'UNKNOWN-35', unit: 'UNKNOWN-35' },
      { currencyId: 'COIN.399825977465856', ticker: 'UNKNOWN-34', unit: 'UNKNOWN-34' },
      { currencyId: 'COIN.399826015065601', ticker: 'UNKNOWN-33', unit: 'UNKNOWN-33' },
      { currencyId: 'COIN.399831792496899', ticker: 'UNKNOWN-32', unit: 'UNKNOWN-32' },
      { currencyId: 'COIN.399831844905984', ticker: 'QQL-139', unit: 'QQL-139' },
      { currencyId: 'COIN.399831945856256', ticker: 'wm-5', unit: 'wm-5' },
      { currencyId: 'COIN.399831945664768', ticker: 'wm-4', unit: 'wm-4' },
      { currencyId: 'COIN.399831945729280', ticker: 'wm-3', unit: 'wm-3' },
      { currencyId: 'COIN.399831945679616', ticker: 'LIOM-679', unit: 'LIOM-679' },
      { currencyId: 'COIN.399831945673984', ticker: 'LIOM-825', unit: 'LIOM-825' },
      { currencyId: 'COIN.399831814680832', ticker: 'UNKNOWN-31', unit: 'UNKNOWN-31' },
      { currencyId: 'COIN.399825904438784', ticker: 'UNKNOWN-30', unit: 'UNKNOWN-30' },
      { currencyId: 'COIN.399831821179648', ticker: 'UNKNOWN-29', unit: 'UNKNOWN-29' },
      { currencyId: 'COIN.399825969449984', ticker: 'UNKNOWN-28', unit: 'UNKNOWN-28' },
      { currencyId: 'COIN.399825846250498', ticker: 'UNKNOWN-27', unit: 'UNKNOWN-27' },
      { currencyId: 'COIN.399825980527616', ticker: 'UNKNOWN-26', unit: 'UNKNOWN-26' },
      { currencyId: 'COIN.399831790115073', ticker: 'UNKNOWN-25', unit: 'UNKNOWN-25' },
      { currencyId: 'COIN.399825824369920', ticker: 'UNKNOWN-24', unit: 'UNKNOWN-24' },
      { currencyId: 'COIN.399831792489474', ticker: 'UNKNOWN-23', unit: 'UNKNOWN-23' },
      { currencyId: 'COIN.399831814925572', ticker: 'UNKNOWN-22', unit: 'UNKNOWN-22' },
      { currencyId: 'COIN.399825879283713', ticker: 'SUPR-14752', unit: 'SUPR-14752' },
      { currencyId: 'COIN.399831940344320', ticker: 'SUPR-19664', unit: 'SUPR-19664' },
      { currencyId: 'COIN.399825950405377', ticker: 'UNKNOWN-21', unit: 'UNKNOWN-21' },
      { currencyId: 'COIN.399831870164992', ticker: 'QQL-97', unit: 'QQL-97' },
      { currencyId: 'COIN.399831839525888', ticker: 'Emblem.pro-7753376887', unit: 'Emblem.pro-7753376887' },
      { currencyId: 'COIN.399825848243712', ticker: 'UNKNOWN-20', unit: 'UNKNOWN-20' },
      { currencyId: 'COIN.399831870188288', ticker: 'SUPR-30855', unit: 'SUPR-30855' },
      { currencyId: 'COIN.399831870174721', ticker: 'SUPR-25479', unit: 'SUPR-25479' },
      { currencyId: 'COIN.399831870171651', ticker: 'SUPR-25478', unit: 'SUPR-25478' },
      { currencyId: 'COIN.399831870150401', ticker: 'SUPR-25480', unit: 'SUPR-25480' },
      { currencyId: 'COIN.399831870191361', ticker: 'BLOCKS-207000241', unit: 'BLOCKS-207000241' },
      { currencyId: 'COIN.399831870161664', ticker: 'BLOCKS-207000363', unit: 'BLOCKS-207000363' },
      { currencyId: 'COIN.399831873211904', ticker: 'BLOCKS-207000222', unit: 'BLOCKS-207000222' },
      { currencyId: 'COIN.399831870641408', ticker: 'QQL-86', unit: 'QQL-86' },
      { currencyId: 'COIN.399831869573120', ticker: 'QQL-72', unit: 'QQL-72' },
      { currencyId: 'COIN.399832027443200', ticker: 'QQL-24', unit: 'QQL-24' },
      { currencyId: 'COIN.399826690761728', ticker: 'QQL-MP-626', unit: 'QQL-MP-626' },
      { currencyId: 'COIN.399831879467264', ticker: 'DISP-1557', unit: 'DISP-1557' },
      { currencyId: 'COIN.399831944769024', ticker: 'DIDA-1862', unit: 'DIDA-1862' },
      { currencyId: 'COIN.399831944745728', ticker: 'DISP-1862', unit: 'DISP-1862' },
      { currencyId: 'COIN.399831879450880', ticker: 'DIDA-1557', unit: 'DIDA-1557' },
      { currencyId: 'COIN.399825845748992', ticker: 'UNKNOWN-19', unit: 'UNKNOWN-19' },
      { currencyId: 'COIN.399825975038208', ticker: 'UNKNOWN-18', unit: 'UNKNOWN-18' },
      { currencyId: 'COIN.399825951744256', ticker: 'UNKNOWN-17', unit: 'UNKNOWN-17' },
      { currencyId: 'COIN.399831839528448', ticker: 'VM-6', unit: 'VM-6' },
      { currencyId: 'COIN.399831878459138', ticker: '1972-1490', unit: '1972-1490' },
      { currencyId: 'COIN.399831878465792', ticker: '1972-2256', unit: '1972-2256' },
      { currencyId: 'COIN.399831878976512', ticker: '1972-2037', unit: '1972-2037' },
      { currencyId: 'COIN.399831878465025', ticker: '1972-2207', unit: '1972-2207' },
      { currencyId: 'COIN.399832026057472', ticker: 'MPMX-81', unit: 'MPMX-81' },
      { currencyId: 'COIN.399832022896128', ticker: 'MPMX-739', unit: 'MPMX-739' },
      { currencyId: 'COIN.399831878959360', ticker: 'COG-188', unit: 'COG-188' },
      { currencyId: 'COIN.399831803302912', ticker: 'UNKNOWN-16', unit: 'UNKNOWN-16' },
      { currencyId: 'COIN.399831946542080', ticker: 'FND-38183', unit: 'FND-38183' },
      { currencyId: 'COIN.399831946572288', ticker: 'MWNYFW-1', unit: 'MWNYFW-1' },
      { currencyId: 'COIN.399831782062080', ticker: 'WMVG-0', unit: 'WMVG-0' },
      { currencyId: 'COIN.399825960825600', ticker: 'SUPR-37876', unit: 'SUPR-37876' },
      { currencyId: 'COIN.399831870176512', ticker: 'Emblem.pro-2956513823', unit: 'Emblem.pro-2956513823' },
      { currencyId: 'COIN.399826016274944', ticker: 'Emblem.pro-4042894433', unit: 'Emblem.pro-4042894433' },
      { currencyId: 'COIN.399832022870528', ticker: 'MPMX-744', unit: 'MPMX-744' },
      { currencyId: 'COIN.399832022911488', ticker: 'MPMX-68', unit: 'MPMX-68' },
      { currencyId: 'COIN.399832025655040', ticker: 'MPMX Option-67', unit: 'MPMX Option-67' },
      { currencyId: 'COIN.399832025611265', ticker: 'MPMX Option-68', unit: 'MPMX Option-68' },
      { currencyId: 'COIN.399832025561089', ticker: 'MPMX Option-69', unit: 'MPMX Option-69' },
      { currencyId: 'COIN.399832025839872', ticker: 'MPMX Option-70', unit: 'MPMX Option-70' },
      { currencyId: 'COIN.399831879922688', ticker: 'NOUN-443', unit: 'NOUN-443' },
      { currencyId: 'COIN.385711164240128', ticker: 'merge-eth.io', unit: 'merge-eth.io' },
      { currencyId: 'COIN.399826276145920', ticker: 'BLOCKS-189000736', unit: 'BLOCKS-189000736' },
      { currencyId: 'COIN.399826276043008', ticker: 'BLOCKS-189000437', unit: 'BLOCKS-189000437' },
      { currencyId: 'COIN.399826276089088', ticker: 'CITIZEN-3000223', unit: 'CITIZEN-3000223' },
      { currencyId: 'COIN.399826276075264', ticker: 'CITIZEN-929', unit: 'CITIZEN-929' },
      { currencyId: 'COIN.399826276465408', ticker: 'CITIZEN-488', unit: 'CITIZEN-488' },
      { currencyId: 'COIN.399827073095168', ticker: 'BLOCKS-95000162', unit: 'BLOCKS-95000162' },
      { currencyId: 'COIN.399826884984064', ticker: 'BLOCKS-95000024', unit: 'BLOCKS-95000024' },
      { currencyId: 'COIN.399825968271617', ticker: 'CDB-909', unit: 'CDB-909' },
      { currencyId: 'COIN.399831937956352', ticker: 'MPKZ-65', unit: 'MPKZ-65' },
      { currencyId: 'COIN.399831937582080', ticker: 'MPKZ-2', unit: 'MPKZ-2' },
      { currencyId: 'COIN.399831937890816', ticker: 'MPKZ-8', unit: 'MPKZ-8' },
      { currencyId: 'COIN.399826458380800', ticker: 'SUPR-37476', unit: 'SUPR-37476' },
      { currencyId: 'COIN.399831937654272', ticker: 'MPKZ-9', unit: 'MPKZ-9' },
      { currencyId: 'COIN.399831937651456', ticker: 'MPKZ-39', unit: 'MPKZ-39' },
      { currencyId: 'COIN.399831937628160', ticker: 'TINY-1', unit: 'TINY-1' },
      { currencyId: 'COIN.399831937796096', ticker: 'TINY-2', unit: 'TINY-2' },
      { currencyId: 'COIN.399831937676544', ticker: 'TINY-3', unit: 'TINY-3' },
      { currencyId: 'COIN.399825870039041', ticker: 'UNKNOWN-15', unit: 'UNKNOWN-15' },
      { currencyId: 'COIN.2882', ticker: 'WETH', unit: 'WETH' },
      { currencyId: 'COIN.399825876172800', ticker: 'OPENSTORE-7808616392', unit: 'OPENSTORE-7808616392' },
      { currencyId: 'COIN.385711601624832', ticker: 'mbird.fun', unit: 'mbird.fun' },
      { currencyId: 'COIN.376550994001152', ticker: 'RARE', unit: 'RARE' },
      { currencyId: 'COIN.399825878183430', ticker: 'BLOCKS-215000630', unit: 'BLOCKS-215000630' },
      { currencyId: 'COIN.399825946125313', ticker: 'UNKNOWN-14', unit: 'UNKNOWN-14' },
      { currencyId: 'COIN.399825950125568', ticker: 'SUPR-28029', unit: 'SUPR-28029' },
      { currencyId: 'COIN.399825872828416', ticker: 'UNKNOWN-13', unit: 'UNKNOWN-13' },
      { currencyId: 'COIN.399831874537984', ticker: 'ᗩᗰ-32', unit: 'ᗩᗰ-32' },
      {
        currencyId: 'COIN.399831764070656',
        ticker: 'BEEPLESPRINGCOLLECTION-100020006',
        unit: 'BEEPLESPRINGCOLLECTION-100020006',
      },
      {
        currencyId: 'COIN.399831817004293',
        ticker: 'BEEPLESPRINGCOLLECTION-100040006',
        unit: 'BEEPLESPRINGCOLLECTION-100040006',
      },
      {
        currencyId: 'COIN.399831819993090',
        ticker: 'BEEPLESPRINGCOLLECTION-100030006',
        unit: 'BEEPLESPRINGCOLLECTION-100030006',
      },
      {
        currencyId: 'COIN.399831769861888',
        ticker: 'BEEPLESPRINGCOLLECTION-100010006',
        unit: 'BEEPLESPRINGCOLLECTION-100010006',
      },
      {
        currencyId: 'COIN.399831803304449',
        ticker: 'BEEPLESPRINGCOLLECTION-100050006',
        unit: 'BEEPLESPRINGCOLLECTION-100050006',
      },
      {
        currencyId: 'COIN.399831839529985',
        ticker: 'CRYPTOCUBESCREATORSTHERD-2040016000',
        unit: 'CRYPTOCUBESCREATORSTHERD-2040016000',
      },
      { currencyId: 'COIN.399831781674499', ticker: 'UNKNOWN-12', unit: 'UNKNOWN-12' },
      { currencyId: 'COIN.399831812772864', ticker: 'UNKNOWN-11', unit: 'UNKNOWN-11' },
      { currencyId: 'COIN.399832077960704', ticker: 'LILNOUN-1489', unit: 'LILNOUN-1489' },
      { currencyId: 'COIN.399831879187712', ticker: '3a85-ERC1155-3', unit: '3a85-ERC1155-3' },
      { currencyId: 'COIN.399825968283904', ticker: 'SUPR-18241', unit: 'SUPR-18241' },
      { currencyId: 'COIN.399825911116544', ticker: 'NOUN-412', unit: 'NOUN-412' },
      { currencyId: 'COIN.399825951384064', ticker: 'SUPR-14664', unit: 'SUPR-14664' },
    ],
    olderPageToken: 'earlier||1660785799||0x118b6b4c37c02230fb525eca931a24b9082601c71dda3ffb170966d584ba76b1',
  };
}
