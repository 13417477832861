


























































































































































































































































































































































































































































































import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Coins,
  Contact,
  ReconciliationStatus,
  ScopeLiterals,
  Transaction,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import PaginationFooter from '@/components/PaginationFooter.vue';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import { isTxnClosed } from '@/services/transactionServices';
import { stringifyError } from '@/utils/error';
import { isDefined } from '@/utils/guards';

import { BaseVue } from '../../BaseVue';
import Pagination from '../../components/Pagination.vue';
import BlockchainExplorerLink from '../../components/transactions/BlockchainExplorerLink.vue';
import CreateManualTransaction from '../../components/transactions/CreateManualTransaction.vue';
import SaveInline from '../../components/transactions/SaveInline.vue';
import { ErroredTransactionsQuery, TransactionsPageQuery, WalletsQuery } from '../../queries/transactionsPageQuery';
import { ellipsis } from '../../utils/stringUtils';

const wellKnownAddresses: Record<string, string> = {
  'ROSE:OASIS1QRMUFHKKYYF79S5ZA2R8YGA9GNK4T446DCY3A5ZM': 'Oasis common pool',
  'ROSE:OASIS1QQNV3PEUDZVEKHULF8V3HT29Z4CTHKHY7GKXMPH5': 'Oasis fee accumulator',
};

@Component({
  components: {
    Pagination,
    CreateManualTransaction,
    BlockchainExplorerLink,
    SaveInline,
    PaginationFooter,
    uiToggle: UiToggle,
    TooltipSelect,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoading',
    },
    erroredTransactions: {
      query: ErroredTransactionsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
            transactionFilter: {
              errored: true,
            },
            limit: 1,
          };
        } else {
          return false;
        }
      },
      update(data) {
        return data.transactions.txns;
      },
    },
  },
})
export default class TransactionsNew extends BaseVue {
  public q = TransactionsPageQuery;
  public selectedTab = 0;
  public filters = {
    categorizationFilter: 'All',
    reconciliationFilter: 'Unreconciled',
    ignoreFilter: 'Unignored',
    walletFilter: 'All',
    pivotDate: this.formatDate(this.now()),
    searchTokens: undefined as string[] | undefined,
    errored: undefined as boolean | undefined,
  };

  public searchToken = '';
  public authors = ['Admin', 'Editor'];
  public categorizationStatuses = [
    { value: 'All', text: 'All' },
    { value: 'Uncategorized', text: 'Un-categorized' },
    { value: 'Categorized', text: 'Categorized' },
  ];

  public reconciliationStatuses = [
    { value: 'All', text: 'All' },
    { value: 'Reconciled', text: 'Reconciled' },
    { value: 'Unreconciled', text: 'Un-reconciled' },
  ];

  public ignoredStatuses = [
    { value: 'All', text: 'All' },
    { value: 'Unignored', text: 'Un-ignored' },
    { value: 'Ignored', text: 'Ignored' },
  ];

  public showingErrors = false;
  public headers = [
    {
      text: this.$tc('_type'),
      align: 'left',
      value: 'type',
    },
    {
      text: this.$tc('_id'),
      align: 'left',
      value: 'id',
    },
    {
      text: this.$tc('_date'),
      align: 'left',
      value: 'created',
    },
    {
      text: this.$tc('_wallet', 1),
      align: 'left',
      value: 'wallet',
    },
    {
      text: this.$t('_amount'),
      align: 'left',
      value: 'amount.value',
    },
  ];

  public transactions = {
    txns: [] as Transaction[],
    count: 0,
  };

  public erroredTransactions: Transaction[] = [];
  public isLoading = 0;
  public isPaginationLoading = false;
  public hasOlderPagination = false;
  public hasNewerPagination = false;
  public selected: Transaction[] = [];
  public snackbar = false;
  public snackbarColor = 'success';
  public snackbarText = '';
  public snackbarErrors: (GraphQLError | string)[] = [];
  public wallets: Wallet[] = [];
  public deleteDialog = false;
  public txnToDelete: Transaction | null = null;
  public deleting = false;
  public deleteMultipleDialog = false;
  public deletingMultiple = false;
  public deleteMultipleTotalCount = 0;
  public deleteMultipleDeletedCount = 0;
  public isNew = false;

  public toggleUi(val: boolean) {
    this.$emit('toggleUi', val);
  }

  public get selectedCount() {
    if (this.selected && this.selected.length) {
      return this.selected.length;
    } else {
      return 0;
    }
  }

  public get walletTypes() {
    const all = [
      {
        id: 'All',
        name: 'All',
      },
      ...this.wallets,
    ];
    return all;
  }

  public get multipleDeletePercent() {
    if (this.deleteMultipleTotalCount === 0) {
      return 0;
    } else {
      return (this.deleteMultipleDeletedCount / this.deleteMultipleTotalCount) * 100;
    }
  }

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public get addressLookup() {
    const contacts = this.contacts;
    const wallets = this.wallets;

    let result = wallets.reduce((a, x) => {
      x.addresses?.forEach((addr) => {
        if (x.name && addr) {
          const upperAddr = addr.toUpperCase();
          const network = x.networkId?.toUpperCase() ?? 'UNKNOWN';
          if (upperAddr.startsWith('OASIS') && !upperAddr.endsWith(':ESCROW') && !a[`${network}:${upperAddr}:ESCROW`]) {
            a[`${network}:${upperAddr}:ESCROW`] = '(Escrow) ' + x.name;
          }
          a[`${network}:${upperAddr}`] = x.name;
        }
      });
      return a;
    }, {} as Record<string, string>);

    result = contacts.reduce((a, x) => {
      for (const addr of x.addresses ?? []) {
        if (addr?.address) {
          a[`${addr.coin?.toUpperCase()}:${addr.address.toUpperCase()}`] = x.name;
        }
      }
      return a;
    }, result);

    return { ...result, ...wellKnownAddresses };
  }

  public get isLoadingAll() {
    const categoriesIsLoading = this.$store.getters['categories/CATEGORIES_ISLOADING'];
    const contactsIsLoading = this.$store.getters['constacts/CONTACTS_ISLOADING'];
    return this.isPaginationLoading || categoriesIsLoading || contactsIsLoading;
  }

  public get vars() {
    return {
      transactionFilter: this.filters,
      orgId: this.$store.state.currentOrg.id,
    };
  }

  @Ref()
  private readonly pagination!: any;

  /** Vue lifecycle hook for 'mounted' */
  public mounted() {
    this.refresh();
  }

  /**
   * fetch the contact information for a transaction
   *
   * passed through SaveInline to child components for auto-setting contact
   *
   * @param transaction the transaction that the contact is being fetched for
   * @return {string|undefined} the name or address of the contact for the transaction
   *
   */
  public getContact(transaction: Transaction): string | undefined {
    try {
      const selfAddresses: string[] = [];
      const networkId = transaction.networkId;
      this.$store.state.wallets.wallets?.map((wallet) => {
        wallet.addresses?.forEach((address) => {
          try {
            if (!address) throw new Error('no address');
            selfAddresses.push(address);
          } catch (error) {}
        });
      });

      let transactionContact;

      // get the transaction transfer logs
      const transferLogs = transaction.txnLogs?.filter(
        (log) => log?.type === 'transfer-log' && log.from?.address !== '0x0000000000000000000000000000000000000000'
      );

      if (!transferLogs) throw new Error('no transfer logs');

      // get the first transfer log
      const firstLog = transferLogs[0];

      if (!firstLog) return undefined;

      // get participant addresses from the logs
      const fromAddress = firstLog.from?.address;
      const toAddress = firstLog.to?.address;

      if (!fromAddress || !toAddress) return undefined;

      let contactAddress;

      // handle contact lookup
      if (!networkId) throw new Error('no networkId');

      switch (transaction.type) {
        case TxnType.Send:
          contactAddress = toAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;
        case TxnType.Receive:
          contactAddress = fromAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;

        default:
          // handle unknown transactions
          if (selfAddresses.map((address) => address.toLocaleLowerCase()).includes(fromAddress.toLocaleLowerCase())) {
            contactAddress = toAddress;
          } else {
            contactAddress = fromAddress;
          }
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);

          break;
      }

      return transactionContact;
    } catch (error) {}
  }

  private isWalletAddress(address: string | null | undefined, networkId?: string): boolean {
    const key = `${networkId?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    return !!this.addressLookup[key] && !wellKnownAddresses[key];
  }

  public lookupAddress(address: string | null | undefined, networkId?: string, coin?: string | null) {
    const key = `${networkId?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    const contactKey = `${coin?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    return this.addressLookup[key] ?? this.addressLookup[contactKey] ?? (address ? ellipsis(address, 20) : 'Unknown');
  }

  public processActors(trans: Transaction) {
    let hiddenLogs = 0;
    const logs = trans.txnLogs?.filter(isDefined) ?? [];
    const networkId = trans?.networkId ?? 'UNKNOWN';

    const shortNum = (num: string | null | undefined) => {
      if (typeof num !== 'string') {
        return 0;
      }
      return +(+num.replace(/[\s,]/g, '')).toFixed(4);
    };

    let result: (
      | {
          from: string[];
          to: string[];
          amount: string[];
          amountMux?: 'left' | 'right';
          type: 'txnLog';
        }
      | { text: string; type: 'note' }
    )[] = [];
    if (logs.length === 0) {
      let from = trans.from ?? [];
      let to = trans.to ?? [];
      const amountMux =
        to.length === from.length || from.length === 1 || to.length === 1
          ? undefined
          : to.length > from.length
          ? 'left'
          : 'right';
      let truncate = false;
      if (from.length === to.length) {
        const pairs = from
          .map((x, i) => [x, to[i]])
          .filter(
            ([fromVal, toVal]) =>
              this.isWalletAddress(fromVal?.address, networkId) || this.isWalletAddress(toVal?.address, networkId)
          );
        if (pairs.length) {
          from = pairs.map((x) => x[0]);
          to = pairs.map((x) => x[1]);
        } else {
          truncate = true;
        }
      } else {
        truncate = true;
      }
      if (truncate) {
        if (from.length > 3) {
          hiddenLogs = from.splice(3).length;
        }
        if (to.length > 3) {
          hiddenLogs = Math.max(to.splice(3).length, hiddenLogs);
        }
      }

      if (to.length && from.length) {
        result = [
          {
            from: from?.map((x) => this.lookupAddress(x?.address, networkId, x?.amount?.coin)) ?? [],
            to: to?.map((x) => this.lookupAddress(x?.address, networkId, x?.amount?.coin)) ?? [],
            amount:
              to.length > from.length
                ? to.map((x) => shortNum(x?.amount?.displayValue) + ' ' + x?.amount?.coin)
                : from.map((x) => shortNum(x?.amount?.displayValue) + ' ' + x?.amount?.coin),
            amountMux,
            type: 'txnLog',
          },
        ];
      }
    } else {
      let filteredLogs = logs.filter(
        (x) => this.isWalletAddress(x.from?.address, networkId) || this.isWalletAddress(x.to?.address, networkId)
      );

      if (filteredLogs.length === 0) {
        if (logs.length > 3) {
          hiddenLogs = logs.length - 3;
          filteredLogs = logs.slice(0, 3);
        }
      }

      result = filteredLogs.map((log) => {
        const coin = log.asset?.symbol ?? trans.amounts?.[0]?.coin;
        const amount = log.displayAmount ? shortNum(log.displayAmount) : undefined;
        return {
          from: [this.lookupAddress(log.from?.address, networkId, coin)],
          to: [this.lookupAddress(log.to?.address, networkId, coin)],
          amount: coin && amount ? [`${amount} ${coin}`] : [],
          type: 'txnLog',
        };
      });
    }

    if (hiddenLogs === 0 && trans.fees && trans.fees.length > 0) {
      result.push({
        text:
          'Fees: ' +
          trans.fees
            .filter((x) => x?.value && x?.coin)
            .map((x) => `${shortNum(x?.value)} ${x?.coin}`)
            .join(', '),
        type: 'note',
      });
    }

    if (hiddenLogs > 0) {
      result.push({
        text: `+ ${hiddenLogs} more`,
        type: 'note',
      });
    }

    return result;
  }

  public selectAll(value: unknown) {
    if (value) {
      this.selected = this.transactions.txns;
    } else {
      this.selected = [];
    }
  }

  public showErrored() {
    this.filters.categorizationFilter = 'All';
    this.filters.reconciliationFilter = 'All';
    this.filters.ignoreFilter = 'Unignored';
    this.filters.walletFilter = 'All';
    this.filters.pivotDate = this.formatDate(this.now());
    this.filters.searchTokens = undefined;
    this.filters.errored = true;
    this.showingErrors = true;
    this.refresh();
  }

  public resetFilters() {
    this.filters.categorizationFilter = 'All';
    this.filters.reconciliationFilter = 'Unreconciled';
    this.filters.ignoreFilter = 'Unignored';
    this.filters.walletFilter = 'All';
    this.filters.pivotDate = this.formatDate(this.now());
    this.filters.searchTokens = undefined;
    this.filters.errored = undefined;
    this.showingErrors = false;
    this.searchToken = '';
    // this.refresh();
  }

  public getSaveTitle(item: Transaction) {
    if (item.readonly || this.isTxnClosed(item) || !this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      return 'View'; // this.$tc('_view');
    } else {
      return item.categorizationStatus === 'Uncategorized' ? this.$tc('_categorize') : this.$tc('_edit');
    }
  }

  public getSaveColor(item: Transaction) {
    if (item.readonly || this.isTxnClosed(item) || !this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      return '#029957'; // this.$tc('_view');
    } else {
      return item.categorizationStatus === 'Uncategorized' ? '#05e191' : '#05b5f5';
    }
  }

  public closeDeleteMultiple() {
    this.deleteMultipleDialog = false;
    this.deletingMultiple = false;
    this.deleteMultipleTotalCount = 0;
    this.deleteMultipleDeletedCount = 0;
  }

  public deleteSelectedTransactions() {
    const combinedTxn = this.selected.find((txn) => txn.isCombined);
    if (combinedTxn) {
      this.snackbarText = this.$tc('_cannotDeleteCombinedTransaction');
      this.snackbarColor = 'warning';
      this.snackbar = true;
      return;
    }

    this.deleteMultipleDialog = true;
    this.deletingMultiple = false;
    this.deleteMultipleTotalCount = this.selected.length;
    this.deleteMultipleDeletedCount = 0;
  }

  public async execDeleteSelectedTransactions() {
    if (this.selectedCount === 0) {
      return;
    }

    this.deletingMultiple = true;

    try {
      for (const s of this.selected) {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          id: s.id,
        };
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              deleteTransaction(orgId: $orgId, id: $id)
            }
          `,
          variables: vars,
        });

        if ((res.errors && res.errors.length > 0) || !res.data.deleteTransaction) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.snackbarText = this.$tc('_deletedTransactionFailure') + ': ' + JSON.stringify(errors.join('<br />'));
          this.snackbarErrors = errors;
          this.snackbarColor = 'error';
          this.snackbar = true;
        } else {
          this.deleteMultipleDeletedCount++;
        }
      }

      this.snackbarText = this.$tc('_deletedTransactionsSuccess', this.selectedCount);
      this.snackbarErrors = [];
      this.snackbarColor = 'success';
      this.snackbar = true;
    } catch (err) {
      this.snackbarText = this.$tc('_deletedTransactionFailure') + ': ' + stringifyError(err);
      this.snackbarColor = 'error';
      this.snackbar = true;
    } finally {
      this.closeDeleteMultiple();
      this.selected = [];
      await this.refresh();
    }
  }

  public combineSelectedTransactions() {
    if (this.selectedCount < 2) {
      throw new Error("Can't combine less than 2 transactions");
    }

    const txnIds = this.selected.map((m) => m.id);
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnIds,
    };
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $txnIds: [ID]!) {
            combineTransactions(orgId: $orgId, txnIds: $txnIds) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((res) => {
        if (res.errors && res.errors.length > 0) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.snackbarText =
            this.$tc('_combinedTransactionsFailure', this.selectedCount) + ': ' + JSON.stringify(errors.join('<br />'));
          this.snackbarErrors = errors;
          this.snackbarColor = 'error';
          this.snackbar = true;
        } else {
          this.snackbarText = this.$tc('_combinedTransactionsSuccess', this.selectedCount);
          this.snackbarErrors = [];
          this.snackbarColor = 'success';
          this.snackbar = true;
        }

        // this.selected = [];
        this.refresh();
      })
      .catch((err) => {
        this.snackbarText =
          this.$tc('_combinedTransactionsFailure', this.selectedCount) + ': ' + JSON.stringify(err.message);
        // this.snackbarErrors = errors;
        this.snackbarColor = 'error';
        this.snackbar = true;
      })
      .finally(() => {
        this.selected = [];
      });
  }

  public async setIgnoreSelectedTransactions(ignored: boolean) {
    if (this.selectedCount === 0) {
      throw new Error('Need at least one transaction to ignore');
    }

    const txnIds = this.selected.map((m) => m.id);

    const errors = [];

    try {
      for (const txnId of txnIds) {
        // const vars = ;

        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!, $ignore: Boolean) {
              updateTransaction(orgId: $orgId, id: $id, ignore: $ignore) {
                id
              }
            }
          `,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: txnId,
            ignore: ignored,
          },
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }
        }
      }
    } catch (err) {
      errors.push(stringifyError(err));
    } finally {
      this.selected = [];
    }

    if (errors.length > 0) {
      const m = ignored ? '_ignoreTransactionsFailure' : '_unIgnoreTransactionsFailure';
      this.snackbarText = this.$tc(m, this.selectedCount) + ': ' + JSON.stringify(errors.join('<br />'));
      this.snackbarErrors = errors;
      this.snackbarColor = 'error';
      this.snackbar = true;
    } else {
      const m = ignored ? '_ignoreTransactionsSuccess' : '_unIgnoreTransactionsSuccess';
      this.snackbarText = this.$tc(m, this.selectedCount);
      this.snackbarErrors = [];
      this.snackbarColor = 'success';
      this.snackbar = true;
    }
    await this.refresh();
    // updateTransaction(
    //   orgId: ID!
    //   id: ID!
    //   ignore: Boolean
  }

  public async setReconcileSelectedTransactions(reconciliationStatus: ReconciliationStatus) {
    if (this.selectedCount === 0) {
      throw new Error('Need at least one transaction to ignore');
    }

    const txnIds = this.selected.map((m) => m.id);

    const errors = [];

    try {
      for (const txnId of txnIds) {
        // const vars = ;

        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!, $reconciliationStatus: ReconciliationStatus) {
              updateTransaction(orgId: $orgId, id: $id, reconciliationStatus: $reconciliationStatus) {
                id
              }
            }
          `,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: txnId,
            reconciliationStatus,
          },
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }
        }
      }
    } catch (err) {
      errors.push(stringifyError(err));
      // this.snackbarText =
      //   this.$tc("_ignoreTransactionsFailure", this.selectedCount) +
      //   ": " +
      //   JSON.stringify(err.message);
      // // this.snackbarErrors = errors;
      // this.snackbarColor = "error";
      // this.snackbar = true;
    } finally {
      this.selected = [];
    }

    if (errors.length > 0) {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsFailure' : '_unReconcileTransactionsFailure';
      this.snackbarText = this.$tc(m, this.selectedCount) + ': ' + JSON.stringify(errors.join('<br />'));
      this.snackbarErrors = errors;
      this.snackbarColor = 'error';
      this.snackbar = true;
    } else {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsSuccess' : '_unReconcileTransactionsSuccess';
      this.snackbarText = this.$tc(m, this.selectedCount);
      this.snackbarErrors = [];
      this.snackbarColor = 'success';
      this.snackbar = true;
    }
    await this.refresh();
    // updateTransaction(
    //   orgId: ID!
    //   id: ID!
    //   ignore: Boolean
  }

  public async refresh() {
    await this.pagination.refetch();
    await this.$apollo.queries.erroredTransactions.refetch();
    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
  }

  public getCurrencySymbol(coin: Coins) {
    if (coin === 'BTC') {
      return '฿';
    } else if (coin === 'ETH') {
      return 'Ξ';
    } else if (coin === 'EOS') {
      return 'EOS';
    } else {
      return coin;
    }
  }

  public toUtcDate(timestamp: moment.MomentInput) {
    return moment(timestamp, 'X').utc().format('lll');
  }

  public ignoreTxn(txn: Transaction, ignore: boolean) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      ignore,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $ignore: Boolean) {
            updateTransaction(orgId: $orgId, id: $id, ignore: $ignore) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then(() => {
        this.refresh();
      });
  }

  public unreconcileTxn(txn: Transaction) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unReconcileTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unReconcileTransaction) {
          this.snackbar = true;
          this.snackbarText = 'Successfully Unreconciled Transaction';
          this.snackbarColor = 'success';
          this.refresh();
        } else {
          this.snackbar = true;
          this.snackbarText = 'Problem Unreconciling Transaction';
          this.snackbarColor = 'error';
          this.refresh();
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.snackbarText = 'Error Unreconciling ' + err;
        this.snackbarColor = 'error';
      });
  }

  public async updateTransaction(
    txn: Transaction,
    reconciliationStatus: ReconciliationStatus,
    categorizationStatus: CategorizationStatus
  ) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      reconciliationStatus,
      categorizationStatus,
    };

    // reconciliationStatus: ReconciliationStatus,
    //   categorizationStatus: CategorizationStatus

    try {
      const d = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $orgId: ID!
            $id: ID!
            $reconciliationStatus: ReconciliationStatus
            $categorizationStatus: CategorizationStatus
          ) {
            updateTransaction(
              orgId: $orgId
              id: $id
              reconciliationStatus: $reconciliationStatus
              categorizationStatus: $categorizationStatus
            ) {
              id
            }
          }
        `,
        variables: vars,
      });
      if (d.data.updateTransaction) {
        this.snackbar = true;
        this.snackbarText = 'Successfully Updated Transaction';
        this.snackbarColor = 'success';
        this.refresh();
      } else {
        this.snackbar = true;
        this.snackbarText = 'Problem Updating Transaction';
        this.snackbarColor = 'error';
        this.refresh();
      }
    } catch (err) {
      this.snackbar = true;
      this.snackbarText = 'Error Updating ' + err;
      this.snackbarColor = 'error';
    }
  }

  public uncombineTxn(txn: Transaction) {
    if (!txn.isCombined) {
      this.snackbar = true;
      this.snackbarText = 'Transaction must be combined';
      this.snackbarColor = 'error';
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unCombineTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unCombineTransaction) {
          this.snackbar = true;
          this.snackbarText = 'Successfully Un-combined Transaction';
          this.snackbarColor = 'success';
          this.refresh();
        } else {
          this.snackbar = true;
          this.snackbarText = 'Problem Un-combining Transaction';
          this.snackbarColor = 'error';
          this.refresh();
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.snackbarText = 'Error Un-combining ' + err;
        this.snackbarColor = 'error';
      });
  }

  public isTxnClosed(txn: Transaction): boolean {
    return isTxnClosed(txn, this.$store.state.currentOrg);
  }

  public deleteTxn(txn: Transaction) {
    this.txnToDelete = txn;
    this.deleteDialog = true;
  }

  public closeDelete() {
    this.txnToDelete = null;
    this.deleteDialog = false;
  }

  public execDeleteTxn() {
    if (this.txnToDelete === null) {
      this.snackbar = true;
      this.snackbarText = 'Problem Deleting Transaction';
      this.snackbarColor = 'error';
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: this.txnToDelete.id,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            deleteTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.deleteTransaction) {
          this.snackbar = true;
          this.snackbarText = 'Successfully Deleted Transaction';
          this.snackbarColor = 'success';
          this.refresh();
        } else {
          this.snackbar = true;
          this.snackbarText = 'Problem Deleting Transaction';
          this.snackbarColor = 'error';
          this.refresh();
        }
      })
      .catch((err) => {
        this.snackbar = true;
        this.snackbarText = 'Error Deleting ' + err;
        this.snackbarColor = 'error';
      })
      .finally(() => {
        this.closeDelete();
      });
  }

  public renderDataTable(data: { transactions: { txns: Transaction[]; count: number } }) {
    this.transactions = data.transactions;
  }

  public isPaginationLoadingHandler(isLoading: boolean) {
    this.isPaginationLoading = isLoading;
  }

  public showPaginateOlderFooter(show: boolean) {
    this.hasOlderPagination = show;
  }

  public showPaginateNewerFooter(show: boolean) {
    this.hasNewerPagination = show;
  }

  public paginateOlder() {
    const paginationComponent = this.$refs.pagination as any;
    paginationComponent.onClickOlder();
  }

  public paginateNewer() {
    const paginationComponent = this.$refs.pagination as any;
    paginationComponent.onClickNewer();
  }

  async uncategorizeTxns() {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      transactionIds: this.selected.map((txn) => txn?.id).filter((id) => id),
    };

    try {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation UncategorizeTransactions($orgId: ID!, $transactionIds: [ID!]!) {
            uncategorizeTransactions(orgId: $orgId, transactionIds: $transactionIds)
          }
        `,
        variables: vars,
      });

      if (res.data) {
        this.snackbar = true;
        this.snackbarText = 'Successfully Uncategorized Transactions';
        this.snackbarColor = 'success';
        this.refresh();
      } else {
        throw Error('Problem Updating Transaction');
      }
    } catch (e) {
      this.snackbar = true;
      this.snackbarText = (e as Error).message;
      this.snackbarColor = 'error';
    }
  }

  public now() {
    return DateTime.local();
  }

  public formatDate(date: DateTime) {
    return date.toFormat('yyyy-LL-dd');
  }

  public onPivotDateChanged(pivotDate: string) {
    this.filters.pivotDate = pivotDate;
  }

  private searchDebounceTimer?: number;
  @Watch('searchToken')
  public onSearchTokenChange() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.searchDebounceTimer = window.setTimeout(() => {
      this.searchDebounceTimer = undefined;
      this.filters.searchTokens = this.searchToken.trim() !== '' ? this.searchToken.split(' ') : undefined;
    }, 1000);
  }

  @Watch('$route.params', { immediate: true })
  public onRouteParamChange() {
    if (this.$route.params.transactionId) {
      this.searchToken = this.$route.params.transactionId;
    }
  }
}
