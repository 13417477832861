<template>
  <div class="tw-flex tw-flex-col tw-max-h-full tw-h-full">
    <transactions-new v-if="isNew && !isBabel" @toggleUi="isNew = $event"></transactions-new>
    <transactions-new2 v-else-if="isNew && isBabel" @toggleUi="isNew = $event"></transactions-new2>
    <transactions v-else @toggleUi="isNew = $event"></transactions>
  </div>
</template>

<script>
import Transactions from './Transactions.vue';
import TransactionsNew from './Transactions3.vue';
import TransactionsNew2 from './Transactions4.vue';

export default {
  components: {
    Transactions,
    TransactionsNew,
    TransactionsNew2,
  },
  data() {
    return {
      isNew: true,
    };
  },
  computed: {
    isBabel() {
      const features = this.$store.getters.features;
      return features['event-sourced-txns'] === 'true';
    },
  },
};
</script>
