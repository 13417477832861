import { Connection, ConnectionCategory, ConnectionStatus, Providers } from '@/api-svc-types';

export const validAccountingConnections = (connections: any[], contacts: any[], categories: any[]): Connection[] => {
  const accountingConnections = connections.filter((c: any) => {
    //  we removed the filter for isDisable and isDelete because it is possible
    //  user used a certain accounting connection then they migrate to a new one

    return c.category === ConnectionCategory.AccountingConnection;
  });

  const manualContactExist =
    contacts?.some((c: { accountingConnectionId: string }) => c.accountingConnectionId === 'Manual') ?? false;
  const manualCategoryExist =
    categories?.some((c: { accountingConnectionId: string }) => c.accountingConnectionId === 'Manual') ?? false;

  if (manualContactExist || manualCategoryExist) {
    const manualAccountingConnection: Connection = {
      id: 'Manual',
      provider: Providers.Manual,
      status: ConnectionStatus.Ok,
    };
    return accountingConnections.concat(manualAccountingConnection);
  }

  return accountingConnections;
};
