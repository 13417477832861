






















































































































































































































































































































































































































































































































































































































































































































































































































































































import * as math from 'mathjs';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Contact,
  NetworkContactInput,
  NetworkFeeCategoryInput,
  ReconciliationStatus,
  ScopeLiterals,
  TransactionLite,
  TxnLineLite,
  TxnLineOperation,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
// model
import { RowAction, RowActionPlace, RowActionType } from '@/models/uiDataTable';
import { convertUnits, getCoinFromEnumString, getCoinUnitFromEnumString, getMainUnitForCoin } from '@/utils/coinUtils';
import numberUtils from '@/utils/numberUtils';
import { checkScope } from '@/utils/security';

import { BaseVue } from '../../BaseVue';
import SaveInline from '../../components/transactions/SaveInline.vue';
import { isTxnClosed } from '../../services/transactionServices';
import UiModal from '../ui/UiModal.vue';
import EditTransactionModal from './edit/EditTransactionModal.vue';
import UiAdvancedDropdown from './UiAdvancedDropdown.vue';
interface TxnLineVM extends TxnLineLite {
  category: string;
  contact: string;
  feeCategory: string;
  feeContact: string;
  dirty: boolean;
}
interface TxnVM extends TransactionLite {
  txnLines: TxnLineVM[];
  canInline: boolean;
  useLines: boolean;
}

@Component({
  components: {
    UiModal,
    UiDataTable,
    UiTooltip,
    UiDropdown,
    UiLoading,
    SaveInline,
    UiSelect2,
    UiAdvancedDropdown,
    UiButton,
    EditTransactionModal,
  },
})
export default class TransactionTable extends BaseVue {
  @Prop() setDirtyTxns!: string[]; // used only for expanded row/categorization
  @Prop({ type: Array, required: true }) transactions!: TxnVM[]; // all transactionLites with txnLines populated ontop
  @Prop({ type: Array, required: true }) selectedTxns!: TxnVM[];
  @Prop({ default: false }) isLoading?: boolean;
  @Prop({ default: '0px' }) headerHeight?: string; // height of parent header to compensate for
  @Prop() wallets!: Wallet[]; // all wallets, used for table and categorization
  @Prop() categories!: any[]; // all categories, used for table and categorization
  @Prop() contacts!: Contact[]; // all contacts, used for table and categorization
  @Prop() feeCategories!: NetworkFeeCategoryInput[]; // default fee categories for organization
  @Prop() feeContacts!: NetworkContactInput[]; // default fee contacts for organization
  @Prop() coinLookup!: Map<string, string>; // currency code to coin name lookup
  @Prop() networkLookup!: Map<string, string>; // currency code to network id lookup
  @Prop() isLoadingExchangeRates!: boolean;
  @Prop({ required: true })
  public readonly getContact?: (transaction: TxnVM) => string; // function to get contact id from transaction only used for categorization

  @Prop({ default: 'all' })
  public readonly displayMode!: string; // table display mode

  @Prop({ default: false }) isError?: boolean;

  @Prop({ required: true })
  public readonly visibleHeaders!: string[]; // current visible headers

  @Prop({ required: true })
  public readonly headers!: any[]; // all available headers

  @Prop({ default: true })
  public readonly showActionColumn!: boolean; // show action column

  @Prop({ default: () => [] })
  public readonly connections!: any[]; // all connections, only used for inline categorization

  @Prop({ default: () => ({}) })
  public readonly uniqueColumnValues!: any; // for filters, a key value map where the key is the column name and the value is an array of unique values for that column

  @Prop({ default: () => ({}) })
  public readonly filters!: any; // for filters, a key value map where the key is the column name and the value is an array of selected values for that column

  @Prop({ default: () => ({}) })
  public readonly sort!: any; // for sorting, an object with column name and sort direction

  @Prop({ default: () => ({ currency: 'USD', symbol: '$' }) })
  public readonly currentFiat!: { currency: string; symbol: string }; // current fiat currency

  public selectedRow: HTMLElement | null = null; // ref to the selected row, used for scrolling and styling
  public expandedTxn: TxnVM | null = null;

  public numFormat = numberUtils.format; // number formatter

  public editTransactionOpen = false;

  public openEditTransaction() {
    this.editTransactionOpen = true;
  }

  public handleCloseEditTransaction() {
    this.editTransactionOpen = false;
  }

  public isManual(txn: TransactionLite) {
    return txn.isManual;
  }

  // #region general functions and computed properties
  public get dataTableActions() {
    const retVal = [
      ...(checkScope(ScopeLiterals.TransactionsCreate)
        ? [
            {
              label: 'Create transactions',
              value: () => this.$emit('createTransactionModal'),
            },
          ]
        : ([] as RowAction[])),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Combine transactions',
              value: () => this.$emit('combineSelectedTransactions'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsDelete)
        ? [
            {
              label: 'Delete transactions',
              value: () => this.$emit('execDeleteSelectedTransactions'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Ignore transactions',
              value: () => this.$emit('setIgnoreSelectedTransactions', true),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Un-ignore transactions',
              value: () => this.$emit('setIgnoreSelectedTransactions', false),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionReconcileUpdate)
        ? [
            {
              label: 'Un-reconcile transactions',
              value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Unreconciled),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionCategorizeUpdate)
        ? [
            {
              label: 'Un-categorize transactions',
              value: () => this.$emit('uncategorizeTxns'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
    ];

    if (this.allowBulkCategorize && this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      retVal.push({
        // label: `Bulk Categorize transactions ${this.selectedTxns.length ? `(${this.selectedTxns.length})` : ''}`,
        label: `Categorize`,
        value: () => this.$emit('bulkEditTransaction'),
        disabled: !this.selectedTxns.length,
      });
    }

    return retVal;
  }

  // ui: new, show row actions
  public get dataTableRowActions(): RowAction[] {
    const actions: RowAction[] = [];

    if (this.allowBulkCategorize && this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      actions.push({
        label: `Categorize`,
        value: () => this.$emit('bulkEditTransaction'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 0,
      });
    }

    if (checkScope(ScopeLiterals.TransactionsUpdate)) {
      actions.push({
        label: 'Ignore',
        value: () => this.$emit('setIgnoreSelectedTransactions', true),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 1,
        separator: true,
      });

      actions.push({
        label: 'Un-ignore',
        value: () => this.$emit('setIgnoreSelectedTransactions', false),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 2,
      });

      actions.push({
        label: 'Combine',
        value: () => this.$emit('combineSelectedTransactions'),
        disabled: !this.selectedTxns.length || this.selectedTxns.length < 2,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        tooltip: 'You need to select more than 2 transactions',
        sortIndex: 2,
      });

      actions.push({
        label: 'Un-combine',
        value: () => this.$emit('uncombineTxn', this.selectedTxns[0]),
        disabled: !this.selectedTxns.length || this.selectedTxns.length !== 1 || !this.selectedTxns[0]?.isCombined,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        tooltip: 'Selection needs to be one combined transaction',
        sortIndex: 3,
        separator: true,
      });

      actions.push({
        label: 'Edit',
        value: () => this.$emit('editSingleTransaction'),
        disabled: !this.selectedTxns.length || this.selectedTxns.length !== 1 || !this.selectedTxns[0]?.isManual,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        tooltip: 'Selection needs to be one manual transaction',
        sortIndex: 4,
      });

      actions.push({
        label: 'Un-categorize',
        value: () => this.$emit('uncategorizeTxns'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 3,
      });
    }

    if (checkScope(ScopeLiterals.TransactionsDelete)) {
      actions.push({
        label: 'Delete',
        value: () => this.$emit('execDeleteSelectedTransactions'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 1,
      });
    }
    if (checkScope(ScopeLiterals.TransactionReconcileUpdate)) {
      actions.push({
        label: 'Mark as Reconciled',
        value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Reconciled),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 4,
      });

      actions.push({
        label: 'Un-reconcile',
        value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Unreconciled),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 4,
      });
    }

    return actions;
  }

  public get walletById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x;
      return a;
    }, {} as Record<string, Wallet>);
  }

  public get walletIdByAddress() {
    return this.wallets.reduce((a, x) => {
      x.addresses?.forEach((y) => {
        y = y?.toLowerCase() ?? '';
        if (y && x.id) {
          if (!a[y]) {
            a[y] = [];
          }
          a[y?.toLowerCase()].push(x.id);
        }
      });
      return a;
    }, {} as Record<string, string[]>);
  }

  public get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  public getConvertedValue(amount: any) {
    const coin = getCoinFromEnumString(amount.coin);
    const mainUnit = getMainUnitForCoin(coin ?? '');
    const val = this.convertBigNumberScalar(amount.value);
    const unit = getCoinUnitFromEnumString(amount.unit);
    let conv;
    if (unit && mainUnit && coin) {
      conv = convertUnits(coin, unit, mainUnit, val);
    }
    return conv ?? val;
  }

  public getFmv(amount: number, txn: TxnVM, coin: string) {
    const rates = txn.accountingDetails?.[0]?.exchangeRates?.length
      ? txn.accountingDetails?.[0]?.exchangeRates
      : txn.exchangeRates?.exchangeRates;
    const rate = rates?.find((x) => x?.coin === coin);
    return amount * parseFloat(rate?.rate || '0');
  }

  public onSelectionChanged(selectedItems: any[]) {
    this.$emit('selectionChanged', selectedItems);
  }

  public refresh() {
    this.$emit('refresh');
  }

  public async savedRow() {
    this.expandedTxn = null;
    this.selectedRow?.classList.add('row-transition');
    await this.$nextTick();
    this.selectedRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.selectedRow?.classList.add('tw-bg-success-100');
    setTimeout(() => {
      this.selectedRow?.classList.remove('tw-bg-success-100');
    }, 1000);
  }

  public handleActionOnTable(handler: string | unknown) {
    if (typeof handler !== 'string') {
      (handler as () => void)();
    }
  }

  public toggleRowView({ item, row }: { item: TxnVM; row: HTMLElement }) {
    this.selectedRow = row;
    if (this.expandedTxn && this.expandedTxn.id === item.id) {
      this.expandedTxn = null;
    } else {
      this.expandedTxn = item;
      window.pendo?.track('Transaction - Transaction Expanded', {
        useLines: item.useLines,
        canInline: item.canInline,
        categorizationStatus: item.categorizationStatus,
        reconciliationStatus: item.reconciliationStatus,
        isCombined: item.isCombined,
        ignored: item.ignored,
      });
    }
  }

  public singleLink(txn: TxnVM) {
    const metadata = this.displayMetadata(txn.metadata);
    const viewLinks = txn.viewLinks;
    const txnUrl = metadata?.find((x: { key: string; value: string }) => x.key.toLowerCase() === 'txnurl')?.value;
    if (txnUrl) {
      return txnUrl;
    } else if (viewLinks && viewLinks.length === 1) {
      return viewLinks[0]?.link;
    } else {
      return undefined;
    }
  }

  public isDirty(txn: TxnVM) {
    return txn.txnLines.some(
      (x) =>
        ((x.contact || x.category || x.feeCategory || x.feeContact) &&
          txn.categorizationStatus === CategorizationStatus.Uncategorized) ||
        x.dirty
    );
  }

  public multiLink(txn: TxnVM) {
    const viewLinks = txn.viewLinks;
    if (viewLinks && viewLinks.length > 1) {
      return viewLinks;
    } else {
      return undefined;
    }
  }

  public getIconsForTxn(txn: TxnVM) {
    const icons = [] as { icon: string; class: string; text: string; position?: string }[];
    const iconDatas = {
      [TxnType.Receive]: {
        icon: 'fa-arrow-circle-left',
        class: 'tw-text-success-300 tw-transform tw--rotate-45',
        text: 'Received',
      },
      [TxnType.Send]: {
        icon: 'fa-arrow-circle-right',
        class: `tw-text-error-300 tw-transform tw--rotate-45`,
        text: 'Send',
      },
      [TxnType.Trade]: {
        icon: 'fa-exchange',
        class: 'tw-text-primary-300',
        text: 'Trade',
      },
      [TxnType.Transfer]: {
        icon: 'fa-refresh',
        class: 'tw-text-primary-300',
        text: 'Transfer',
      },
      [TxnType.ContractExecution]: {
        icon: 'fa-code',
        class: 'tw-text-primary-300',
        text: 'Contract execution',
      },
    } as Record<TxnType, typeof icons[number]>;
    if (txn.type) {
      if (txn.type in iconDatas) {
        icons.push(iconDatas[txn.type]);
      }
      if (txn.type === TxnType.Receive && txn.hasMatchedInvoices) {
        icons.push({
          icon: 'fa-credit-card-alt',
          class: 'tw-text-primary-300',
          text: 'Invoice payment',
        });
      }
    }
    if (txn.isCombined) {
      icons.push({
        icon: 'fa-compress',
        class: 'tw-text-primary-300',
        text: 'Combined transaction',
      });
    }
    if (txn.isCombinedSubTransaction) {
      icons.push({
        icon: 'fa-sign-in',
        class: 'tw-text-primary-300 tw-transform tw-rotate-180',
        text: 'Combined sub-transaction',
      });
    }
    if (txn.errored) {
      icons.push({
        icon: 'fa-exclamation-circle',
        class: 'tw-text-error-300',
        text: 'Errored', // txn.errors?.join('\n') ?? 'Errored',
      });
    }
    if (!txn.useLines) {
      icons.push({
        icon: 'fa-exclamation-circle',
        class: 'tw-text-gray-300',
        text: 'This transaction cannot be inline categorized',
        position: 'right',
      });
    }
    return icons;
  }

  public getWalletNameByAddress(address: string, walletId: string) {
    return this.walletIdByAddress?.[address.toLowerCase()]?.some((y) => y === walletId)
      ? this.walletById?.[walletId]?.name
      : '';
  }

  public lookupCoin(currencyId: string) {
    return this.coinLookup.get(currencyId) ?? currencyId;
  }

  public lookupNetwork(currencyId: string) {
    return this.networkLookup.get(currencyId) ?? currencyId;
  }

  public displayMetadata(metadata: any[]) {
    return metadata?.reduce((a, x) => {
      for (const key of Object.keys(x)) {
        a.push({ key: key, value: x[key] });
      }
      return a;
    }, [] as { key: string; value: string }[]);
  }

  public isTxnClosed(txn: TxnVM): boolean {
    return isTxnClosed(txn, this.$store.state.currentOrg);
  }

  public splitDate(date: number) {
    const formattedDate = moment.unix(date).tz(this.preferredTimezone).format('MM/DD/YY hh:mm a');
    const splitDate = formattedDate?.split(' ') || [];
    const dateParts = [];
    if (splitDate.length > 0) {
      dateParts.push(splitDate[0]);
      dateParts.push(splitDate[1] + ' ' + splitDate[2]);
    }
    return dateParts;
  }

  public getAddressFirstPart(address: string) {
    return address?.substring(0, address.length - 8) ?? '';
  }

  public getAddressLastPart(address: string) {
    return address?.substring(address.length - 8, address.length) ?? '';
  }
  // #endregion

  // #region sorting filtering

  public onSort(sort: any) {
    this.$emit('sort', sort);
  }

  public onFilter(filters: any) {
    this.$emit('filter', filters);
  }

  // #endregion

  // #region txnLines vs fullAmountSet

  public useLines(txn: TransactionLite) {
    return this.txnLines[txn.id ?? '']?.length && this.verifyLines(txn);
  }

  public verifyLines(txn: TransactionLite) {
    // if feature flag is on, verify that the txnLines add up to the fullAmountSet
    // also the flag should be removed once the feature is fully released
    // remove all instances of categorization-validation-hotfix once the feature is proven to work 100%

    // summed txnLines grouped by walletId and ticker
    if (this.checkFeatureFlag('categorization-validation-hotfix', this.features)) {
      const groupedLines = txn.txnLines?.reduce((a, x) => {
        const key = (x.walletId + ':' + x.assetId) as string;
        if (!a[key]) {
          a[key] = { amount: math.bignumber(0), walletId: x.walletId, assetId: x.assetId ?? '' };
        }
        let amount = math.bignumber(x.amount);
        if (x.operation === 'FEE' || x.operation === 'SELL' || x.operation === 'WITHDRAW')
          amount = math.bignumber(x.amount).mul(-1);
        a[key].amount = math.bignumber(amount).add(a[key].amount);

        if (x.feeAmount || x.feeAssetId) {
          const feeKey = (x.walletId + ':' + x.feeAssetId) as string;
          if (!a[feeKey]) {
            a[feeKey] = { amount: math.bignumber(0), walletId: x.walletId, assetId: x.feeAssetId ?? '' };
          }
          a[feeKey].amount = math.bignumber(x.feeAmount).sub(a[feeKey].amount);
        }

        return a;
      }, {} as Record<string, { amount: math.BigNumber; walletId: string; assetId: string }>);

      // summed walletAmounts grouped by walletId and ticker
      const remainingAmounts = [...(txn.walletAmounts ?? [])]?.reduce((a, x) => {
        x?.amounts?.forEach((am) => {
          const key = (x.walletId + ':' + am?.currencyId) as string;
          if (!a[key]) {
            a[key] = { amount: math.bignumber(0), walletId: x.walletId ?? '', assetId: am?.currencyId ?? '' };
          }
          a[key].amount = this.getConvertedValue(am).add(a[key].amount);
        });
        return a;
      }, {} as Record<string, { amount: math.BigNumber; walletId: string; assetId: string }>);

      // compare the two to make sure the summed txnLines match the summed walletAmounts by walletId and ticker
      const retVal = (Object.keys(groupedLines ?? {}) ?? []).every((x) => {
        const line = groupedLines?.[x];
        const remaining = remainingAmounts?.[x];
        if (!remaining) return false;
        return line?.amount.eq(remaining.amount);
      });
      return retVal;
    } else {
      const remainingAmounts = [...(txn.fullAmountSet ?? [])];
      return (
        !(txn.txnLines ?? []).some((x) => {
          if (!x) return true;
          if (x?.feeAmount || x?.feeAssetId) {
            const index = remainingAmounts.findIndex(
              (a) =>
                this.getConvertedValue(a).toString().replace('-', '') === x.feeAmount && a?.currencyId === x.feeAssetId
            );
            if (index > -1) {
              remainingAmounts.splice(index, 1);
            } else {
              return true;
            }
          }
          const index = remainingAmounts.findIndex(
            (a) => this.getConvertedValue(a).toString().replace('-', '') === x.amount && a?.currencyId === x.assetId
          );
          if (index > -1) {
            remainingAmounts.splice(index, 1);
          } else {
            return true;
          }
          return false;
        }) && remainingAmounts.length === 0
      );
    }
  }

  get txnLines() {
    return this.transactions.reduce((a: any, x) => {
      x.accountingConnectionId =
        x.accountingConnectionId ?? (this.connections.find((cn) => cn.isDefault) || this.connections?.[0])?.id;
      if (x.id) a[x.id] = this.getSortedTxnLines(x as TxnVM);
      x.txnLines.forEach((y) => {
        if (y?.amount?.startsWith('-')) {
          y.amount = y.amount?.slice(1); // remove minus sign to keep precision and not convert to number.
        }
        if (y?.feeAmount?.startsWith('-')) {
          y.feeAmount = y.feeAmount?.slice(1); // remove minus sign to keep precision and not convert to number.
        }
        if (y.feeAmount || y.feeAssetId || y.operation === TxnLineOperation.Fee) {
          this.knownFeeAddress(y, x);
          this.knownFeeCategory(y, x);
        }
        this.knownAddress(y, x);
        if (y.operation !== TxnLineOperation.Fee) this.knownCategory(y, x);
      });
      x.canInline = this.canInline(x);
      x.useLines = this.verifyLines(x);
      return a;
    }, {});
  }

  getSortedTxnLines(txn: TxnVM) {
    // sort the txn lines placing the ones with feeAmount or operation equals 'FEE' at the end also secondary sort by walletId, do not mutate the original array
    return (
      (txn.txnLines?.slice()?.sort((a, b) => {
        if ((a?.feeAmount && !b?.feeAmount) || (a?.operation === 'FEE' && b?.operation !== 'FEE')) {
          return 1;
        } else if ((!a?.feeAmount && b?.feeAmount) || (a?.operation !== 'FEE' && b?.operation === 'FEE')) {
          return -1;
        } else if ((a?.walletId || '') > (b?.walletId || '')) {
          return 1;
        } else if ((a?.walletId || '') < (b?.walletId || '')) {
          return -1;
        } else {
          return 0;
        }
      }) as any[]) ?? []
    );
  }

  // #endregion

  // #region inline logic

  public canInline(txn: TxnVM) {
    return txn.type === TxnType.Receive || txn.type === TxnType.Send;
  }

  public isValidInline(txn: TxnVM) {
    return txn.txnLines.every(
      (x) =>
        x.category &&
        x.contact &&
        txn.accountingConnectionId &&
        (x.feeCategory || x.operation === TxnLineOperation.Fee || !x.feeAssetId) &&
        (x.feeContact || x.operation === TxnLineOperation.Fee || !x.feeAssetId)
    );
  }

  public handleConnectionSelected(txn: TxnVM, connection: any) {
    if (txn.accountingConnectionId !== connection) {
      txn.txnLines.forEach((x: TxnLineVM) => {
        // use this.$set to trigger reactivity
        this.$set(x, 'category', '');
        this.$set(x, 'contact', '');
        this.$set(x, 'feeCategory', '');
        this.$set(x, 'feeContact', '');
      });
    }
    this.$set(txn, 'accountingConnectionId', connection);
  }

  public setLineDirty(line: any, dirty = false) {
    line.dirty = dirty;
  }

  public knownCategory(line: any, txn: TxnVM) {
    const categoryType = txn.type === TxnType.Send ? 'defaultExpenseCategoryId' : 'defaultRevenueCategoryId';
    const categoryId = line.category;
    if (!categoryId) {
      const catCategoryId =
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) => x.id === line.contact)?.[
              categoryType
            ]
          : txn.accountingDetails?.[0]?.multivalue?.items?.reduce((a, x) => {
              const id =
                x?.lines?.find(
                  (y) =>
                    y?.txnLineId === line.txnLineId &&
                    (y?.coinAmount === line.amount || y?.coinAmount === '-' + line.amount)
                )?.categoryId ?? '';
              if (id) a = id;
              return a;
            }, '');
      this.$set(line, 'category', catCategoryId);
    }
    return line.category;
  }

  public knownFeeCategory(line: any, txn: TxnVM) {
    if (!line.feeCategory) {
      let categoryId = '';
      if (txn.categorizationStatus === CategorizationStatus.Uncategorized) {
        categoryId = this.$store.state.currentOrg.accountingConfig?.defaultFeeCategoryId ?? '';
        if (!this.getCategoriesByConnection(txn.accountingConnectionId ?? '').some((x) => x.id === categoryId)) {
          categoryId = '';
        }
      } else {
        categoryId =
          txn.accountingDetails?.[0]?.multivalue?.items?.reduce((a, x) => {
            const amount = line.operation === 'FEE' ? line.amount : line.feeAmount;
            const id =
              x?.lines?.find(
                (y) => y?.txnLineId === line.txnLineId && (y?.coinAmount === amount || y?.coinAmount === '-' + amount)
              )?.categoryId ?? '';
            if (id) a = id;
            return a;
          }, '') ?? '';
      }
      if (line.operation === 'FEE') {
        this.$set(line, 'category', categoryId);
      } else {
        this.$set(line, 'feeCategory', categoryId);
      }
    }
    return line.feeCategory;
  }

  public knownAddress(line: any, txn: TxnVM) {
    const address = txn.type === TxnType.Receive ? line.from : line.to;
    const contactId = line.contact;
    const assetId = line.assetId;
    if (!contactId) {
      const catContactId = txn.accountingDetails?.[0]?.multivalue?.items?.find((x) =>
        x?.lines?.some(
          (y) =>
            y?.txnLineId === line.txnLineId &&
            y?.coin?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase() &&
            y?.coinAmount?.replace('-', '') === line.amount
        )
      )?.contactId;
      const defaultFeeContact =
        line.operation === 'FEE' && txn.canInline
          ? this.feeContacts.find((x) => x.blockchain?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
              ?.contactId ?? ''
          : '';
      const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? x.addresses.some((y) => y.address === address) || defaultFeeContact === x.id
          : x.id === catContactId
      );

      if (contact) this.$set(line, 'contact', contact.id);
    }

    return line.contact;
  }

  public knownFeeAddress(line: any, txn: TxnVM) {
    const address = txn.type === TxnType.Receive ? line.from : line.to;
    const contactId = line.feeContact;
    const assetId = line.feeAssetId;
    if (!contactId) {
      const catContactId = txn.accountingDetails?.[0]?.multivalue?.items?.find((x) =>
        x?.lines?.some(
          (y) =>
            y?.txnLineId === line.txnLineId &&
            y?.coin?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase() &&
            y?.coinAmount?.replace('-', '') === line.feeAmount
        )
      )?.contactId;
      const defaultFeeContact = txn.canInline
        ? this.feeContacts.find((x) => x.blockchain?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
            ?.contactId ?? ''
        : '';
      const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? x.addresses.some((y) => y.address === address && y.coin === this.lookupCoin(assetId)) ||
            defaultFeeContact === x.id
          : x.id === catContactId
      );

      if (contact) this.$set(line, 'feeContact', contact.id);
    }

    return line.feeContact;
  }

  public getCategoryName(categoryId: string) {
    const category = this.categories.find((x) => x.id === categoryId);
    return category?.name ?? '';
  }

  public getContactName(contactId: string) {
    const contact = this.contacts.find((x) => x.id === contactId);
    return contact?.name ?? '';
  }

  public getCategoriesByConnection(accountingConnectionId: string) {
    return this.categories.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public getContactsByConnection(accountingConnectionId: string) {
    return this.contacts.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public inlineCategorize(txn: any) {
    this.$emit('inlineCategorize2', txn);
  }

  public assignToContact(address: string, contactId: string, coin: string) {
    this.$emit('assignToContact', { address, contactId, currencyId: coin });
  }

  public assignCategoryToContact(categoryId: string, contactId: string, revenue: TxnType) {
    this.$emit('assignCategoryToContact', {
      categoryId,
      contactId,
      revenue: revenue === TxnType.Receive,
    });
  }

  public closeAdvDropdown(key: string) {
    ((this.$refs[key] as Vue[])[0] as any)?.setOpen();
  }

  // #endregion

  // #region bulk categorize

  public get allowBulkCategorize() {
    return true;
  }

  // #endregion

  // #region lifeCycleHooks
  @Watch('transactions')
  public onTransactionsChanged() {
    const triggerPlaceholder = this.txnLines; // trigger computed property, the assignment is to avoid linting error for raw expression
  }
  // #endregion
}
