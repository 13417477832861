












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiBanner from '@/components/ui/UiBanner.vue';
import UiButton from '@/components/ui/UiButton.vue';

@Component({
  components: {
    UiButton,
    UiBanner,
  },
})
export default class NewTransactionBanner extends Vue {
  @Prop({ default: 0 })
  public readonly count!: number;

  public show = true;

  public refresh() {
    this.$emit('refresh');
  }
}
